export async function stripeCheckoutWithCustomer(token, cardDetails, product) {
  try {
    const response = await fetch("/payment/stripeCustomerHandler", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        cardDetails: cardDetails,
        product: product,
      }),
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function storePayment(token, payment, plan) {
  try {
    const response = await fetch("/payment/storePayments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        payment: payment,
        plan: plan,
      }),
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function createSubscription(token, payload) {
  try {
    const response = await fetch("/payment/createSubscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        payload: payload,
      }),
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function processPayPalPayments(token, payload, plan) {
  try {
    const response = await fetch("/payment/paypalPaymentHandler", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ product: payload.product, paypal:payload.data }),
    });
    return await response.json();

  } catch (error) {
    return error;
  }
}

// export async function  paypalPaymentHandlerBackend(token, payload){
//   try {
//     const response = await fetch("/payment/paypalPaymentHandler", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: token,
//       },
//       body: JSON.stringify({ payload: payload }),
//     });
//     // return await response.json();

//   } catch (error) {
//     return error;
//   }
// }

export async function getPaymentMethods(token) {
  try {
    const response = await fetch("/payment/fetchPaymentMethods", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}
