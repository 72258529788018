export async function fetchProjectList(token) {
  try {
    const response = await fetch("/project/get", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function fetchProjectList2(token, user_id) {
  try {
    const response = await fetch("/project/guest_get", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export const deleteProject = async (token, id) => {
  try {
    return await fetch("/project/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        project_id: id,
      }),
    }).then((response) => response.json());
  } catch (error) {
    return error;
  }
};

export const createProject = async (token, domain, title) => {
  try {
    return await fetch("/project/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        domain: domain,
        title: title,
      }),
    }).then((response) => response.json());
  } catch (error) {
    return error;
  }
};

export const getUserPlan = async (token) => {
  try {
    return await fetch("/account/fetchUserPlanDetails", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((response) => response.json());
  } catch (error) {
    return error;
  }
};
