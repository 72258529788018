import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import ProjectPageSpeedCard from "./ProjectPageSpeedCard";
import ProjectAnalyzerCard from "./ProjectAnalyzerCard";

const ProjectDetailCard = () => {
  const history = useHistory();
  const { id, type } = useParams();
  {
    switch (type) {
      case "page-speed":
        return <ProjectPageSpeedCard project={id} />;
        break;
      case "web-analyzer":
        return <ProjectAnalyzerCard project={id} />;
        break;
      // default:
      //   history.push("/app/dashboard");
      //   return false;
      //   break;
    }
  }
};

export default ProjectDetailCard;
