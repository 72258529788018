import React, { useState } from "react";
import ProgressBar from "components/Shared/ProgressBar";
import Collapsible from "components/Collapse/Collapse";
import LinksTable from "../Tables/LinksTable";
import WordsTable from "../Tables/WordsTables";
import HeadingList from "./HeadingList";
const getComputedRow = (
  icon = "fa-info-circle",
  title = "",
  content = "",
  image = "",
  color = ""
) => {
  return (
    <div className="p-4  pl-0 flex justify-between items-center border-b">
      <h4 className={`${color} bold text-base lg:w-3/12 md:w-3/12 `}>
        <i className={`mr-2 fa fa ${icon}`}></i>
        {title}
      </h4>
      <div className=" ml-3 flex-1">
        <p>
          {image && (
            <img
              src={image}
              className="h-5 w-5 mr-2"
              style={{ display: "inline" }}
            />
          )}
          {content}
        </p>
      </div>
      <div></div>
    </div>
  );
};

const RenderCard = (
  title = "",
  ratio = 0,
  customClass = "",
  customColor = ""
) => {
  return (
    <div>
      <span className="text-sm block  text-blueGray-700 rounded border border-solid border-blueGray-100">
        <div
          className={`relative flex flex-col min-w-0 break-words ${customClass} rounded mb-6 xl:mb-0 shadow-lg`}
        >
          <div className="flex-auto p-2">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full pr-4 max-w-full flex-grow flex-1 ">
                <h5 className="font-semibold text-base text-blueGray-700">
                  {title}
                </h5>
              </div>
              <div className="relative w-auto pl-4 flex-initial">
                <div
                  className={
                    "text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 shadow-lg rounded-full "
                  }
                >
                  <ProgressBar
                    strokeWidth={8}
                    percentage={parseInt(ratio) || 0}
                    isPercentage={false}
                    customClass={customClass}
                    customColor={customColor}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
};

const Stats = ({
  totalAudits = 0,
  successfulAudits = 0,
  errorAudits = 0,
  warningAudits = 0,
  internalLinks = [],
  externalLinks = [],
  webPageData,
  successfullData,
  errorData,
  warningData,
}) => {
  const [openTab, setOpenTab] = useState(1);
  const [successData, setSuccessData] = useState(
    Object.entries(successfullData).map(([key, value]) => ({ key, value }))
  );

  const [error, setError] = useState(
    Object.entries(errorData).map(([key, value]) => ({ key, value }))
  );

  const [warnings, setWarnings] = useState(
    Object.entries(warningData).map(([key, value]) => ({ key, value }))
  );
  return (
    <>
      <div className="flex flex-wrap justify-center w-full">
        <ul
          className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row mr-3 my-4 w-full"
          role="tablist"
        >
          <li className="-mb-px  last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold   shadow-lg rounded block leading-normal "
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              {RenderCard(
                "All Audits",
                totalAudits,
                openTab === 1 ? "bg-gray-200" : "bg-white",
                "92aab7"
              )}
            </a>
          </li>
          <li className="-mb-px  last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold   shadow-lg rounded block leading-normal "
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              {RenderCard(
                "Successful Audits",
                successfulAudits,
                openTab === 2 ? "bg-gray-200" : "bg-white",
                "#2ac9a1"
              )}
            </a>
          </li>
          <li className="-mb-px  last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold   shadow-lg rounded block leading-normal "
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(3);
              }}
              data-toggle="tab"
              href="#link3"
              role="tablist"
            >
              {RenderCard(
                "Errors",
                errorAudits,
                openTab === 3 ? "bg-gray-200" : "bg-white",
                "#fb6868"
              )}
            </a>
          </li>
          <li className="-mb-px  last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold   shadow-lg rounded block leading-normal "
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(4);
              }}
              data-toggle="tab"
              href="#link4"
              role="tablist"
            >
              {RenderCard(
                "Warnings",
                warningAudits,
                openTab === 4 ? "bg-gray-200" : "bg-white",
                "#f97316"
              )}
            </a>
          </li>
          <li className="-mb-px  last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold   shadow-lg rounded block leading-normal "
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(5);
              }}
              data-toggle="tab"
              href="#link5"
              role="tablist"
            >
              {RenderCard(
                "Basic Info",
                totalAudits,
                openTab === 5 ? "bg-gray-200" : "bg-white",
                "#0288d1"
              )}
            </a>
          </li>
        </ul>
        <div className="w-full  flex justify-between items-center justify-center shadow-lg border-2 border-gray-700">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded">
            <div className="flex-auto">
              <div className="tab-content tab-space ">
                <div
                  className={
                    openTab === 1 ||
                    openTab === 2 ||
                    openTab === 3 ||
                    openTab === 4 ||
                    openTab === 5
                      ? "block"
                      : "hidden"
                  }
                  id="link1"
                >
                  {openTab === 1 && (
                    <div className=" md:py-3 px-3 py-3">
                      <h2 className="text-black bold text-xl mt-2 uppercase font-bold">
                        SEO
                      </h2>
                      <div className="p-4 pl-0 flex justify-between items-center">
                        <h3 className="text-black-300 bold text-base  font-bold">
                          On-Page SEO
                        </h3>
                        <i className="ml-2 fa fa fa-info-circle"></i>
                        <div
                          className="ml-3 bg-blueGray-600 flex-1"
                          style={{ height: "1px" }}
                        ></div>
                      </div>
                      {getComputedRow(
                        "",
                        "Title",
                        webPageData.tags.title || "Missing title"
                      )}
                      {getComputedRow(
                        "",
                        "Meta description",
                        webPageData.tags.description || "Missing  Description"
                      )}
                      <div className="p-4  pl-0 flex justify-between items-center border-b">
                        <h4 className="text-black-300 bold text-base lg:w-3/12 md:w-3/12">
                          <i className="mr-2 fa fa fa-info-circle"></i>Snippet
                          preview
                        </h4>
                        <div className=" ml-3 flex-1">
                          <>
                            <h5 className="text-black-300 text-bold text-base flex items-center ">
                              <span className="fa fa-desktop mr-2"></span>{" "}
                              Desktop
                            </h5>
                            <a className="text-sm flex" href="">
                              {webPageData.domain || "N/A"}
                            </a>
                            <a className="text-lg text-blueGray-500" href="">
                              {webPageData.tags.title || "Missing title"}
                            </a>
                            <p className="flex text-sm mt-2">
                              {webPageData.tags.description ||
                                "Missing description"}
                            </p>
                          </>
                          <>
                            <div className="mt-4">
                              <h5 className="text-black-300 text-bold text-base flex items-center ">
                                <span className="fa fa-mobile mr-2"></span>
                                Mobile
                              </h5>
                              <div className="w-full lg:w-6/12 mt-3">
                                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                                  <div className="flex-auto p-4">
                                    <div className="flex flex-wrap">
                                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                                        {" "}
                                        <div
                                          className="flex justify-start items-center"
                                          href=""
                                        >
                                          <img
                                            src={webPageData.favicon_url}
                                            className="w-2 h-2 mr-2"
                                          />
                                          <a className="text-sm" href="">
                                            {webPageData.domain || "N/A"}
                                          </a>
                                        </div>
                                        <a
                                          className="text-lg text-blueGray-500"
                                          href=""
                                        >
                                          {webPageData.tags.title ||
                                            "Missing title"}
                                        </a>
                                        <p className="flex text-sm mt-2">
                                          {webPageData.tags.description ||
                                            "Missing description"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                        <div></div>
                      </div>
                      <div className="border-b">
                        <div className="p-4  pl-0 flex justify-between items-center ">
                          <h4 className="text-black-300 bold text-base lg:w-3/12 md:w-3/12 ">
                            <i className={`mr-2  fa fa-info-circle`}></i>
                            Headings
                          </h4>
                          <div className=" ml-3 flex-1">
                            We found the following headings on the page:
                          </div>
                        </div>
                        <div className="p-4  pl-0 flex justify-between items-center ">
                          <h4 className="text-black-300 bold text-base lg:w-3/12 md:w-3/12 "></h4>
                          <div className=" ml-3 flex-1">
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h1  Count: ( ${
                                webPageData.h1.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h1"
                                headings={webPageData.h1 || []}
                              />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h2  Count: ( ${
                                webPageData.h2.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h2"
                                headings={webPageData.h2 || []}
                              />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h3  Count: ( ${
                                webPageData.h3.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h3"
                                headings={webPageData.h3 || []}
                              />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h4  Count: ( ${
                                webPageData.h4.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h4"
                                headings={webPageData.h4 || []}
                              />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h5  Count: ( ${
                                webPageData.h5.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h5"
                                headings={webPageData.h5 || []}
                              />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h6  Count: ( ${
                                webPageData.h6.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h6"
                                headings={webPageData.h6 || []}
                              />
                            </Collapsible>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      {getComputedRow(
                        "fa-info-circle",
                        "Favicon",
                        "Your website has a favicon.",
                        webPageData.favicon_url || ""
                      )}
                      <div className="border-b">
                        <div className="p-4 pl-0 flex justify-between items-center ">
                          <h3 className="text-black-300  text-base  lg:w-3/12 md:w-3/12">
                            <i className="fa fa-home mr-2"></i> On-page Links
                          </h3>
                          <div className=" ml-3 flex-1">
                            We found {webPageData.url.length || 0} links on the
                            page:
                          </div>
                        </div>
                        <div className="p-4 pl-0 flex justify-between items-center ">
                          <h3 className="text-black-300  text-base  lg:w-3/12 md:w-3/12"></h3>

                          <div className=" ml-3 flex-1 w-full">
                            <Collapsible title="External Links">
                              <LinksTable links={externalLinks} />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible title="Internal Links">
                              <LinksTable links={internalLinks} />
                            </Collapsible>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <div className="border-b">
                        <div className="p-4 pl-0 flex justify-between items-center ">
                          <h3 className="text-black-300  text-base lg:w-3/12 md:w-3/12">
                            <i className="fa fa-home mr-2"></i> Most common
                            words
                          </h3>

                          <div className=" ml-3 flex-1">
                            <p className="text-sm text-orange-500 font-bold">
                              Low keyword consistency.
                            </p>
                          </div>
                        </div>
                        <div className="p-4 pl-0 flex justify-between items-center">
                          <span className="text-black-300  text-base lg:w-3/12 md:w-3/12"></span>

                          <div className=" ml-3 flex-1 overflow-x-scroll">
                            <p className="text-sm text-gray-600 mb-5">
                              The analyzed page has low keyword consistency.
                            </p>
                            <h4 className="text-base font-bold">
                              Most common words on the page
                            </h4>
                            <WordsTable
                              words={webPageData.commonOneWords || []}
                            />
                            <div className="mt-3"></div>
                            <h4 className="text-base font-bold">
                              Most common 2 word phrases on the page
                            </h4>
                            <WordsTable
                              words={webPageData.commonTwoWords || []}
                            />
                            <div className="mt-3"></div>
                            <h4 className="text-base font-bold">
                              Most common 3 word phrases on the page
                            </h4>
                            <WordsTable
                              words={webPageData.commonThreeWords || []}
                            />
                          </div>
                          <div></div>
                        </div>
                      </div>
                      {getComputedRow(
                        "fa-info-circle",
                        "Words per page",
                        `Total word count ${webPageData.words_per_page || "0"}`
                      )}
                      <div className="border-b">
                        <div className="p-4  pl-0 flex justify-between items-center">
                          <h4 className="text-black-300 bold text-base lg:w-3/12 md:w-3/12">
                            <i className="mr-2 fa fa fa-info-circle text-orange-500"></i>
                            Image ALT attribute
                          </h4>
                          <div className=" ml-3 flex-1">
                            <p className="text-sm ">
                              We found {webPageData.missingAlt.length || "no"}{" "}
                              images on the page.
                            </p>
                            <p className="text-red-500 text-sm font-bold">
                              {webPageData.missingAlt.length || ""} alt
                              attributes are empty or missing.
                            </p>
                          </div>
                        </div>
                        <div className="p-4  pl-0 flex justify-between items-center">
                          <h4 className="text-black-300 bold text-base lg:w-3/12 md:w-3/12"></h4>
                          <div className=" ml-3 flex-1">
                            <h4 className="text-sm font-bold">#URL</h4>
                            <div className="mt-3">
                              {webPageData &&
                                webPageData.missingAlt &&
                                webPageData.missingAlt.map((link, index) => (
                                  <a
                                    key={index + 1}
                                    href={link}
                                    target="_blank"
                                    className="text-blueGray-500 block py-1"
                                  >
                                    <span className="text-blueGray-400 mr-2">
                                      {index + 1}.
                                    </span>
                                    {link}
                                  </a>
                                ))}
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6 w-full">
                        <div className="flex items-center flex-shrink-0 text-white mr-6 w-full">
                          <span className="font-semibold text-xl tracking-tight">
                            Not-so-good. Your website needs improvements.
                          </span>
                        </div>
                        <div>
                          {/* <a
                          href="#"
                          className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
                        >
                          Get Free Consultation
                        </a> */}
                          <div className="flex justify-end">
                            <a
                              href="https://marketmediaconnect.com/contact-us/"
                              target="_blank"
                              className="mt-5"
                            >
                              <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out border border-solid font-bold last:mr-0  text-white bg-teal-500 border-teal-500 active:bg-teal-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded">
                                FIX ERRORS
                              </button>
                            </a>
                          </div>
                        </div>
                      </nav>
                    </div>
                  )}
                  {openTab === 5 && (
                    <div className=" md:py-3 px-3 py-3">
                      <h2 className="text-black bold text-xl mt-2 uppercase font-bold">
                        SEO
                      </h2>
                      <div className="p-4 pl-0 flex justify-between items-center">
                        <h3 className="text-black-300 bold text-base  font-bold">
                          On-Page SEO
                        </h3>
                        <i className="ml-2 fa fa fa-info-circle"></i>
                        <div
                          className="ml-3 bg-blueGray-600 flex-1"
                          style={{ height: "1px" }}
                        ></div>
                      </div>
                      {getComputedRow(
                        "",
                        "Title",
                        webPageData.tags.title || "Missing title"
                      )}
                      {getComputedRow(
                        "",
                        "Meta description",
                        webPageData.tags.description || "Missing  Description"
                      )}
                      <div className="p-4  pl-0 flex justify-between items-center border-b">
                        <h4 className="text-black-300 bold text-base lg:w-3/12 md:w-3/12">
                          <i className="mr-2 fa fa fa-info-circle"></i>Snippet
                          preview
                        </h4>
                        <div className=" ml-3 flex-1">
                          <>
                            <h5 className="text-black-300 text-bold text-base flex items-center ">
                              <span className="fa fa-desktop mr-2"></span>{" "}
                              Desktop
                            </h5>
                            <a className="text-sm flex" href="">
                              {webPageData.domain || "N/A"}
                            </a>
                            <a className="text-lg text-blueGray-500" href="">
                              {webPageData.tags.title || "Missing title"}
                            </a>
                            <p className="flex text-sm mt-2">
                              {webPageData.tags.description ||
                                "Missing description"}
                            </p>
                          </>
                          <>
                            <div className="mt-4">
                              <h5 className="text-black-300 text-bold text-base flex items-center ">
                                <span className="fa fa-mobile mr-2"></span>
                                Mobile
                              </h5>
                              <div className="w-full lg:w-6/12 mt-3">
                                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                                  <div className="flex-auto p-4">
                                    <div className="flex flex-wrap">
                                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                                        {" "}
                                        <div
                                          className="flex justify-start items-center"
                                          href=""
                                        >
                                          <img
                                            src={webPageData.favicon_url}
                                            className="w-2 h-2 mr-2"
                                          />
                                          <a className="text-sm" href="">
                                            {webPageData.domain || "N/A"}
                                          </a>
                                        </div>
                                        <a
                                          className="text-lg text-blueGray-500"
                                          href=""
                                        >
                                          {webPageData.tags.title ||
                                            "Missing title"}
                                        </a>
                                        <p className="flex text-sm mt-2">
                                          {webPageData.tags.description ||
                                            "Missing description"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                        <div></div>
                      </div>
                      <div className="border-b">
                        <div className="p-4  pl-0 flex justify-between items-center ">
                          <h4 className="text-black-300 bold text-base lg:w-3/12 md:w-3/12 ">
                            <i className={`mr-2  fa fa-info-circle`}></i>
                            Headings
                          </h4>
                          <div className=" ml-3 flex-1">
                            We found the following headings on the page:
                          </div>
                        </div>
                        <div className="p-4  pl-0 flex justify-between items-center ">
                          <h4 className="text-black-300 bold text-base lg:w-3/12 md:w-3/12 "></h4>
                          <div className=" ml-3 flex-1">
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h1  Count: ( ${
                                webPageData.h1.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h1"
                                headings={webPageData.h1 || []}
                              />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h2  Count: ( ${
                                webPageData.h2.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h2"
                                headings={webPageData.h2 || []}
                              />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h3  Count: ( ${
                                webPageData.h3.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h3"
                                headings={webPageData.h3 || []}
                              />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h4  Count: ( ${
                                webPageData.h4.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h4"
                                headings={webPageData.h4 || []}
                              />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h5  Count: ( ${
                                webPageData.h5.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h5"
                                headings={webPageData.h5 || []}
                              />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible
                              title={`h6  Count: ( ${
                                webPageData.h6.length || 0
                              } )`}
                            >
                              <HeadingList
                                tag="h6"
                                headings={webPageData.h6 || []}
                              />
                            </Collapsible>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      {getComputedRow(
                        "fa-info-circle",
                        "Favicon",
                        "Your website has a favicon.",
                        webPageData.favicon_url || ""
                      )}
                      <div className="border-b">
                        <div className="p-4 pl-0 flex justify-between items-center ">
                          <h3 className="text-black-300  text-base  lg:w-3/12 md:w-3/12">
                            <i className="fa fa-home mr-2"></i> On-page Links
                          </h3>
                          <div className=" ml-3 flex-1">
                            We found {webPageData.url.length || 0} links on the
                            page:
                          </div>
                        </div>
                        <div className="p-4 pl-0 flex justify-between items-center ">
                          <h3 className="text-black-300  text-base  lg:w-3/12 md:w-3/12"></h3>

                          <div className=" ml-3 flex-1 w-full">
                            <Collapsible title="External Links">
                              <LinksTable links={externalLinks} />
                            </Collapsible>
                            <div className="mt-3"></div>
                            <Collapsible title="Internal Links">
                              <LinksTable links={internalLinks} />
                            </Collapsible>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <div className="border-b">
                        <div className="p-4 pl-0 flex justify-between items-center ">
                          <h3 className="text-black-300  text-base lg:w-3/12 md:w-3/12">
                            <i className="fa fa-home mr-2"></i> Most common
                            words
                          </h3>

                          <div className=" ml-3 flex-1">
                            <p className="text-sm text-orange-500 font-bold">
                              Low keyword consistency.
                            </p>
                          </div>
                        </div>
                        <div className="p-4 pl-0 flex justify-between items-center overflow-x-scroll lg:overflow-x-auto">
                          <span className="text-black-300  text-base lg:w-3/12 md:w-3/12"></span>

                          <div className=" ml-3 flex-1">
                            <p className="text-sm text-gray-600 mb-5">
                              The analyzed page has low keyword consistency.
                            </p>
                            <h4 className="text-base font-bold">
                              Most common words on the page
                            </h4>
                            <WordsTable
                              words={webPageData.commonOneWords || []}
                            />
                            <div className="mt-3"></div>
                            <h4 className="text-base font-bold">
                              Most common 2 word phrases on the page
                            </h4>
                            <WordsTable
                              words={webPageData.commonTwoWords || []}
                            />
                            <div className="mt-3"></div>
                            <h4 className="text-base font-bold">
                              Most common 3 word phrases on the page
                            </h4>
                            <WordsTable
                              words={webPageData.commonThreeWords || []}
                            />
                          </div>
                          <div></div>
                        </div>
                      </div>
                      {getComputedRow(
                        "fa-info-circle",
                        "Words per page",
                        `Total word count ${webPageData.words_per_page || "0"}`
                      )}
                      <div className="border-b">
                        <div className="p-4  pl-0 flex justify-between items-center">
                          <h4 className="text-black-300 bold text-base lg:w-3/12 md:w-3/12">
                            <i className="mr-2 fa fa fa-info-circle text-orange-500"></i>
                            Image ALT attribute
                          </h4>
                          <div className=" ml-3 flex-1">
                            <p className="text-sm ">
                              We found {webPageData.missingAlt.length || "no"}{" "}
                              images on the page.
                            </p>
                            <p className="text-red-500 text-sm font-bold">
                              {webPageData.missingAlt.length || ""} alt
                              attributes are empty or missing.
                            </p>
                          </div>
                        </div>
                        <div className="p-4  pl-0 flex justify-between items-center">
                          <h4 className="text-black-300 bold text-base lg:w-3/12 md:w-3/12"></h4>
                          <div className=" ml-3 flex-1">
                            <h4 className="text-sm font-bold">#URL</h4>
                            <div className="mt-3">
                              {webPageData &&
                                webPageData.missingAlt &&
                                webPageData.missingAlt.map((link, index) => (
                                  <a
                                    key={index + 1}
                                    href={link}
                                    target="_blank"
                                    className="text-blueGray-500 block py-1"
                                  >
                                    <span className="text-blueGray-400 mr-2">
                                      {index + 1}.
                                    </span>
                                    {link}
                                  </a>
                                ))}
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6 w-full">
                        <div className="flex items-center flex-shrink-0 text-white mr-6 w-full">
                          <span className="font-semibold text-xl tracking-tight">
                            Not-so-good. Your website needs improvements.
                          </span>
                        </div>
                        <div>
                          {/* <a
                          href="#"
                          className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
                        >
                          Get Free Consultation
                        </a> */}
                          <div className="flex justify-end">
                            <a
                              href="https://marketmediaconnect.com/contact-us/"
                              target="_blank"
                              className="mt-5"
                            >
                              <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out border border-solid font-bold last:mr-0  text-white bg-teal-500 border-teal-500 active:bg-teal-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded">
                                FIX ERRORS
                              </button>
                            </a>
                          </div>
                        </div>
                      </nav>
                    </div>
                  )}
                  {openTab === 2 && (
                    <div className=" md:py-3 px-3 py-3">
                      <h2 className="text-black bold text-xl mt-2 uppercase font-bold">
                        Successful Audits
                      </h2>
                      <div className="p-4 pl-0 flex justify-between items-center">
                        <h3 className="text-black-300 bold text-base  font-bold">
                          On-Page SEO
                        </h3>
                        <i className="ml-2 fa fa fa-info-circle"></i>
                        <div
                          className="ml-3 bg-blueGray-600 flex-1"
                          style={{ height: "1px" }}
                        ></div>
                      </div>
                      {successData &&
                        successData.map((data, index) =>
                          getComputedRow(
                            "",
                            data.key,
                            data.value,
                            "",
                            "text-green-500 font-bold"
                          )
                        )}
                      <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6 w-full">
                        <div className="flex items-center flex-shrink-0 text-white mr-6 w-full">
                          <span className="font-semibold text-xl tracking-tight">
                            Not-so-good. Your website needs improvements.
                          </span>
                        </div>
                        <div>
                          {/* <a
                        href="#"
                        className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
                      >
                        Get Free Consultation
                      </a> */}
                          <div className="flex justify-end">
                            <a
                              href="https://marketmediaconnect.com/contact-us/"
                              target="_blank"
                              className="mt-5"
                            >
                              <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out border border-solid font-bold last:mr-0  text-white bg-teal-500 border-teal-500 active:bg-teal-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded">
                                FIX ERRORS
                              </button>
                            </a>
                          </div>
                        </div>
                      </nav>
                    </div>
                  )}
                  {openTab === 3 && (
                    <div className=" md:py-3 px-3 py-3">
                      <h2 className="text-black bold text-xl mt-2 uppercase font-bold">
                        Errors
                      </h2>
                      <div className="p-4 pl-0 flex justify-between items-center">
                        <h3 className="text-black-300 bold text-base  font-bold">
                          On-Page SEO
                        </h3>
                        <i className="ml-2 fa fa fa-info-circle"></i>
                        <div
                          className="ml-3 bg-blueGray-600 flex-1"
                          style={{ height: "1px" }}
                        ></div>
                      </div>
                      {error &&
                        error.map((data, index) =>
                          getComputedRow(
                            "",
                            data.key,
                            data.value,
                            "",
                            "text-red-500 font-bold"
                          )
                        )}
                      <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6 w-full">
                        <div className="flex items-center flex-shrink-0 text-white mr-6 w-full">
                          <span className="font-semibold text-xl tracking-tight">
                            Not-so-good. Your website needs improvements.
                          </span>
                        </div>
                        <div>
                          {/* <a
                        href="#"
                        className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
                      >
                        Get Free Consultation
                      </a> */}
                          <div className="flex justify-end">
                            <a
                              href="https://marketmediaconnect.com/contact-us/"
                              target="_blank"
                              className="mt-5"
                            >
                              <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out border border-solid font-bold last:mr-0  text-white bg-teal-500 border-teal-500 active:bg-teal-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded">
                                FIX ERRORS
                              </button>
                            </a>
                          </div>
                        </div>
                      </nav>
                    </div>
                  )}

                  {openTab === 4 && (
                    <div className=" md:py-3 px-3 py-3">
                      <h2 className="text-black bold text-xl mt-2 uppercase font-bold">
                        Warnings
                      </h2>
                      <div className="p-4 pl-0 flex justify-between items-center">
                        <h3 className="text-black-300 bold text-base  font-bold">
                          On-Page SEO
                        </h3>
                        <i className="ml-2 fa fa fa-info-circle"></i>
                        <div
                          className="ml-3 bg-blueGray-600 flex-1"
                          style={{ height: "1px" }}
                        ></div>
                      </div>
                      {warnings &&
                        warnings.map((data, index) =>
                          getComputedRow(
                            "",
                            data.key,
                            data.value,
                            "",
                            "text-orange-500 font-bold"
                          )
                        )}
                      <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6 w-full">
                        <div className="flex items-center flex-shrink-0 text-white mr-6 w-full">
                          <span className="font-semibold text-xl tracking-tight">
                            Not-so-good. Your website needs improvements.
                          </span>
                        </div>
                        <div>
                          {/* <a
                        href="#"
                        className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
                      >
                        Get Free Consultation
                      </a> */}
                          <div className="flex justify-end">
                            <a
                              href="https://marketmediaconnect.com/contact-us/"
                              target="_blank"
                              className="md:mt-5"
                            >
                              <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out border border-solid font-bold last:mr-0  text-white bg-teal-500 border-teal-500 active:bg-teal-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded">
                                FIX ERRORS
                              </button>
                            </a>
                          </div>
                        </div>
                      </nav>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
