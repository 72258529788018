import React, { useEffect, useState } from "react";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const PercentageCircularBar = ({ percentage }) => {
  const [barColor, setBarColor] = useState("");
  const color = (percentage) => {
    if (percentage >= 90) {
      setBarColor("#0c6");
    }
    if (parseInt(percentage) >= 50 && percentage < 90) {
      setBarColor("#fa3");
    }
    if (percentage < 50) {
      setBarColor("#f33");
    }
  };

  useEffect(() => {
    color(percentage);
  }, [percentage]);

  return (
    <>
      <CircularProgressbarWithChildren
        value={percentage}
        styles={{
          path: {
            // Customize the path/border color of the bar
            stroke: barColor,
          },
          trail: {
            // Customize the trail color (background of the bar)
            stroke: "#f2f2f2",
          },
        }}
      >
        <div style={{ fontSize: 20, marginTop: -5 }}>
          <strong>SEO Score</strong>
        </div>
        <div style={{ fontSize: 25 }}>
          <strong>{percentage}/100</strong>
        </div>
      </CircularProgressbarWithChildren>
    </>
  );
};

export default PercentageCircularBar;
