import React, { useEffect, useRef, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { useAuth } from "contexts/auth-context";

import Joi from "joi";

// components
import Button from "components/Shared/Button";
import Tabs from "components/Tabs/Tabs";
import Skeleton from "components/Shared/Skeleton";
import ProjectDropDown from "components/Dropdowns/ProjectsDropDown";

/** service import */
import { getDesktopPageSpeed, getMobilePageSpeed } from "services/pagespeed";
import { storeReportData } from "services/report";

import useAccountMiddleware from "hooks/useAccountMiddleware";

/**alert import */
import toast from "react-hot-toast";
import SignupModal from "components/Modals/SignupModal";
import PercentageProgressBar from "components/Shared/PercentageProgressBar";

/** fiedls validator */
const schema = Joi.object({
  domain: Joi.string()
    .regex(
      /^(https?:\/\/)?((?!-)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z]{2,})+)(\/.*)?$/
    )
    .label("Domain")
    .required()
    .messages({ "string.pattern.base": "* Invalid Domain" }),
});

export default function CardInput() {
  const authContext = useAuth();
  const [
    isSignUpCompleted,
    isPlanSubscribed,
    plan,
    ifSubscriptionCancelled,
    cancelPlan,
  ] = useAccountMiddleware();
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState(authContext?.state?.url || "");
  const [importantAudit, setImportantAudit] = useState(null);
  const [desktopLoading, setDesktopLoading] = useState(null);
  const [desktopPerformance, setDesktopPerformance] = useState(null);
  const [mobileImportantAudit, setMobileImportantAudit] = useState(null);
  const [mobilePerformance, setMobilePerformance] = useState(null);
  const [mobileLoading, setMobileLoading] = useState(null);
  const [project, setProject] = useState({
    domain: authContext?.state?.url || "",
  });
  const [projectCount, setProjectCount] = useState();
  const [showModal, setShowModal] = useState(false);
  const [averageResponseTime, setAverageResponseTime] = useState(0);
  const [capchaError, setCapchaError] = useState(
    "Please try again and check url. Your site is blocked or it uses capcha."
  );
  const history = useHistory();
  let myInterval = null;

  const analyzeMobilePageSpeed = (token) => {
    getMobilePageSpeed(domain, token).then((response) => {
      if (response && response.Audit) {
        setMobileImportantAudit(response[`Important-Audit`]);
      } else if (response && response.lighthouse) {
        setMobileImportantAudit(response?.lighthouse[`Important-Audit`]);
        setMobileLoading(response?.loadingexperince?.mterics);
      } else if (response && response.lighthouseResult) {
        setMobileImportantAudit(response?.lighthouseResult?.audits);
        setMobilePerformance(
          response?.lighthouseResult?.categories?.performance
        );
        setMobileLoading(response?.loadingexperince?.mterics);
        // setAverageResponseTime(100);
        // setTimeout(() => {
        //   setLoading(false);
        //   setAverageResponseTime(0);
        // }, 1000);
      } else if (
        response?.error?.message.includes("net::ERR_CONNECTION_FAILED") ||
        response?.error?.message.includes("error: FAILED_DOCUMENT_REQUEST")
      ) {
        setLoading(false);
        toast.error("Please Check Domain and try again.");
        setAverageResponseTime(0);
        clearInterval(myInterval);
      } else if (
        (response?.error?.errors &&
          response?.error?.errors[0]?.domain === "lighthouse") ||
        response?.error?.message.includes("error: ERRORED_DOCUMENT_REQUEST")
      ) {
        setCapchaError(
          "Please try again and check url. Your site is blocked or it uses capcha."
        );
        setLoading(false);
        setAverageResponseTime(0);
        toast.error(capchaError);
      } else {
        if (response.name === "AbortError") {
          console.log("Request aborted");
        } else {
          toast.error(response?.message || response?.error?.message);
        }
      }
    });
  };

  function myprogress() {
    setAverageResponseTime((preState) => {
      if (preState < 80) {
        return preState + 20;
      } else {
        return preState;
      }
    });
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (isSignUpCompleted() === false) {
      localStorage.removeItem("persist");
    }
    if ((!plan || (plan && plan?.title === "Free")) && cancelPlan === null) {
      if(authContext.state.guestUser){
        toast.error("You Need to Signup Before Proceeding.");
      }else{
        toast.error("You do not subscribed any plan!");
      }
      setTimeout(() => {
        history.push("/app/subscription");
      }, 1000);
      return;
    }
    setAverageResponseTime(0);
    let validator = schema.validate({ domain });
    if (!validator?.error) {
      myInterval = setInterval(() => {
        myprogress();
      }, 5000);
      setLoading(true);
      await analyzeMobilePageSpeed(authContext?.state?.authToken);
      await getDesktopPageSpeed(domain, authContext?.state?.authToken).then(
        (response) => {
          if (response && response.Audit) {
            setImportantAudit(response[`Important-Audit`]);
            setAverageResponseTime(100);
            setTimeout(() => {
              setLoading(false);
              setAverageResponseTime(0);
            }, 1000);
          } else if (response && response.lighthouseResult) {
            setImportantAudit(response?.lighthouseResult?.audits);
            setDesktopPerformance(
              response?.lighthouseResult?.categories?.performance
            );
            setDesktopLoading(response?.loadingexperince?.mterics);
            setAverageResponseTime(100);
            setTimeout(() => {
              setLoading(false);
              setAverageResponseTime(0);
            }, 1000);
          } else if (
            response?.error?.errors &&
            response?.error?.errors[0]?.domain === "lighthouse"
          ) {
            setCapchaError(
              "Please try again and check url. Your site is blocked or it uses capcha."
            );
            setLoading(false);
            setAverageResponseTime(0);
            toast.error(capchaError);
          } else {
            setLoading(false);
            if (response.name === "AbortError") {
              console.log("Request aborted");
            } else {
              toast.error(response?.message || response?.error?.message);
            }
            setAverageResponseTime(0);
            clearInterval(myInterval);
          }
        }
      );
    } else {
      if (projectCount === 0) {
        toast.error("No project added.");
      } else {
        toast.error(validator.error.message);
      }
      setLoading(false);
      setAverageResponseTime(0);
      clearInterval(myInterval);
    }
  };

  // const handleReportData = async (desktop, mobile) => {
  //   try {
  //     await storeReportData(
  //       authContext.state.authToken,
  //       project?.uuid,
  //       desktop,
  //       mobile
  //     ).then((response) => {
  //       if (response.status === false) {
  //         clearInterval(myInterval);
  //         setAverageResponseTime(0);
  //         toast.error(response.message);
  //       } else {
  //         setAverageResponseTime(100);
  //         clearInterval(myInterval);
  //         setTimeout(() => {
  //           setLoading(false);
  //           setAverageResponseTime(0);
  //           toast.success(response?.message);
  //         }, 1000);
  //       }
  //     });
  //   } catch (error) {
  //     toast.error(error);
  //     clearInterval(myInterval);
  //     setTimeout(() => {
  //       setLoading(false);
  //       setAverageResponseTime(0);
  //     }, 1000);
  //   }
  // };

  // useEffect(() => {
  //   if (desktopPerformance && mobilePerformance)
  //     handleReportData(desktopPerformance, mobilePerformance);
  // }, [desktopPerformance, mobilePerformance]);

  const autoFormSubmit = async () => {
    setAverageResponseTime(0);
    myInterval = setInterval(() => {
      myprogress();
    }, 5000);
    setLoading(true);
    await analyzeMobilePageSpeed();
    await getDesktopPageSpeed(domain).then((response) => {
      if (response && response.Audit) {
        setImportantAudit(response[`Important-Audit`]);
        setAverageResponseTime(100);
        setTimeout(() => {
          setLoading(false);
          setAverageResponseTime(0);
        }, 1000);
      } else if (response && response.lighthouseResult) {
        setImportantAudit(response?.lighthouseResult?.audits);
        setDesktopPerformance(
          response?.lighthouseResult?.categories?.performance
        );
        setDesktopLoading(response?.loadingexperince?.mterics);
        setAverageResponseTime(100);
        setTimeout(() => {
          setLoading(false);
          setAverageResponseTime(0);
        }, 1000);
      } else if (
        response?.error?.message.includes("net::ERR_CONNECTION_FAILED") ||
        response?.error?.message.includes("error: FAILED_DOCUMENT_REQUEST")
      ) {
        setLoading(false);
        toast.error("Please Check Domain and try again.");
        setAverageResponseTime(0);
        clearInterval(myInterval);
      } else if (
        (response?.error?.errors &&
          response?.error?.errors[0]?.domain === "lighthouse") ||
        response?.error?.message.includes("error: ERRORED_DOCUMENT_REQUEST")
      ) {
        setCapchaError(
          "Please try again and check url. Your site is blocked or it uses capcha."
        );
        setLoading(false);
        setAverageResponseTime(0);
        toast.error(capchaError);
      } else {
        setLoading(false);
        toast.error(response?.message || response?.error?.message);
        setAverageResponseTime(0);
        clearInterval(myInterval);
      }
    });
  };

  useEffect(() => {
    if (project && project.domain) setDomain(project.domain);
  }, [project]);

  useEffect(() => {
    if (authContext.state.guestUser) {
      autoFormSubmit();
    }
  }, [authContext.state.guestUser]);

  useEffect(() => {
    if (!authContext?.state?.guestUser) {
      isPlanSubscribed();
      ifSubscriptionCancelled();
    }
  }, []);

  return (
    <>
      <SignupModal
        showModal={showModal}
        hideModal={() => setShowModal(false)}
      />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0 py-10 overflow-x-hidden">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Page Speed</h6>
            <Link
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              to="/app/dashboard"
            >
              Go To homepage
            </Link>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 pt-0">
          <form id="page-speed-form" onSubmit={onFormSubmit}>
            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold">
              Make your web pages fast on all devices
            </h6>
            <div className="flex flex-wrap py-12 justify-center  items-center">
              <div className="w-full lg:w-6/12 px-4 ">
                <div className="relative w-full mb-3">
                  {/* <input
                    type="url"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue=""
                    placeholder="Enter domain here"
                    onChange={(e) => setDomain(e.target.value)}
                    disabled={loading === true ? true : false}
                  /> */}
                  <ProjectDropDown
                    project={project}
                    setProject={setProject}
                    setProjectCount={setProjectCount}
                  />
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <Button
                    classes="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    text={"Analyze"}
                    loading={loading}
                    type={"submit"}
                    onClick={() => {
                      return;
                    }}
                  ></Button>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4 ">
                <div className="relative w-full mb-3">
                  {loading ? (
                    <PercentageProgressBar
                      percentage={averageResponseTime}
                      domain={authContext?.state?.url}
                    ></PercentageProgressBar>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Skeleton />}
      {!loading && importantAudit && (
        <Tabs
          desktopStats={importantAudit}
          desktopPerformance={desktopPerformance}
          desktopLoading={desktopLoading}
          mobileStats={mobileImportantAudit}
          mobilePerformance={mobilePerformance}
          mobileLoading={mobileLoading}
        />
      )}
    </>
  );
}
