import { useState } from "react";
import { useAuth } from "contexts/auth-context";
import { getUserPlan } from "services/dashboard";
import { getCancelSubscription } from "services/subscription";
const useAccountMiddleware = () => {
  const auth = useAuth()?.state;
  const [plan, setPlan] = useState(null);
  const [cancelPlan, setCancelPlan] = useState(null);
  const isSignUpCompleted = () => {
    return auth && parseInt(auth.signupCompleted) === 1;
  };

  const isPlanSubscribed = async () => {
    try {
      await getUserPlan(auth?.authToken).then((response) => {
        if (response.status === false) {
          return false;
        } else {
          setPlan(response?.data);
        }
      });
    } catch (error) {
      return error;
    }
  };

  const ifSubscriptionCancelled = async () => {
    try {
      await getCancelSubscription(auth?.authToken).then((response) => {
        if (response.status === false) {
          return false;
        } else {
          setCancelPlan(response?.data);
        }
      });
    } catch (error) {
      return error;
    }
  };
  return [
    isSignUpCompleted,
    isPlanSubscribed,
    plan,
    ifSubscriptionCancelled,
    cancelPlan,
  ];
};

export default useAccountMiddleware;
