import react, { useEffect, useRef, useState } from "react";

import { useAuth } from "contexts/auth-context";

import { fetchProjectList, fetchProjectList2 } from "services/dashboard";

export const useProjectList2 = (query) => {
  const authToken = useAuth()?.state?.authToken;
  const user_id = useAuth()?.state?.user_id;
  const email = useAuth()?.state?.email;
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("idle");
  useEffect(() => {
    if (!query) return;
    if (email == "" || typeof email === "undefined") {
      fetchProjectList2(authToken)
        .then((response) => setProjects(response.data))
        .catch((error) => setError(error));
      setStatus("fetched");
    } else {
      fetchProjectList(authToken)
        .then((response) => setProjects(response.data))
        .catch((error) => setError(error));
      setStatus("fetched");
    }
  }, [query]);
  return [status, projects, error];
};

export const useProjectList = (query) => {
  const authToken = useAuth()?.state?.authToken;
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("idle");
  const email = useAuth()?.state?.email;
  useEffect(() => {
    if (!query) return;
    if (typeof email == "undefined" || email == "") {
      fetchProjectList2(authToken)
        .then((response) => {
          setProjects(response.data);
          setStatus("fetched");
        })
        .catch((error) => setError(error));
    } else {
      fetchProjectList(authToken)
        .then((response) => {
          setProjects(response.data);
          setStatus("fetched");
        })
        .catch((error) => setError(error));
    }
  }, [query]);
  return [status, projects, error];
};
