import React, { useEffect, useState } from "react";
import Joi from "joi";
import { useHistory } from "react-router-dom";

/** context import */
import { useAuth } from "contexts/auth-context";
import { useApp } from "contexts/app-context";

/* toast alert import*/
import toast from "react-hot-toast";

// components
import { composeTitle } from "utils/helper";
import { CountryDropdown } from "components/Dropdowns/CountryDropdown";
import Modal from "components/Modals/Modal";
//constants
import { pages } from "constant/constant";

import { getProfile, updateProfile } from "../../services/profile";

/** fields validator */
const schema = Joi.object({
  first_name: Joi.string()
    .min(3)
    .max(30)
    .required()
    .messages({ "string.empty": "Firstname is required" }),
  last_name: Joi.string()
    .min(3)
    .max(30)
    .required()
    .messages({ "string.empty": "Lastname is required" }),
  email: Joi.string()
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: ["com", "net"] },
    }),
  phone: Joi.string()
    .pattern(/^[0-9]+$/)
    .required()
    .messages({
      "string.empty": "Phone number is required",
      "string.pattern.base": "Please Provide Valid Phone Number",
    }),
  profileImage: Joi.optional(),
});

export default function AccountSettings() {
  const [openTab, setOpenTab] = useState(1);
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    profileImage: "",
  });
  const [fileDataURL, setFileDataURL] = useState(null);
  const [file, setFile] = useState(null);
  const appContext = useApp();
  const authContext = useAuth();
  const history = useHistory();
  const [clientError, setClientError] = useState(null);
  const [showModal, setShowModal] = useState(
    appContext?.state?.showModal || false
  );

  useEffect(() => {
    if (authContext?.state?.guestUser) {
      authContext.dispatch({
        type: authContext.actions.SET,
        data: {
          guestUser: false,
        },
      });
      localStorage.setItem("previousPage", "/app/page-speed");
      localStorage.removeItem("persist");
      history.push("/auth/register");
    }

    composeTitle(pages?.accountSettings);

    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.accountSettings,
      },
    });

    getProfile(authContext.state.authToken)
      .then((data) => {
        setUser({
          first_name: data.user?.first_name,
          last_name: data.user?.last_name,
          email: data.user?.email,
          phone: data.user?.phone,
          profileImage: data.user?.profileImage,
        });
      })
      .catch((error) => console.log(error));
  }, []);

  const hideModal = () => {
    setShowModal(!showModal);
    appContext.dispatch({
      type: appContext.actions.UPDATE,
      data: {
        showModal: !showModal,
      },
    });
    localStorage.removeItem("openTab");
  };

  useEffect(() => {
    setShowModal(appContext.state.showModal);
  }, [appContext.state.showModal]);

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const formSubmit = (event) => {
    event.preventDefault();
    let validator = schema.validate({ ...user });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setClientError(null);

      const formData = new FormData();
      formData.append("newProfileImage", file);
      formData.append("first_name", user.first_name);
      formData.append("last_name", user.last_name);
      formData.append("phone", user.phone);
      formData.append("profileImage", user.profileImage);

      updateProfile(authContext.state.authToken, formData)
        .then((response) => {
          if (response.status) {
            toast.success(response.message);
          } else if (response.errors) {
            toast.error(response.errors);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <Modal showModal={showModal} hideModal={hideModal} />
      <div className="w-full">
        <div className="bg-white mb-0 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
          <div className="w-full px-4 mx-auto px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="px-4 relative w-full md:w-12/12">
                <div className="relative py-12">
                  <section className="text-center block">
                    <div className="text-center">
                      <h3 className="text-3xl font-semibold mt-2 mb-4">
                        My Account
                      </h3>
                      <div></div>
                    </div>
                  </section>
                  <section className="block">
                    <ul
                      className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                      role="tablist"
                    >
                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                            (openTab === 1
                              ? "text-white bg-lightBlue-600"
                              : "text-lightBlue-600 bg-white")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(1);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          <i className="fas fa-user text-base mr-1"></i> Profile
                        </a>
                      </li>
                    </ul>
                  </section>
                  <div className="mt-4"></div>
                </div>
              </div>
              <div className="ml-auto px-4 relative w-full md:w-12/12 justify-center">
                {openTab === 1 && (
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex-auto">
                      <form onSubmit={formSubmit}>
                        <div className="container mx-auto px-4">
                          <div className="flex justify-between">
                            <h3 className="text-3xl font-semibold mt-4 mb-6">
                              Personal Information
                            </h3>
                          </div>
                          <div className="flex flex-wrap -mx-4">
                            <div className="px-4 pb-2 relative w-full lg:w-6/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                First name*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="E.g. Smith"
                                  type="text"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  onChange={handleInputChange}
                                  value={user?.first_name}
                                  name="first_name"
                                />
                                {clientError &&
                                  clientError?.context?.key ===
                                    "first_name" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-6/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Last name*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="E.g. John"
                                  type="text"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  onChange={handleInputChange}
                                  value={user?.last_name}
                                  name="last_name"
                                />
                                {clientError &&
                                  clientError?.context?.key === "lastName" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-6/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Email address*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="E.g. email@email.email"
                                  type="email"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  value={user?.email}
                                  readOnly
                                />
                                {clientError &&
                                  clientError?.context?.key === "email" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-6/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Phone*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="E.g. +1 (5417) 543 010"
                                  type="text"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  value={
                                    user?.phone === "null" ? "" : user?.phone
                                  }
                                  onChange={handleInputChange}
                                  name="phone"
                                />
                                {clientError &&
                                  clientError?.context?.key === "phone" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>

                            <div className="px-4 pb-2 relative w-full lg:w-6/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Profile*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  type="file"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  accept=".png, .jpg, .jpeg"
                                  onChange={(e) => setFile(e.target.files[0])}
                                />
                              </div>
                            </div>

                            <div className="px-4 pb-2 relative w-full lg:w-6/12">
                              {fileDataURL ? (
                                <p className="img-preview-wrapper">
                                  {
                                    <div className="mt-4">
                                      <img
                                        src={fileDataURL}
                                        alt="preview"
                                        height="100"
                                        width="100"
                                      />
                                    </div>
                                  }
                                </p>
                              ) : (
                                <div className="mt-4">
                                  <img
                                    src={
                                      user?.profileImage &&
                                      user?.profileImage !== "null"
                                        ? "https://webwatch.marketmediaconnect.com/adminapi/" +
                                          "/" +
                                          user?.profileImage
                                        : "https://webwatch.marketmediaconnect.com/adminapi/" +
                                          "/uploads/user_image/placeholder.png"
                                    }
                                    alt=""
                                    height="100"
                                    width="100"
                                  />
                                </div>
                              )}
                            </div>

                            {/* <div className="px-4 pb-2 relative w-full lg:w-8/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Street address*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="E.g. 420 Long Beach, CA"
                                  type="text"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                />
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-4/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Postcode/Zip*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="E.g. 340112"
                                  type="text"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                />
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-4/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Apt, suite, etc.
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="E.g. YC7B 3UT"
                                  type="text"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                />
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-4/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                City*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="E.g. London"
                                  type="text"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                />
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-4/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Country*
                              </label>
                              <CountryDropdown />
                            </div> */}
                          </div>

                          <div className="flex justify-between mt-12 mb-8">
                            <p></p>

                            <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-white bg-blueGray-800 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md">
                              Update
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="mb-12 justify-center flex flex-wrap w-full">
                <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-red-500 border-red-500 active:bg-red-600 active:border-red-600 active:text-white text-sm px-6 py-2 shadow hover:shadow-lg rounded-md">
                  Cancel
                </button>
                <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-white bg-blueGray-800 border-blueGray-800 active:bg-blueGray-900 active:border-blueGray-900 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md">
                  Save Change
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
