export  const useEmailValidation = (email) => {
    if (!email || email.length === 0) {
      return 'Email cannot be empty';
    }
  
    const isEmailValid = email.trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/); // use any validator you want
    if (!isEmailValid) {
      return 'Invalid email provided';
    }
  
    return null;
  };