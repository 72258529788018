import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/app/Dashboard.js";
import PageSpeed from "views/app/PageSpeed";
import PageDetail from "views/app/PageDetail";
import WebAnalyzer from "views/app/WebAnalyzer";
import Subscription from "views/app/Subscription";
import AccountSettings from "views/app/AccountSettings";
import ChangePassword from "views/app/ChangePassword";
import Pricing from "views/app/Pricing";
import Invoice from "views/app/Invoice";
import Checkout from "views/app/Checkout";
import Reports from "views/app/Reports";
import Keywords from "views/app/Keywords.js";
import BestPages from "views/app/BestPages";
import Competitors from "views/app/Competitors";
import Settings from "views/app/Settings.js";
import Tables from "views/app/Tables.js";
// import InvoicePdf from "views/app/InvoicePdf";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/app/dashboard" exact component={Dashboard} />
            <Route path="/app/keywords" exact component={Keywords} />
            <Route path="/app/best-pages" exact component={BestPages} />
            <Route path="/app/competitors" exact component={Competitors} />
            <Route path="/app/page-speed" exact component={PageSpeed} />
            <Route path="/app/web-analyzer" exact component={WebAnalyzer} />
            <Route path="/app/pricing" exact component={Pricing} />
            <Route path="/app/subscription" exact component={Subscription} />
            <Route path="/app/checkout" exact component={Checkout} />
            <Route
              path="/app/project-detail/:id/:type"
              exact
              component={PageDetail}
            />
            <Route
              path="/app/change-password"
              exact
              component={ChangePassword}
            />
            <Route
              path="/app/account-settings"
              exact
              component={AccountSettings}
            />
            <Route path="/app/reports" exact component={Reports} />
            <Route path="/app/invoice/:billingId" exact component={Invoice} />
            {/* <Route
              path="/app/invoice-pdf/:billingId"
              exact
              component={InvoicePdf}
            /> */}
            <Route path="/app/settings" exact component={Settings} />
            <Route path="/app/tables" exact component={Tables} />
            <Redirect from="/app" to="/app/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
