import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

/** context import */
import { useApp } from "contexts/app-context";
import { useAuth } from "contexts/auth-context";

// components
import { composeTitle } from "utils/helper";
import { formatDate } from "utils/helper";

//constants
import { pages, contacts } from "constant/constant";

/** service import */
import { fetchInvoice } from "services/invoice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";


import logoDark from "assets/logo/logo-dark.png";

export default function Subscription() {
  const appContext = useApp();
  const pdfRef = React.useRef();
  const [isDownloading, setIsDownloading] = useState(false);
  const auth = useAuth()?.state;
  const [invoice, setInvoice] = useState(null);
  const { billingId } = useParams();

  const getDetailInvoice = async () => {
    const response = await fetchInvoice(auth?.authToken, billingId);
    if (response.status === true) {
      setInvoice(response.data);
    }
  };

  useEffect(() => {
    composeTitle(pages?.invoice);

    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.invoice,
      },
    });
    getDetailInvoice();
  }, []);

  function download() {
    setIsDownloading(true); // Set download status to true

    setTimeout(() => {
      const capture = pdfRef.current;
      html2canvas(capture, { dpi: 300 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const doc = new jsPDF("l", "px", "a4");
        const componentWidth = doc.internal.pageSize.getWidth();
        const componentHeight = doc.internal.pageSize.getHeight();
        doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
        doc.save("receipt.pdf");
        setIsDownloading(false); // Reset download status to false after download is complete
      });
    }, 1000);
  }

  return (
    <>
      <div className="w-full" ref={pdfRef}>
        <div className="bg-white shadow-lg rounded-lg relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg">
          <div className="px-5 pt-6 pb-4 border-b border-blueGray-200">
            <div className="justify-between flex flex-wrap -mx-4">
              <div className="text-left px-4 relative w-full md:w-4/12">
                <div className="text-left">
                  <img
                  src={logoDark}
                    alt="company-logo"
                    className="w-full mb-2"
                  />
                  <h6 className="block capitalize text-blueGray-700 mt-6">
                    {contacts.addressLine1}, {contacts.addressLine2}
                  </h6>
                  <h6 className="text-blueGray-700">tel: {contacts.phone}</h6>
                </div>
              </div>
              <div className="text-left px-4 relative w-full lg:w-3/12 w-full md:w-5/12">
                {!isDownloading && ( // Render the button only if not downloading
                  <button
                    onClick={download}
                    className="iinline-block rounded outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out  border border-solid font-bold  text-white active:bg-blueGray-50 bg-blueGray-700 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md"
                  >
                    Download
                  </button>
                )}
                <div className="flex justify-center py-6 lg:pt-6 pt-12">
                  <div className="text-left">
                    <h3 className="text-2xl font-bold leading-normal mt-0 mb-2">
                      Billed to:
                    </h3>
                    <h6 className="block mt-2 mb-0 text-xl">{invoice?.Name}</h6>
                    <p className="text-blueGray-500">{invoice?.email}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:justify-between flex flex-wrap -mx-4">
              <div className="text-left px-4 relative w-full md:w-4/12">
                <h4 className="text-2xl font-semibold leading-normal mb-2 mt-12 text-left">
                  Invoice no
                </h4>
                <h3 className="text-2xl font-normal leading-normal mt-1 mb-2 font-light">
                  #{invoice?.uuid}
                </h3>
              </div>
              <div className="text-left px-4 relative w-full lg:w-3/12 w-full md:w-5/12">
                <div className="flex justify-center py-6 lg:pt-4">
                  <div className="mt-12">
                    <p className="float-left mb-0">Invoice date:</p>
                    <p className="float-right mb-0">
                      {formatDate(invoice?.subscriptionDate)}
                    </p>
                    <br />
                    <p className="float-left">Due date:</p>
                    {formatDate(invoice?.subscriptionDate)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 flex-auto">
            <table className="text-right w-full mb-4 text-blueGray-800 border-collapse">
              <thead className="bg-blueGray-800">
                <tr className="text-right text-white uppercase font-light">
                  <th className="p-3 border-t" scope="col">
                    Item
                  </th>
                  <th className="p-3 border-t" scope="col">
                    Qty
                  </th>
                  <th className="p-3 border-t" scope="col">
                    Price
                  </th>
                  <th className="p-3 border-t" scope="col">
                    Amount Deducted
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-4 p-3 border-t">
                    SEO {invoice?.title} Dashboard
                  </td>
                  <td className="py-4 p-3 border-t">1</td>
                  <td className="py-4 p-3 border-t">
                    ${invoice?.package_price}
                  </td>
                  <td className="py-4 p-3 border-t">${invoice?.payment}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr className="mt-4">
                  <th className="border-b-0 p-3 border-t">
                    <p className="text-lg font-semibold pt-2">Total</p>
                  </th>
                  <th className="border-b-0 p-3 border-t" colSpan="4">
                    <p className="text-right text-lg font-semibold pt-2">
                      ${invoice?.payment}
                    </p>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="px-4 py-3 border-t border-blueGray-200">
            <div className="text-right ml-auto px-4 relative w-full md:w-5/12">
              <h5 className="text-2xl font-semibold leading-normal mt-0 mb-2">
                Thank you!
              </h5>
              <p className="">If you encounter any issues can contact us</p>
              <h6 className="flex items-center justify-end mb-4">
                <span className="mr-2">email:</span>
                <span className="text-blueGray-500">{contacts?.email}</span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
