import React, { useEffect, useState } from "react";
import Joi from "joi";
import { useHistory } from "react-router-dom";

/** context import */
import { useApp } from "contexts/app-context";
import { useAuth } from "contexts/auth-context";
// components
import { composeTitle } from "utils/helper";
import Modal from "components/Modals/Modal";

//import { CountryDropdown } from "components/Dropdowns/CountryDropdown";
//constants
import { pages } from "constant/constant";
import { updatePassword } from "../../services/profile"

/* toast alert import*/
import toast from "react-hot-toast";

/** fields validator */
const schema = Joi.object({
  old_password: Joi.string()
    .required()
    .messages({ "string.empty": "Current Password is required" }),
  new_password: Joi.string().min(8)
    .required()
    .messages({ "string.empty": "New Password is required", "string.min": "Password must be minimum 8 characters long" }),
  confirm_password: Joi.string().min(8).valid(Joi.ref('new_password'))
    .required().messages({ "string.empty": "Confirm Password is required", "any.only": "Password mismatch " }),
});

export default function ChangePassword() {
  const history = useHistory();
  const [openTab, setOpenTab] = useState(1);
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const appContext = useApp();
  const authContext = useAuth();
  const [clientError, setClientError] = useState(null);
  const [showModal, setShowModal] = useState(
    appContext?.state?.showModal || false
  );
  const hideModal = () => {
    setShowModal(!showModal);
    appContext.dispatch({
      type: appContext.actions.UPDATE,
      data: {
        showModal: !showModal,
      },
    });
  };

  useEffect(() => {
    setShowModal(appContext.state.showModal);
  }, [appContext.state.showModal]);
  useEffect(() => {
    composeTitle(pages?.changePassword);

    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.changePassword,
      },
    });
    localStorage.removeItem("openTab");

    if (authContext?.state?.guestUser) {
      authContext.dispatch({
        type: authContext.actions.SET,
        data: {
          guestUser: false,
        },
      });
      localStorage.setItem("previousPage", "/app/page-speed");
      localStorage.removeItem("persist");
      history.push("/auth/register");
    }
  }, []);


  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let validator = schema.validate({ ...formData });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setClientError(null);
      const newFormData = new FormData();
      newFormData.append("old_password", formData.old_password);
      newFormData.append("new_password", formData.new_password);
      newFormData.append("confirm_password", formData.confirm_password);
      // Perform api call here
      updatePassword(authContext?.state?.authToken, newFormData)
        .then(response => {
          if (response.status) {
            setFormData({
              old_password: "",
              new_password: "",
              confirm_password: "",
            });
            toast.success(response.message);
          } else if (response.errors) {
            toast.error(response.errors);
          }
        }).catch(error => console.log(error))
    }
  }
  return (
    <>
      <Modal showModal={showModal} hideModal={hideModal} />
      <div className="w-full">
        <div className="bg-white mb-0 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
          <div className="w-full px-4 mx-auto px-4 ">
            <div className="flex flex-wrap -mx-4 items-center justify-center">
              <div className="px-4 relative w-full md:w-8/12">
                <div className="relative py-12">
                  <section className="text-center block">
                    <div className="text-center">
                      <h3 className="text-3xl font-semibold mt-2 mb-4">
                        My Account
                      </h3>
                      <div></div>
                    </div>
                  </section>
                  <section className="block">
                    <ul
                      className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                      role="tablist"
                    >
                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                            (openTab === 1
                              ? "text-white bg-lightBlue-600"
                              : "text-lightBlue-600 bg-white")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(1);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          <i className="fas fa-user text-base mr-1"></i> Profile
                        </a>
                      </li>
                    </ul>
                  </section>
                  <div className="mt-4"></div>
                </div>
              </div>
              <div className="px-4 relative w-full md:w-8/12 justify-center">
                {openTab === 1 && (
                  <div className="relative flex flex-col min-w-0 break-words bg-white mb-6 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex-auto">
                      <form
                        className="myForm"
                        autoComplete="off"
                        onSubmit={onSubmit}>
                        <div className="container mx-auto px-4">
                          <div className="flex justify-between">
                            <h3 className="text-3xl font-semibold mt-4 mb-6">
                              Change Password
                            </h3>
                          </div>
                          <div className="flex flex-wrap -mx-4">
                            <div className="px-4 pb-2 relative w-full lg:w-12/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Current Password*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="Enter your current password"
                                  type="password"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  name="old_password"
                                  onChange={handleInputChange}
                                  value={formData.old_password}
                                />
                                {clientError &&
                                  clientError?.context?.key ===
                                  "old_password" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-12/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                New Password*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="Enter new password"
                                  type="password"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  name="new_password"
                                  onChange={handleInputChange}
                                  value={formData.new_password}
                                />
                                {clientError &&
                                  clientError?.context?.key ===
                                  "new_password" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="px-4 pb-2 relative w-full lg:w-12/12">
                              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                Confirm Password*
                              </label>
                              <div className="mb-3 pt-0">
                                <input
                                  placeholder="Confirm Password"
                                  type="password"
                                  className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                  name="confirm_password"
                                  onChange={handleInputChange}
                                  value={formData.confirm_password}
                                />
                                {clientError &&
                                  clientError?.context?.key ===
                                  "confirm_password" && (
                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                      {clientError?.message}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-between mt-12 mb-8">
                            <p></p>

                            <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-white bg-blueGray-800 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md">
                              Update Password
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
