import React from "react";

import { Link } from "react-router-dom";

/**component import */

import Button from "components/Shared/Button";
import { string } from "joi";

export default function PriceCard({
  title,
  planId,
  price,
  type,
  currency,
  description,
  actionTitle,
  other,
  action,
  loading,
  activePlan,
  planTrail,
  actionFreeTrail,
  cancelPlan,
}) {
  return (
    <div className="w-full">
      <div
        title={activePlan && activePlan.title === title ? "Active Plan" : ""}
        className={`shadow-lg rounded-lg text-center cursor-pointer text-blueGray-500 relative flex flex-col min-w-0 break-words w-full mb-6  custom-shadow ${
          activePlan && activePlan.title === title
            ? "bg-blueGray-600"
            : "bg-blueGray-800"
        }`}
      >
        <div className="bg-transparent first:rounded-t px-5 py-3 border-b border-blueGray-700">
          <h4 className="font-bold uppercase my-2 text-white">{title}</h4>
        </div>

        <div className="px-4 py-5 flex-auto">
          {price && (
            <div className="text-5xl font-normal leading-tight font-bold text-white">
              {currency && currency}
              {price}
            </div>
          )}
          <span className="text-lg text-white opacity-50">{type}</span>
          <div
            className={
              planTrail ? "flex justify-center py-4 align-center" : "py-1"
            }
          >
            {activePlan ? (
              activePlan && activePlan?.title === title ? (
                <span className="inline-flex text-center rounded-full mr-3 mt-2 text-2xl items-center justify-center w-8 h-8 text-blueGray-500 bg-blueGray-200">
                  <i className="text-emerald-500  fas fa-check"></i>
                </span>
              ) : activePlan &&
                parseInt(activePlan?.package_price) > parseInt(price) ? (
                <Button
                  text="Downgrade"
                  onClick={action}
                  loading={activePlan && activePlan?.title === title}
                  disabled={activePlan && activePlan?.title === title}
                  classes="mr-2 inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold last:mr-0 text-white bg-lightBlue-500 border-lightBlue-500 active:bg-lightBlue-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded custom-shadow py-2"
                />
              ) : (
                <Button
                  text="Upgrade"
                  onClick={action}
                  loading={activePlan && activePlan?.title === title}
                  disabled={activePlan && activePlan?.title === title}
                  classes="mr-2 inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold last:mr-0 text-white bg-lightBlue-500 border-lightBlue-500 active:bg-lightBlue-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded custom-shadow py-2"
                />
              )
            ) : (
              <Button
                text="Subscribe"
                onClick={action}
                loading={activePlan && activePlan?.title === title}
                disabled={activePlan && activePlan?.title === title}
                classes="mr-2 inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold last:mr-0 text-white bg-lightBlue-500 border-lightBlue-500 active:bg-lightBlue-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded custom-shadow py-2"
              />
            )}

            {/* <Button
              text={
                activePlan
                  ? activePlan && activePlan?.title === title
                    ? "Subscribed"
                    : activePlan && activePlan?.payment > price
                    ? "Downgrade"
                    : "Upgrade"
                  : "Subscribe"
              }
              onClick={action}
              loading={activePlan && activePlan?.title === title}
              disabled={activePlan && activePlan?.title === title}
              classes="mr-2 inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold last:mr-0 text-white bg-lightBlue-500 border-lightBlue-500 active:bg-lightBlue-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded custom-shadow"
            /> */}

            {!loading && (
              <div>
                {planTrail && activePlan === null && (
                  <>
                    <Link to="/app/checkout">
                      <button
                        onClick={actionFreeTrail}
                        className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold last:mr-0  text-white bg-orange-500 border-orange-500 active:bg-orange-600 text-sm px-4 py-2 shadow hover:shadow-lg rounded custom-shadow"
                      >
                        Free Trail
                      </button>
                    </Link>
                  </>
                )}
              </div>
            )}
          </div>
          <ul className="mt-4 mb-8 list-none">
            {description &&
              description.map((item, index) => (
                <li className="py-2" key={index}>
                  <div className="flex items-center">
                    <h6 className="text-lg text-white opacity-75">
                      {item.desc == "WEB ANALYZER" ? (
                        <strong className="text-dark">{item.desc}</strong>
                      ) : item.desc == "PAGE SPEED ANALYZER" ? (
                        <strong className="text-dark">{item.desc}</strong>
                      ) : item.desc == "REPORT CENTER" ? (
                        <strong className="text-dark">{item.desc}</strong>
                      ) : (
                        <p>
                          <span className="inline-flex text-center rounded-full mr-3 items-center justify-center w-8 h-8 text-sm text-blueGray-500 bg-blueGray-200">
                            <i
                              className={
                                item?.included === true
                                  ? "text-emerald-500  fas fa-arrow-right"
                                  : "text-red-500  fas fa-times-circle"
                              }
                            ></i>
                          </span>{" "}
                          {item.desc}
                        </p>
                      )}
                    </h6>
                  </div>
                </li>
              ))}
          </ul>
          <div className="block w-full overflow-x-auto">
            <div className="w-full">
              <div className="flex items-center justify-between py-2">
                <div className="flex items-center">
                  <span className="inline-flex text-center rounded-full mr-3 items-center justify-center w-8 h-8 text-sm text-lightBlue-500 bg-lightBlue-200">
                    <i className="fas fa-hat-wizard"></i>
                  </span>
                  <span className="text-white capatalize text-sm">
                    Projects
                  </span>
                </div>
                <span className="text-white">{other.projects}</span>
              </div>
              <div className="border-t border-blueGray-200 flex items-center justify-between py-2">
                <div className="flex items-center">
                  <span className="inline-flex text-center rounded-full mr-3 items-center justify-center w-8 h-8 text-sm text-lightBlue-500 bg-lightBlue-200">
                    <i className="fas fa-calendar-week"></i>
                  </span>
                  <span className="text-white capatalize text-sm">
                    Update Frequency
                  </span>
                </div>
                <span>{other.updateFrequency}</span>
              </div>
              {/* <div className="border-t border-blueGray-200 flex items-center justify-between py-2">
                <div className="flex items-center">
                  <span className="inline-flex text-center rounded-full mr-3 items-center justify-center w-8 h-8 text-sm text-lightBlue-500 bg-lightBlue-200">
                    <i className="fas fa-search-plus"></i>
                  </span>
                  <span className="text-white capatalize text-sm">
                    Number of keywords update
                  </span>
                </div>
                <span>{other.numberOfKeywordsUpdate}</span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="bg-transparent mt-2 py-4 bg-transparent rounded-b px-4 py-3 border-t border-blueGray-700">
          <div
            className={planTrail ? "flex justify-center py-4 align-center" : ""}
          >
            {/* <Button
              text={
                activePlan
                  ? activePlan && activePlan?.title === title
                    ? "Subscribed"
                    : activePlan && activePlan?.payment > price
                    ? "Downgrade"
                    : "Upgrade"
                  : "Subscribe"
              }
              onClick={action}
              loading={activePlan && activePlan?.title === title}
              disabled={activePlan && activePlan?.title === title}
              classes="mr-2 inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold last:mr-0 text-white bg-lightBlue-500 border-lightBlue-500 active:bg-lightBlue-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded"
            /> */}

            {activePlan ? (
              activePlan && activePlan?.title === title ? (
                <span className="inline-flex text-center rounded-full mr-3 text-2xl items-center justify-center w-8 h-8 text-blueGray-500 bg-blueGray-200">
                  <i className="text-emerald-500  fas fa-check"></i>
                </span>
              ) : parseInt(activePlan?.package_price) > parseInt(price) ? (
                <Button
                  text="Downgrade"
                  onClick={action}
                  loading={activePlan && activePlan?.title === title}
                  disabled={activePlan && activePlan?.title === title}
                  classes="mr-2 inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold last:mr-0 text-white bg-lightBlue-500 border-lightBlue-500 active:bg-lightBlue-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded custom-shadow py-2"
                />
              ) : (
                <Button
                  text="Upgrade"
                  onClick={action}
                  loading={activePlan && activePlan?.title === title}
                  disabled={activePlan && activePlan?.title === title}
                  classes="mr-2 inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold last:mr-0 text-white bg-lightBlue-500 border-lightBlue-500 active:bg-lightBlue-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded custom-shadow py-2"
                />
              )
            ) : (
              <Button
                text="Subscribe"
                onClick={action}
                loading={activePlan && activePlan?.title === title}
                disabled={activePlan && activePlan?.title === title}
                classes="mr-2 inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold last:mr-0 text-white bg-lightBlue-500 border-lightBlue-500 active:bg-lightBlue-600 text-sm px-4 py-1 shadow hover:shadow-lg rounded custom-shadow py-2"
              />
            )}
            {!loading && (
              <div>
                {planTrail && activePlan === null && (
                  <>
                    <Link to="/app/checkout">
                      <button
                        onClick={actionFreeTrail}
                        className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold last:mr-0  text-white bg-orange-500 border-orange-500 active:bg-orange-600 text-sm px-4 py-2 shadow hover:shadow-lg rounded custom-shadow"
                      >
                        Free Trail
                      </button>
                    </Link>
                  </>
                )}
              </div>
            )}
          </div>
          {/* <p className="text-white pt-3 mt-5">
            <Link to="/app/checkout">
              <button
                onClick={actionFreeTrail}
                classes="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out border border-solid font-bold last:mr-0 mr-2  text-white"
              >
                {actionDesc}
              </button>
            </Link>
          </p> */}
        </div>
      </div>
    </div>
  );
}
