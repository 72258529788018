import React from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "contexts/auth-context";
import { session } from "constant/constant";

const PageDetailStats = ({
  WebPageLoadingEmulation,
  ErrorsToFix,
  successfulAudits,
}) => {
  const [openTab, setOpenTab] = React.useState(1);

  const authContext = useAuth();
  const [auth, setAuth] = React.useState(authContext.state);
  const history = useHistory();

  const redirectToRegister = () => {
    localStorage.setItem("previousPage", history.location.pathname);
    if (authContext?.state?.guestUser) {
      localStorage.removeItem("persist");
      authContext.dispatch({
        type: authContext.actions.DEAUTHENTICATE,
      });
      history.push("/auth/register");
    }
  };
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full ">
          {auth.guestUser && (
            <div className="bg-white absolute z-40 inset-0 h-full opacity-75">
              <div className="flex flex-col justify-center items-center w-full pt-20">
                <h2 className="text-black text-2xl uppercase font-bold z-40">
                Signup for Free to View Full Report
                </h2>
                <a
                  className={
                    "p-5 text-white bg-lightBlue-600 font-bold z-40 uppercase py-3 shadow-lg rounded block leading-normal mt-5"
                  }
                  href="javascript:void(0)"
                  onClick={(e) => {
                    e.preventDefault();
                    redirectToRegister();
                  }}
                >
                  <i className="fas fa-user-plus mr-1"></i> Free Signup Now
                </a>
              </div>
            </div>
          )}
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row mx-4"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center mt-5">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-lightBlue-600"
                    : "text-lightBlue-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                WebPage Loading emulation
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center mt-5">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-lightBlue-600"
                    : "text-lightBlue-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (!auth.guestUser) {
                    setOpenTab(2);
                  }
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Errors to fix
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center mt-5">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 3
                    ? "text-white bg-lightBlue-600"
                    : "text-lightBlue-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (!auth.guestUser) {
                    setOpenTab(3);
                  }
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Success full audits
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  {WebPageLoadingEmulation}
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  {ErrorsToFix}
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  {successfulAudits}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageDetailStats;
