import React, { useEffect, useState } from "react";

import CardStats from "./CardStats";
// components

export default function CardProjects({
  projects = [],
  setProjectsFilter = null,
}) {
  const [filterProjects, setFilterProjects] = useState(null);

  useEffect(() => {
    if (filterProjects) {
      setProjectsFilter(filterProjects);
    }
  }, [filterProjects]);
  const formateDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded mb-3 p-3">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Projects (
                {
                  projects.filter((project) => project.uuid !== filterProjects)
                    .length
                }
                )
              </h3>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4">
          {projects &&
            projects.map((project) => (
              <div className="" key={project.uuid}>
                <CardStats
                  setFilterProjects={setFilterProjects}
                  projectId={project.uuid}
                  statSubtitle={"#" + project.uuid}
                  statTitle={project.title}
                  domain={project.domain}
                  statArrow="up"
                  statPercent="2"
                  statPercentColor="text-emerald-500"
                  statDescripiron="Top 100"
                  statIconName="fa fa-ellipsis-stroke-vertical"
                  statIconColor="text-red-500"
                  performance={project.desktopPerformance}
                  date={formateDate(project.created_at)}
                  reportId={project.reportId}
                  analyzerId={project.analyzerId}
                  // statIconColor="background-color-red-700"
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
