import React, { useState, useEffect } from "react";
import Joi from "joi";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";

/** common import */
import { composeTitle } from "utils/helper";
import Button from "components/Shared/Button";

/**Context import */
import { useAuth } from "contexts/auth-context";

/** constants */
import { session } from "constant/constant";
import { store } from "constant/constant";

/**backend service import */
import { completeSignup } from "services/auth";
/** fiedls validator */
const schema = Joi.object({
  firstName: Joi.string().alphanum().min(3).max(30).required(),
  lastName: Joi.string().alphanum().min(3).max(30).required(),
  email: Joi.string()
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: ["com", "net"] },
    }),
  password: Joi.string().pattern(new RegExp("^[a-zA-Z0-9]{3,30}$")),
});

export default function CompleteSignUp() {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const { email, userId } = useParams();
  const [clientError, setClientError] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const authContext = useAuth();

  const handleClick = async (e) => {
    let validator = schema.validate({ ...user });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
      console.log(validator.error.details?.[0]);
    } else {
      setClientError(null);

      try {
        setLoading(true);
        const response = await completeSignup({ ...user, userId });
        if (response?.status === false) {
          setLoading(false);
          toast.error(response.message);
        } else {
          toast.success(response.message);
          let user = response.data;
          if (localStorage.getItem(store.session) !== "") {
            localStorage.setItem(store.session, JSON.stringify(response.data));
          } else if (
            localStorage.getItem(store.session).email !== response.data.email
          )
            localStorage.setItem(store.session, JSON.stringify(response.data));
          authContext.dispatch({
            type: authContext.actions.SET,
            data: {
              role: user.role,
              authToken: user.token,
              userId: user.user_id,
              userName: user.first_name + " " + user.last_name,
              email: user.email,
              status: session.authenticated,
              signupCompleted: user.sign_up,
              emailVerified: user.email_verified,
              signupDate: user.signed_up_date,
            },
          });
          setTimeout(() => {
            history.push("/app/dashboard");
          }, 2000);
        }
      } catch (err) {
        setLoading(false);
        toast.error(err);
      }
    }
  };

  useEffect(() => {
    setUser({ ...user, email: email });
    composeTitle("Complete SignUp");
  }, []);
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Complete Signup
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>
                    You're 1 step away to use! <b>It's quick & easy.</b>
                  </small>
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="First Name"
                      autoComplete="off"
                      onChange={(e) =>
                        setUser({ ...user, firstName: e.target.value })
                      }
                      value={user.firstName}
                      required
                    />
                    {clientError &&
                      clientError?.context?.key === "firstName" && (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {clientError?.message}
                        </span>
                      )}
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Last Name"
                      autoComplete="off"
                      onChange={(e) =>
                        setUser({ ...user, lastName: e.target.value })
                      }
                      value={user.lastName}
                      required
                    />
                    {clientError &&
                      clientError?.context?.key === "lastName" && (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {clientError?.message}
                        </span>
                      )}
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      autoComplete="off"
                      readOnly
                      onChange={(e) => e.preventDefault()}
                      value={user.email}
                      required
                    />
                    {clientError && clientError?.context?.key === "email" && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {clientError?.message}
                      </span>
                    )}
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      autoComplete="off"
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                      value={user.password}
                      required
                    />
                    {clientError &&
                      clientError?.context?.key === "password" && (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {clientError?.message}
                        </span>
                      )}
                  </div>

                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        I agree with the{" "}
                        <a
                          href="#pablo"
                          className="text-lightBlue-500"
                          onClick={(e) => e.preventDefault()}
                        >
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <Button
                      classes="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      text={"Complete Process"}
                      onClick={handleClick}
                      loading={loading}
                    ></Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
