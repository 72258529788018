export const errorCount = (data) => {
  try {
    let errorAuditsCount = 0;
    let tags = data?.tags || null;

    if (tags && tags.title.length === 0) errorAuditsCount++;
    if (tags && tags.description.length === 0) errorAuditsCount++;
    if (tags && tags.robots.length === 0) errorAuditsCount++;
    if (tags && tags.viewport.length === 0) errorAuditsCount++;
    if (tags && tags.twitterCard.length === 0) errorAuditsCount++;
    if (tags && tags.googleSiteVerification.length === 0) errorAuditsCount++;
    if (data.h1 && data.h1.length === 0) errorAuditsCount++;
    if (data.h2 && data.h2.length === 0) errorAuditsCount++;
    if (data.h3 && data.h3.length === 0) errorAuditsCount++;
    if (data.h4 && data.h4.length === 0) errorAuditsCount++;
    if (data.h5 && data.h5.length === 0) errorAuditsCount++;
    if (data.h6 && data.h6.length === 0) errorAuditsCount++;
    if (data.webStatus && parseInt(data.webStatus) !== 200) errorAuditsCount++;
    if (data.favicon_url && data.favicon_url.length === 0) errorAuditsCount++;

    return errorAuditsCount;
  } catch (e) {
    return e.message || e;
  }
};
export const auditsCount = (data) => {
  try {
    let successfulAuditsCount = 0;
    const tags = data?.tags || null;
    if (tags) {
      if (tags.title && tags?.title.length >= 65 && tags.title.length <= 70)
        successfulAuditsCount++;
      if (
        tags.description &&
        tags?.description.length > 155 &&
        tags?.description.length < 160
      )
        successfulAuditsCount++;
      if (tags.robots && tags?.robots.length) successfulAuditsCount++;
      if (tags.viewport && tags?.viewport.length) successfulAuditsCount++;
      if (tags.twitterCard && tags?.twitterCard.length) successfulAuditsCount++;
      if (tags.googleSiteVerification && tags?.googleSiteVerification.length)
        successfulAuditsCount++;
      if (data.h1 && data.h1.length) successfulAuditsCount++;
      if (data.h2 && data.h2.length) successfulAuditsCount++;
      if (data.h3 && data.h3.length) successfulAuditsCount++;
      if (data.h4 && data.h4.length) successfulAuditsCount++;
      if (data.h5 && data.h5.length) successfulAuditsCount++;
      if (data.h6 && data.h6.length) successfulAuditsCount++;
      if (data.alt && data.alt.length) successfulAuditsCount++;
      if (data.favicon_url && data.favicon_url.length) successfulAuditsCount++;
      if (
        data.words_per_page &&
        data.words_per_page.length >= 800  
        // data.words_per_page.length <= 1300
      )
        successfulAuditsCount++;
      if (data.host && data.host === "https") successfulAuditsCount++;
      if (data.response_time && data.response_time >= 200)
        successfulAuditsCount++;
      if (data.webStatus && parseInt(data.webStatus) === 200)
        successfulAuditsCount++;
      return successfulAuditsCount;
    }
  } catch (e) {
    return e.message || e;
  }
};

export const warningCount = (data) => {
  try {
    let warningAuditsCount = 0;
    const tags = data?.tags || null;
    if (tags) {
      if (tags.title && (tags?.title.length <= 65 || tags.title.length > 70))
        warningAuditsCount++;
      if (
        tags.description &&
        (tags?.description.length < 155 || tags?.description.length > 160)
      )
        warningAuditsCount++;
      // if (tags.robots && tags?.robots.length) warningAuditsCount++;
      // if (tags.viewport && tags?.viewport.length) warningAuditsCount++;
      // if (tags.twitterCard && tags?.twitterCard.length) warningAuditsCount++;
      // if (tags.googleSiteVerification && tags?.googleSiteVerification.length)
      //   warningAuditsCount++;
      if (data.h1 && data.h1.length > 1) warningAuditsCount++;
      if (data.h2 && data.h2.length > 5) warningAuditsCount++;
      if (data.h3 && data.h3.length > 4) warningAuditsCount++;
      if (data.h4 && data.h4.length > 3) warningAuditsCount++;
      if (data.h5 && data.h5.length > 3) warningAuditsCount++;
      if (data.h6 && data.h6.length > 5) warningAuditsCount++;
      if (data.alt && data.alt.length === 0) warningAuditsCount++;
      if (data.favicon_url && data.favicon_url.length === 0) warningAuditsCount++;
      if (
        data.words_per_page &&
        (data.words_per_page.length < 800)
      )
        warningAuditsCount++;
      // if (data.host && data.host === "https") warningAuditsCount++;
      return warningAuditsCount;
    }
  } catch (e) {
    return e.message || e;
  }
};

export const filterExternalLinks = (data) => {
  return data.url.filter(function (str) {
    return !str.includes(data.domain);
  });
};
export const filterInternalLinks = (data) => {
  return data.url.filter(function (str) {
    return str.includes(data.domain);
  });
};
