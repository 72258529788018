import React, { useEffect } from "react";

/**context */
import { useApp } from "contexts/app-context";

/**constants */
import { pages } from "constant/constant";
// components
import CommingSoon from "components/Shared/CommingSoon";

/**common */
import { composeTitle } from "utils/helper";

export default function BestPages() {
  const appContext = useApp();

  useEffect(() => {
    composeTitle(pages?.BestPages);
    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.BestPages,
      },
    });
  }, []);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <CommingSoon />
          </div>
        </div>
      </div>
    </>
  );
}
