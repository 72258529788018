import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

/** context import */
import { useApp } from "contexts/app-context";

/** constant import */
import { pages } from "constant/constant";

import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

import Button from "components/Shared/Button";
import CardPayment from "components/Cards/CardPayment";
import Modal from "components/Modals/Modal";
import PaypalSubscription from "components/Shared/PayPalSubscription";
/** service import */
import {
  stripeCheckoutWithCustomer,
  getPaymentMethods,
  storePayment,
  processPayPalPayments,
  createSubscription,
} from "services/payment";

/**Utils import */

import { toMonthName } from "utils/helper";
/**constant import */
import { store } from "constant/constant";

/** context import */
import { useAuth } from "contexts/auth-context";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

/**
 *
 * @returns
 */

const paypalScriptOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "USD",
};
const PayPalButton = ({ price, plan }) => {
  const auth = useAuth()?.state;
  const history = useHistory();

  const savePaymentDetails = async (details) => {
    let response = await processPayPalPayments(
      auth?.authToken,
      //process.env.NODE_ENV,
      details
    );
    if (response.status === true) {
      toast(response.message, {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      setTimeout(() => {
        localStorage.removeItem(store.CART);
        history.push("/app/subscription");
      }, 1000);
    } else {
      toast.error(response?.message);
    }
  };
  /**
   * usePayPalScriptReducer use within PayPalScriptProvider
   * isPending: not finished loading(default state)
   * isResolved: successfully loaded
   * isRejected: failed to load
   */
  const [{ isPending }] = usePayPalScriptReducer();
  const paypalbuttonTransactionProps = {
    style: { layout: "vertical" },
    createOrder(data, actions) {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: price,
            },
          },
        ],
      });
    },
    onApprove(data, actions) {
      console.log(data, actions);
      /**
       * data: {
       *   orderID: string;
       *   payerID: string;
       *   paymentID: string | null;
       *   billingToken: string | null;
       *   facilitatorAccesstoken: string;
       * }
       */
      return actions.order.capture({}).then((details) => {
        if (details?.status === "COMPLETED") {
          details.plan = plan;
          details.status = "succeeded";
          savePaymentDetails(details);
        } else {
          toast.error(
            "Something went wrong while processing. Please try again later."
          );
        }
      });
    },
    onError(error) {
      console.log(error.message);
      toast.error(error.message);
    },
  };
  return (
    <>
      {isPending ? <h2>Loading Smart Payment Button...</h2> : null}
      <PayPalButtons {...paypalbuttonTransactionProps} />
    </>
  );
};

const CheckoutForm = ({ plan }) => {
  const appContext = useApp();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [paymentMethod, setPaymentMethod] = React.useState(null);
  const [addPaymentMethod, setAddPaymentMethod] = React.useState();
  const [showModal, setShowModal] = React.useState(
    appContext?.state?.showModal || false
  );
  const auth = useAuth()?.state;
  const history = useHistory();
  const [product, setProduct] = React.useState(null);
  const [paymentVia, setPaymentVia] = React.useState("card");
  const [planTrail, setPlanTrail] = React.useState(false);
  const paypalClientID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  const initialOptions = {
    "client-id":
      "AfsiJQKOzIlexduyjWVeaALuQ-RYr_oZQMuIN8H3jPeFIvy0ezMaYyMnBWhjhBgAsE_Xa-xMKljfEGk-",
    currency: "USD",
    intent: "capture",
  };
  // const handleClick = (event) => {
  //   event.preventDefault();
  //   localStorage.removeItem(store.CART);
  //   localStorage.removeItem(store.FREE_TRAIL);
  //   history.push("/app/dashboard");
  // };

  const fetchPaymentMethods = async () => {
    let response = await getPaymentMethods(auth?.authToken);
    if (response.status === true) {
      setPaymentMethods(response.data);
      if (response.data.length == 1) {
        handleChange(response.data[0]);
      }
    } else {
      console.error(response.message);
    }
  };

  const handleCardPayments = async () => {
    try {
      setLoading(true);
      if (elements == null) {
        return;
      }
      let cardDetails = {};
      if (!paymentMethod) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${auth.firstName}  ${auth.lastName}`,
            email: auth.email,
          },
        });

        if (error) {
          toast.error(error?.message);
          setLoading(false);
          return;
        }
        cardDetails = {
          paymentMethod: paymentMethod?.id,
          brand: paymentMethod?.card?.brand,
          last4: paymentMethod?.card?.last4,
          expireMonth: paymentMethod?.card?.exp_month,
          expireYear: paymentMethod?.card?.exp_year,
        };
      } else {
        cardDetails = paymentMethod;
      }

      if (!cardDetails) {
        toast.error("Please add/choose payment method & try again");
        return;
      }
      let response = await stripeCheckoutWithCustomer(
        auth?.authToken,
        cardDetails,
        product
      );
      if (response?.status === false) {
        toast.error(response?.message);
      } else {
        // await stripe.confirmCardPayment
        // const { paymentIntent, error } = await stripe.confirmCardPayment(
        //   response.data.client_secret,
        //   {
        //     payment_method: cardDetails.paymentMethod,
        //   }
        // );
        // if (paymentIntent) {
        //   let response = await storePayment(
        //     auth?.authToken,
        //     paymentIntent,
        //     product?.title
        //   );
        //   if (response.status === true) {
        //     toast(response.message, {
        //       icon: "👏",
        //       style: {
        //         borderRadius: "10px",
        //         background: "#333",
        //         color: "#fff",
        //       },
        //     });
        //     setLoading(false);
        //     setTimeout(() => {
        //       localStorage.removeItem(store.CART);
        //       history.push("/app/subscription");
        //     }, 2000);
        //   } else {
        //     setLoading(false);
        //     toast.error(response?.message);
        //   }
        // }
        cardDetails.plan = product?.title;
        cardDetails.planId = product?.priceId;
        cardDetails.amount = product?.price;
        cardDetails.status = product?.status;
        let response = await createSubscription(auth?.authToken, cardDetails);
        if (response.status === true) {
          toast(response.message, {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setLoading(false);
          setTimeout(() => {
            localStorage.removeItem(store.CART);
            localStorage.removeItem(store.FREE_TRAIL);
            if (localStorage.getItem("previousPage")) {
              history.push(localStorage.getItem("previousPage"));
              localStorage.removeItem("previousPage");
            } else {
              history.push("/app/subscription");
            }
          }, 2000);
        } else {
          setLoading(false);
          toast.error(response?.message);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.message.includes("Invalid value for createPaymentMethod")) {
        toast.error("Please Choose Payment Card");
      } else {
        toast.error(error.message);
      }
    }
  };

  const handlePaypalPayments = async (payload) => {
    try {
      let response = await processPayPalPayments(
        auth?.authToken,
        //process.env.NODE_ENV,
        payload,
        product?.title
      );
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const handleCheckoutClick = async (event) => {
    event.preventDefault();
    if (paymentVia === "card") {
      handleCardPayments();
    } else if (paymentVia === "paypal") {
      handlePaypalPayments();
    } else {
      toast.error("Something went wrong");
    }
  };

  const applyCouponCode = async () => {};

  const handleChange = async (method) => {
    setPaymentMethod(method);
  };

  const handlePaymentMethodChange = async (event, method) => {
    event.preventDefault();
    setPaymentVia(method);
  };

  React.useEffect(() => {
    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.checkout,
      },
    });
    if (
      localStorage.getItem(store.CART) ||
      localStorage.getItem(store.FREE_TRAIL)
    ) {
      setProduct(
        JSON.parse(
          localStorage.getItem(store.CART) ||
            localStorage.getItem(store.FREE_TRAIL)
        )
      );
    } else {
      history.push("/app/dashboard");
    }
    fetchPaymentMethods();
  }, []);

  useEffect(() => {
    if (localStorage.getItem(store.FREE_TRAIL)) {
      setPlanTrail(true);
    }
  }, []);

  const hideModal = () => {
    setShowModal(!showModal);
    appContext.dispatch({
      type: appContext.actions.UPDATE,
      data: {
        showModal: !showModal,
      },
    });
  };

  useEffect(() => {
    setShowModal(appContext.state.showModal);
  }, [appContext.state.showModal]);

  return (
    <>
      <Modal showModal={showModal} hideModal={hideModal} />
      <div className="w-full">
        <section className="bg-blueGray-100 py-12 z-2">
          <div className="container mx-auto px-4 ">
            <div className="flex flex-wrap -mx-4">
              <div className="px-4 relative w-full lg:w-4/12">
                <h3 className="text-4xl font-normal leading-normal mt-0 mb-2 text-white">
                  Order summary
                </h3>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="items-center my-4 flex items-start">
                      <div className="flex-1">
                        <div className="justify-between">
                          <h6 className="text-xl font-semibold leading-tight mb-1">
                            {product?.title}
                          </h6>
                          <p className="text-blueGray-500 uppercase font-bold text-xs">
                            per month
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between flex-col">
                        <span
                          className={"text-blueGray-700 "}
                          style={
                            planTrail ? { textDecoration: "line-through" } : {}
                          }
                        >
                          ${product?.price}
                        </span>
                        <span>{planTrail ? "$0" : ""}</span>
                      </div>
                    </div>
                    {/* <hr className="mt-6 mb-4 bg-blueGray-300 ml-0" />
                   <div className="mb-0 pt-0 relative">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Discount
                    </label>
                    <div className="mb-2 relative flex flex-wrap w-full items-stretch">
                      <div className="mr-2">
                        <div className="mb-3 pt-0">
                          <input
                            placeholder="Discount code"
                            type="text"
                            className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                          />
                        </div>
                      </div>
                      <div className="mb-0">
                        <Button
                          onClick={applyCouponCode}
                          loading={false}
                          text="Apply"
                          classes="inline-block rounded outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out  border border-solid font-bold last:mr-0 mr-2  text-white active:bg-blueGray-50 bg-blueGray-700 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md"
                        />
                      </div>
                    </div>
                  </div> */}
                    <div>
                      <hr className="mt-4 mb-2 bg-blueGray-300 ml-0" />
                      <div className="flex justify-between">
                        <h6 className="text-blueGray-700 leading-normal mt-0 mb-2">
                          Subtotal
                        </h6>
                        <h6 className="leading-normal mt-0 mb-2">
                          {planTrail ? "$0" : "$" + product?.price}
                        </h6>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-blueGray-700 leading-normal mt-0 mb-2">
                          Discount
                        </h6>
                        <h6 className="leading-normal mt-0 mb-2">$0.0</h6>
                      </div>
                    </div>
                    <div>
                      <hr className="mt-4 mb-2 bg-blueGray-300 ml-0" />
                      <div className="flex justify-between">
                        <h6 className="text-blueGray-700 leading-normal mt-0 mb-2">
                          Total
                        </h6>
                        <h6 className="leading-normal mt-0 mb-2">
                          {planTrail ? "$0" : "$" + product?.price}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 relative w-full lg:w-8/12">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <form>
                      <div className="container mx-auto px-4">
                        <h3 className="text-3xl font-semibold mt-4 mb-6">
                          Billing address
                        </h3>
                        <div className="flex flex-wrap -mx-4">
                          <div className="px-4 pb-2 relative w-full lg:w-6/12">
                            <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                              First name*
                            </label>
                            <div className="mb-3 pt-0">
                              <input
                                placeholder="E.g. Smith"
                                value={auth?.firstName}
                                readOnly
                                type="text"
                                className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                              />
                            </div>
                          </div>
                          <div className="px-4 pb-2 relative w-full lg:w-6/12">
                            <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                              Last name*
                            </label>
                            <div className="mb-3 pt-0">
                              <input
                                placeholder="E.g. John"
                                readOnly
                                value={auth?.lastName}
                                type="text"
                                className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                              />
                            </div>
                          </div>
                          <div className="px-4 pb-2 relative w-full lg:w-6/12">
                            <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                              Email address*
                            </label>
                            <div className="mb-3 pt-0">
                              <input
                                placeholder="E.g. email@email.email"
                                type="email"
                                readOnly
                                value={auth?.email}
                                className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                              />
                            </div>
                          </div>
                          <div className="px-4 pb-2 relative w-full lg:w-6/12">
                            <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                              Phone
                            </label>
                            <div className="mb-3 pt-0">
                              <input
                                placeholder="E.g. +1 (5417) 543 010"
                                type="text"
                                className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                value={auth?.phone}
                              />
                            </div>
                          </div>
                        </div>

                        <h3 className="text-3xl font-semibold mt-4 mb-6">
                          Payment method
                        </h3>
                        <div className="flex items-center justify-center w-screen mb-2">
                          <div className="grid grid-cols-3 gap-2 w-full max-w-screen-sm">
                            {paymentMethods &&
                              paymentMethods.map((method) => (
                                <CardPayment
                                  key={method?.paymentMethod}
                                  last4={method?.last4}
                                  expiry={`${toMonthName(
                                    method?.expireMonth
                                  )}, ${method.expireYear}`}
                                  brand={method?.brand}
                                  onChange={() => handleChange(method)}
                                  active={
                                    paymentMethod?.paymentMethod ===
                                    method?.paymentMethod
                                  }
                                  paymentMethod={method?.paymentMethod}
                                />
                              ))}
                          </div>
                        </div>

                        <Button
                          onClick={() => {
                            setPaymentMethod(null);
                            setAddPaymentMethod(!addPaymentMethod);
                          }}
                          loading={false}
                          text={!addPaymentMethod ? "Add new" : "Cancel"}
                          classes="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out  border border-solid font-bold last:mr-0 mr-2  text-white active:bg-blueGray-50 bg-blueGray-700 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md mb-2"
                        />

                        {addPaymentMethod && (
                          <>
                            <ul className="flex-col md:flex-row flex flex-wrap list-none pl-0 mb-0">
                              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                                <a
                                  href="#"
                                  onClick={(event) =>
                                    handlePaymentMethodChange(event, "card")
                                  }
                                  className={`text-xs font-bold  px-5 py-3 shadow rounded block leading-normal sm:mb-4 md:mb-0 uppercase duration-500 transition-all ease-in-out ${
                                    paymentVia === "card"
                                      ? "bg-lightBlue-600 text-white"
                                      : "text-pink-500 bg-white hover:text-blueGray-500"
                                  } `}
                                >
                                  Credit Card
                                </a>
                              </li>
                              {/* <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                                <a
                                  onClick={(event) =>
                                    handlePaymentMethodChange(event, "paypal")
                                  }
                                  href="#"
                                  className={`text-xs font-bold uppercase px-5 py-3 shadow rounded block leading-normal sm:mb-4 md:mb-0 uppercase duration-500 transition-all ease-in-out text-pink-500 ${
                                    paymentVia === "paypal"
                                      ? "bg-lightBlue-600 text-white"
                                      : "text-pink-500 bg-white hover:text-blueGray-500"
                                  } `}
                                >
                                  PayPal
                                </a>
                              </li> */}
                            </ul>
                            {paymentVia && paymentVia === "card" && (
                              <div className="w-full">
                                <div className="my-6 transform duration-500 transition-all ease-in-out block">
                                  <div className="flex flex-wrap -mx-4">
                                    <div className="px-4 relative w-full lg:w-12/12">
                                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                        Card Number
                                      </label>
                                      <div className="mb-3 border border-solid AppWrapper border-blueGray-300 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full">
                                        <CardElement />
                                      </div>
                                    </div>
                                    {/* <div className="px-4 relative w-full lg:w-7/12">
                                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                                      Card holder
                                    </label>
                                    <div className="mb-3 pt-0">
                                      <input
                                        placeholder="E.g. SMITH JOHN"
                                        type="text"
                                        className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
                                      />
                                    </div>
                                  </div> */}
                                  </div>
                                </div>
                              </div>
                            )}
                            {paymentVia && paymentVia === "paypal" && (
                              <div className="w-full">
                                <div className="my-6 transform duration-500 transition-all ease-in-out block">
                                  <div className="flex flex-wrap -mx-4">
                                    <div className="px-4 relative w-full lg:w-12/12">
                                      <PaypalSubscription
                                        planId={product?.paypalPlanId}
                                        product={product}
                                        plan={plan}
                                      />
                                      {/* <PayPalScriptProvider
                                      options={paypalScriptOptions}
                                    >
                                      <PayPalButton
                                        price={product.price}
                                        plan={product.title}
                                      />
                                    </PayPalScriptProvider> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        <div className="flex justify-between mt-12 mb-8">
                          {/* <Button
                            onClick={handleClick}
                            loading={false}
                            text="Clear Cart"
                            classes="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out  border border-solid font-bold last:mr-0 mr-2  text-blueGray-800 bg-blueGray-200 border-blueGray-200 active:bg-blueGray-300 active:border-blueGray-300 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md"
                          /> */}
                          <div></div>
                          <Button
                            onClick={handleCheckoutClick}
                            loading={loading}
                            text="Order Now"
                            classes="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out  border border-solid font-bold last:mr-0 mr-2  text-white active:bg-blueGray-50 bg-blueGray-700 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CheckoutForm;
