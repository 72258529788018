import React, { useState } from "react";

// Pre-import all images
import backlink from '../assets/images/round-icons/back-link.png';
import technical from '../assets/images/round-icons/technical.png';
import semantic from '../assets/images/round-icons/semantic.png';
import content from '../assets/images/round-icons/content.png';
import ux from '../assets/images/round-icons/ux.png';
import serp from '../assets/images/round-icons/serp.png';
import monitor from '../assets/images/round-icons/monitor.png';

import backlinkIcon from '../assets/images/icons/backlink.png';
import technicalIcon from '../assets/images/icons/technical.png';
import semanticIcon from '../assets/images/icons/semantic.png';
import contentIcon from '../assets/images/icons/content.png';
import uxIcon from '../assets/images/icons/ux.png';
import serpIcon from '../assets/images/icons/serp.png';
import strategyIcon from '../assets/images/icons/strategy.png';

// Create a mapping object for round icons
const roundIcons = {
  'back-link.png': backlink,
  'technical.png': technical,
  'semantic.png': semantic,
  'content.png': content,
  'ux.png': ux,
  'serp.png': serp,
  'monitor.png': monitor,
};

// Create a mapping object for other icons
const iconImages = {
  'backlink.png': backlinkIcon,
  'technical.png': technicalIcon,
  'semantic.png': semanticIcon,
  'content.png': contentIcon,
  'ux.png': uxIcon,
  'serp.png': serpIcon,
  'strategy.png': strategyIcon,
};

const CircleImages = () => {
  const [activeImage, setActiveImage] = useState(0);

  const images = [
    { src: 'back-link.png', alt: 'Image 1', title: <> Free Speed <span className="block">Test</span></>, description: 'Description 1', titleStyle: { left: '-170', top: '-160' }, },
    { src: 'technical.png', alt: 'Image 2', title: <> SEO &  <span className="block">Fast Websites</span></>, description: 'Description 2', titleStyle: { left: '-100', top: '-190' }, },
    { src: 'semantic.png', alt: 'Image 3', title: <> Speed Insights <span className="block">Instantly</span></>, description: 'Description 3', titleStyle: { left: '-210', top: '-160' }, },
    { src: 'content.png', alt: 'Image 4', title: <>Core Web<span className="block">Vitals Check</span></>, description: 'Description 4', titleStyle: { left: '-260', top: '-130' }, },
    { src: 'ux.png', alt: 'Image 5', title: <>Discover Slowdown <span className="block">Causes</span><span className="block"></span></>, description: 'Description 5', titleStyle: { left: '-210', top: '-170' }, },
    { src: 'serp.png', alt: 'Image 6', title: <>Share Speed <span className="block">Results</span><span className="block"></span></>, description: 'Description 6', titleStyle: { left: '-160', top: '-200' }, },
    { src: 'monitor.png', alt: 'Image 7', title: <>Mobile & SEO<span className="block">Scores</span></>, description: 'Description 7', titleStyle: { left: '-240', top: '-160' }, },
  ];

  const content = [
    {
      src: 'backlink.png', alt: 'Free Speed Test', title: 'Free Speed Test', content: <>Try our Free Website Speed Test for an immediate evaluation of your site's performance. This no-cost tool offers a thorough analysis, aiding in efficient optimization.</>
    },
    {
      src: 'technical.png', alt: 'SEO & Fast Websites', title: 'SEO & Fast Websites', content: <>Understand how a faster website boosts SEO. Our tool demonstrates the direct impact of website speed on SEO, providing a clear pathway for optimization and higher search engine visibility.</>
    },
    {
      src: 'semantic.png', alt: 'Speed Insights Instantly', title: 'Speed Insights Instantly', content: <>Get immediate insights with our Website Speed Checker. This tool swiftly analyzes your website, providing a clear understanding of your site's Speed and performance, which is essential for effective Website Speed Optimization.</>
    },
    {
      src: 'content.png', alt: 'Core Web Vitals Check', title: 'Core Web Vitals Check', content: <>Our Website Speed Checker thoroughly evaluates your site's Core Web Vitals. This essential check ensures your website meets key performance indicators(KPIs), which are crucial for both user experience and SEO rankings.</>
    },
    {
      src: 'ux.png', alt: 'Discover Slowdown Causes', title: 'Discover Slowdown Causes', content: <>Identify what's slowing your site with comprehensive analysis. Free Website Speed Test by WebWatch pinpoints specific issues, offering actionable tips for improvement. This insight is critical for Website Speed Optimization and better user engagement.</>
    },
    {
      src: 'serp.png', alt: 'Share Speed Results', title: 'Share Speed Results', content: <>Easily share your website speed test results from our Free Website Speed Test. This feature allows for collaborative optimization efforts and transparent performance reporting, which is essential for team-based website improvement strategies.</>
    },
    {
      src: 'strategy.png', alt: 'Mobile & SEO Scores', title: 'Mobile & SEO Scores', content: <>Optimize for mobile with our tool's Mobile Optimization insights. Get a detailed SEO score reflecting your website's mobile-friendliness and overall performance, which is essential for ranking higher in mobile searches and attracting a wider audience.</>
    },
  ];

  const calculateImageAngle = (index) => {
    return (360 / images.length) * (index + 0.5); // Add 0.5 to center the title below the image
  };

  return (
    <div className="flex items-center">
      <div className="lg:grid lg:grid-cols-5 w-full">
        <div className="col-span-3 p-20">
          <div className="mx-auto items-center w-fit">
            <div className="relative w-96 h-96 p-7 bg-gradient-to-br from-theme-primary to-theme-secondary rounded-full">
              <div className="w-full h-full bg-white rounded-full p-14">
                <div className="mx-auto items-center w-full h-full p-7 bg-gradient-to-tl from-theme-primary to-theme-secondary rounded-full">
                  <div className="w-full h-full bg-white rounded-full"></div>
                </div>
              </div>
            </div>
            <div className="w-96 h-96 absolute" style={{ transform: `translate(80px, -304px)` }}>
              {images.map((image, index) => (
                <div
                  key={image.src}
                  className={`absolute mx-auto w-48 h-48 pt-1 pl-1 cursor-pointer mt-4 ml-4`}
                  style={{
                    transform: `rotate(${calculateImageAngle(index)}deg) translate(-50%, -50%)`,
                  }}
                  onMouseEnter={() => setActiveImage(index)}
                >
                  <img
                    src={roundIcons[image.src]} // Dynamically set the src using the roundIcons map
                    alt={image.alt}
                    className={`object-cover`}
                    style={{
                      transform: `rotate(-${calculateImageAngle(index)}deg)`,
                    }}
                  />
                  <h2
                    className="text-center text-xl font-medium line-clamp-3 text-theme-secondary"
                    style={{
                      transform: `rotate(-${calculateImageAngle(index)}deg)`,
                      marginLeft: `${image.titleStyle.left}px`,
                      marginTop: `${image.titleStyle.top}px`,
                    }}
                  >
                    {image.title}
                  </h2>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-2 p-8">
          <div className="border-2 border-theme-primary p-8 text-center">
            <img
              src={iconImages[content[activeImage].src]} // Dynamically set the src using the iconImages map
              alt={content[activeImage].alt}
              className={`object-cover mx-auto`}
            />
            <h3 className="text-center text-xl font-medium line-clamp-3 text-theme-secondary my-4">
              {content[activeImage].title}
            </h3>
            <p>{content[activeImage].content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircleImages;
