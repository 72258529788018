export const updateProfile = async (token, formData) => {
    try {
      const response = await fetch("/account/updateProfile", {
        method: "POST",
        headers: {
          authorization: token,
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  };
  
  export const getProfile = async (token) => {
    try {
      const response = await fetch("/account/getProfile", {
        headers: {
          authorization: token,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  };
  
  export const updatePassword = async (token, formData) => {
      try {
        const response = await fetch("/account/updatePassword", {
          method: "POST",
          headers: {
            authorization: token
          },
          body: formData,
        })
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
      } catch (error) {
        return error;
      }
    };
  