export async function fetchCountries(token) {
  try {
    const response = await fetch("https://restcountries.com/v2/all", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}
