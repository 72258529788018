export async function getActiveSubscriptions(token) {
  try {
    const response = await fetch("/account/fetchSubscriptions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}
export async function getActivePlan(token) {
  try {
    const response = await fetch("/account/fetchUserPlanDetails", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function getOrderHistory(token) {
  try {
    const response = await fetch("/account/fetchOrderHistory", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function getSubscriptionTimeperiods(token) {
  try {
    const response = await fetch("/account/fetchSubscriptionTimeperiods", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function cancelSubscription(token) {
  try {
    const response = await fetch("/payment/update_cancel_status", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function getCancelSubscription(token) {
  try {
    const response = await fetch("/account/fetchCancelSubscriptions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}
