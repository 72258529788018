export async function fetchInvoice(token, invoiceId) {
  try {
    const response = await fetch("/payment/invoice/" + invoiceId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}
