import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

/** context import */
import { useApp } from "contexts/app-context";
import { useAuth } from "contexts/auth-context";
/**services import */
import { getPaymentMethods } from "services/payment";
import {
  getActiveSubscriptions,
  getOrderHistory,
  getSubscriptionTimeperiods,
  getCancelSubscription,
} from "services/subscription";
// components
import { composeTitle } from "utils/helper";
import { toMonthName, formatDate } from "utils/helper";
import PriceCard from "components/Cards/PriceCard";
import { getActivePlan } from "services/subscription";
import Modal from "components/Modals/Modal";

//constants
import { pages, store } from "constant/constant";
import { products } from "constant/products";
import { cancelSubscription } from "services/subscription";

function returnNoResultFound(message = "") {
  return (
    <tr className="w-full">
      <div className="text-center">
        <div className="px-5 py-3 mx-4 border-b border-black border-opacity-10">
          {message}
          <div className="h-12 w-12 mx-auto mt-3">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="face-thinking"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="svg-inline--fa fa-face-thinking fa-4x"
            >
              <g className="fa-duotone-group">
                <path
                  fill="currentColor"
                  d="M224.6 510.1C228.6 504.4 231.9 498.1 234.4 491.3L255.4 433.6L291.1 420.6C315 411.9 329.4 388.6 327.9 364.5C326.6 340.1 311.4 318.4 288.7 308.8L166.2 257.3C158.1 253.8 148.7 257.6 145.3 265.8C141.8 273.9 145.6 283.3 153.8 286.7L236.2 321.4L144 354.1V352C144 321.1 118.9 296 88 296C57.07 296 32 321.1 32 352V380C11.61 343.3 0 301 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C245.4 512 234.9 511.4 224.6 510.1L224.6 510.1zM176.4 207.1C194 207.1 208.4 193.7 208.4 175.1C208.4 158.3 194 143.1 176.4 143.1C158.7 143.1 144.4 158.3 144.4 175.1C144.4 193.7 158.7 207.1 176.4 207.1zM336.4 159.1C318.7 159.1 304.4 174.3 304.4 191.1C304.4 209.7 318.7 223.1 336.4 223.1C354 223.1 368.4 209.7 368.4 191.1C368.4 174.3 354 159.1 336.4 159.1zM229.6 140.1C236.3 145.9 246.4 145.1 252.1 138.4C257.9 131.7 257.1 121.6 250.4 115.9L237.2 104.5C206.4 78.14 162.3 73.95 127.1 94.08L120.1 98.11C112.4 102.5 109.7 112.3 114.1 119.9C118.5 127.6 128.3 130.3 135.9 125.9L142.1 121.9C166.5 108.4 195.9 111.2 216.4 128.8L229.6 140.1z"
                  className="fa-secondary"
                ></path>
                <path
                  fill="currentColor"
                  d="M135.9 125.9C128.3 130.3 118.5 127.6 114.1 119.9C109.7 112.3 112.4 102.5 120.1 98.11L127.1 94.08C162.3 73.95 206.4 78.14 237.2 104.5L250.4 115.9C257.1 121.6 257.9 131.7 252.1 138.4C246.4 145.1 236.3 145.9 229.6 140.1L216.4 128.8C195.9 111.2 166.5 108.4 142.1 121.9L135.9 125.9zM144.4 176C144.4 158.3 158.7 144 176.4 144C194 144 208.4 158.3 208.4 176C208.4 193.7 194 208 176.4 208C158.7 208 144.4 193.7 144.4 176zM112 400.6L263.8 345.4C276.3 340.9 290 347.3 294.6 359.8C299.1 372.3 292.7 386 280.2 390.6L230.4 408.7L204.3 480.4C197.4 499.4 179.4 512 159.2 512H112C85.49 512 64 490.5 64 464V352C64 338.7 74.75 328 88 328C101.3 328 112 338.7 112 352L112 400.6zM368.4 192C368.4 209.7 354 224 336.4 224C318.7 224 304.4 209.7 304.4 192C304.4 174.3 318.7 160 336.4 160C354 160 368.4 174.3 368.4 192z"
                  className="fa-primary"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </tr>
  );
}

export default function Subscription() {
  const history = useHistory();
  const [openTab, setOpenTab] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [cancelSubscriptions, setCancelSubscriptions] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const appContext = useApp();
  const auth = useAuth();
  const [showModal, setShowModal] = useState(
    appContext?.state?.showModal || false
  );
  const [planTrail, setPlanTrail] = useState(false);

  // const addMonth = (date) => {
  //   const newDate = new Date(date);

  //   // Add one month to the new Date object
  //   newDate.setMonth(newDate.getMonth() + 1);

  //   // Return the new date
  //   return newDate;
  // };

  const fetchPaymentMethods = async () => {
    let response = await getPaymentMethods(auth?.state?.authToken);
    if (response.status === true) {
      setPaymentMethods(response.data);
    } else {
      console.warn(response.message);
    }
  };

  const fetchSubscriptions = async () => {
    let response = await getActiveSubscriptions(auth?.state?.authToken);
    if (response.status === true) {
      setSubscriptions(response.data);
    } else if (response.status === false) {
      let res = await fetchCancelSubscriptions();
      if (res.status === true) {
        setCancelSubscriptions(res.data);
      }
    } else {
      console.warn(response.message);
    }
  };

  const fetchCancelSubscriptions = async () => {
    let response = await getCancelSubscription(auth?.state?.authToken);
    return response;
  };

  const fetchOrderHistory = async () => {
    let response = await getOrderHistory(auth?.state?.authToken);
    if (response.status === true) {
      setOrderHistory(response.data);
      setPlanTrail(false);
    } else {
      console.warn(response.message);
      setPlanTrail(true);
    }
  };
  const fetchSubscriptionTime = async () => {
    setLoading(true);
    let response = await getSubscriptionTimeperiods(auth?.state?.authToken);
    if (response.trail === "true") {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getUserActivePlan = async () => {
    setLoading(true);
    let response = await getActivePlan(auth?.state?.authToken);
    if (response.status === true) {
      setPlan(response.data);
      setLoading(false);
    } else {
      toast.error(response.message);
      setLoading(false);
    }
  };

  const hideModal = () => {
    setShowModal(!showModal);
    appContext.dispatch({
      type: appContext.actions.UPDATE,
      data: {
        showModal: !showModal,
      },
    });
  };

  const handleCFreeTrailClick = async (product) => {
    product.coupon = null;
    product.status = "free_trail";
    localStorage.setItem(store.FREE_TRAIL, JSON.stringify(product));
    localStorage.removeItem(store.CART);
    history.push("/app/checkout");
  };

  const handleCancelSubscription = async () => {
    let response = await cancelSubscription(auth?.state?.authToken);
    if (response.status) {
      toast.success(response.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const addTab = () => {
    localStorage.setItem("openTab", 3);
  };

  useEffect(() => {
    setShowModal(appContext.state.showModal);
  }, [appContext.state.showModal]);

  useEffect(() => {
    if (localStorage.getItem("openTab")) {
      setOpenTab(parseInt(localStorage.getItem("openTab")));
      localStorage.removeItem("openTab");
    }
  }, []);

  useEffect(() => {
    composeTitle(pages?.subscription);

    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.subscription,
      },
    });

    if (auth?.state?.guestUser) {
      auth.dispatch({
        type: auth.actions.SET,
        data: {
          guestUser: false,
        },
      });
      localStorage.setItem("previousPage", "/app/page-speed");
      localStorage.removeItem("persist");
      history.push("/auth/register");
    }

    if (!auth?.state?.guestUser) {
      fetchOrderHistory();
      fetchSubscriptionTime();
      fetchSubscriptions();
      fetchPaymentMethods();
      getUserActivePlan();
    }

    composeTitle(pages?.pricing);

    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.pricing,
      },
    });
  }, []);

  const handleClick = async (product) => {
    if (plan?.title !== "Free" && product.title === "Free") {
      toast.error("You're not allowed to perform this action.");
      return;
    }
    product.coupon = null;
    product.status = "subscribe";
    localStorage.setItem(store.CART, JSON.stringify(product));
    localStorage.removeItem(store.FREE_TRAIL);
    localStorage.setItem("plan", JSON.stringify(plan));
    history.push("/app/checkout");
  };

  const downloadReceipt = () => {};
  return (
    <>
      <Modal showModal={showModal} hideModal={hideModal} />
      <div className="w-full h-screen overflow-x-hidden">
        <div className="bg-white relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
          <div className="w-full mx-auto px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="px-4 relative w-full md:w-12/12">
                <div className="relative py-12">
                  <section className="text-center block">
                    <div className="text-center flex justify-between items-center">
                      <h3 className="text-3xl font-semibold mt-2 mb-4">
                        Plans & Subscription
                      </h3>
                    </div>
                  </section>
                  <section className="block">
                    <ul
                      className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                      role="tablist"
                    >
                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                            (openTab === 0
                              ? "text-white bg-lightBlue-600"
                              : "text-lightBlue-600 bg-white")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(0);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          <i className="fas fa-dollar-sign text-base mr-1"></i>{" "}
                          Pricing
                        </a>
                      </li>
                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                            (openTab === 1
                              ? "text-white bg-lightBlue-600"
                              : "text-lightBlue-600 bg-white")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(1);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          <i className="fas fa-user text-base mr-1"></i>{" "}
                          Subscription
                        </a>
                      </li>
                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                            (openTab === 2
                              ? "text-white bg-lightBlue-600"
                              : "text-lightBlue-600 bg-white")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          <i className="fas fa-credit-card text-base mr-1"></i>{" "}
                          Payment Methods
                        </a>
                      </li>
                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                            (openTab === 3
                              ? "text-white bg-lightBlue-600"
                              : "text-lightBlue-600 bg-white")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(3);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          <i className="fas fa-space-shuttle text-base mr-1"></i>{" "}
                          Order History
                        </a>
                      </li>
                    </ul>
                  </section>
                  <div className="mt-4"></div>
                </div>
              </div>
              <div className="ml-auto px-4 relative w-full md:w-12/12 justify-center">
                {openTab === 0 && !loading && (
                  <div className="flex flex-wrap justify-center">
                    {products &&
                      products.map((product, index) => (
                        <div className="w-full lg:w-4/12 px-4" key={index}>
                          <PriceCard
                            title={product?.title}
                            planId={product?.planId}
                            price={product?.price}
                            type={product?.duration}
                            currency={product.currency}
                            description={product?.description}
                            actionTitle={product.actionTitle}
                            other={product.others}
                            action={(e) => handleClick(product)}
                            activePlan={plan}
                            planTrail={planTrail}
                            loading={loading}
                            cancelPlan={cancelSubscription}
                            actionFreeTrail={(e) =>
                              handleCFreeTrailClick(product)
                            }
                          />
                        </div>
                      ))}
                  </div>
                )}
                {openTab === 1 && (
                  <div className="relative flex flex-col min-w-0 break-wordsw-full mb-6 overflow-x-scroll lg:overflow-x-auto">
                    <div className="py-5 flex-auto">
                      <div className="relative">
                        <div className="my-8 duration-500 transition-all ease-in-out block">
                          <div className="mb-6">
                            <h2 className="text-2xl font-semibold mt-0 mb-2">
                              Active subscription
                            </h2>
                            <hr className="w-full mt-2 mb-8 h-0 border-blueGray-400" />
                            <table className="w-full mb-4 text-blueGray-800 border-collapse items-center">
                              {subscriptions.length > 0 && (
                                <thead className="items-center">
                                  <tr className="text-left">
                                    <th className="align-middle uppercase font-bold text-sm p-3 border-t text-blueGray-500">
                                      <small>Date</small>
                                    </th>
                                    <th className="align-middle d  font-bold text-sm p-3 border-t text-blueGray-500">
                                      <small>Package</small>
                                    </th>
                                    <th className="align-middle  font-bold text-sm p-3 border-t text-blueGray-500">
                                      <small>Amount</small>
                                    </th>
                                    <th className="align-middle  font-bold text-sm p-3 border-t text-blueGray-500">
                                      <small>Status</small>
                                    </th>
                                    {/* <th className="align-middle  font-bold text-sm p-3 border-t text-blueGray-500">
                                      <small>Renewal Date</small>
                                    </th> */}
                                    <th className="align-middle  font-bold text-sm p-3 border-t text-blueGray-500">
                                      <small>Action</small>
                                    </th>
                                  </tr>
                                </thead>
                              )}
                              <tbody className="">
                                {subscriptions &&
                                  subscriptions.map((subscription, index) => (
                                    <tr key={index}>
                                      <td className="align-middle  font-bold text-sm p-3 border-t text-blueGray-500">
                                        {formatDate(
                                          subscription.subscriptionDate
                                        )}
                                      </td>
                                      <td className="align-middle uppercase font-normal p-3 border-t">
                                        SEO {subscription.product}
                                      </td>
                                      <td className="align-middle  font-normal p-3 border-t">
                                        <label className="inline-flex items-center cursor-pointer">
                                          ${subscription.payment}
                                        </label>
                                      </td>
                                      <td className="align-middle  font-normal p-3 border-t">
                                        <label className="inline-flex items-center cursor-pointer">
                                          Active
                                        </label>
                                      </td>
                                      {/* <td className="align-middle  font-normal p-3 border-t">
                                        <label className="inline-flex items-center cursor-pointer">
                                          {formatDate(
                                            addMonth(
                                              subscription.subscriptionDate
                                            )
                                          )}
                                        </label>
                                      </td> */}
                                      <td className="align-middle  font-normal  border-t text-center">
                                        <button
                                          className="text-xs font-bold py-2 shadow-lg rounded block leading-normal text-white bg-red-600 px-4 cursor-pointer"
                                          onClick={() => {
                                            handleCancelSubscription();
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </td>
                                    </tr>
                                  ))}

                                {subscriptions.length < 1 &&
                                  returnNoResultFound(
                                    "No active subscription available"
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {openTab === 2 && (
                  <div className="relative overflow-x-scroll lg:overflow-x-auto">
                    <div className="my-8 duration-500 transition-all ease-in-out block">
                      <div className="mb-6">
                        <header className="block relative">
                          <h2 className="text-2xl font-semibold mt-0 mb-2">
                            Billing Method
                          </h2>
                        </header>
                        <hr className="w-full mt-2 mb-8 h-0 border-blueGray-400" />
                        <table className="w-full mb-4 text-blueGray-800 border-collapse items-center">
                          {paymentMethods && paymentMethods.length > 0 && (
                            <thead className="items-center">
                              <tr className="text-left">
                                <th className="align-middle uppercase font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Card Brand</small>
                                </th>
                                <th className="align-middle uppercase font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Card Number</small>
                                </th>
                                <th className="align-middle uppercase font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Expiry</small>
                                </th>
                              </tr>
                            </thead>
                          )}
                          <tbody className="">
                            {paymentMethods &&
                              paymentMethods.map((method, index) => (
                                <tr key={index}>
                                  <td className="align-middle uppercase font-bold text-sm p-3 border-t text-blueGray-500">
                                    <img
                                      alt="..."
                                      className="bg-white inline-flex justify-center items-center w-12"
                                      src={
                                        require(`assets/img/cards/${method?.brand}.png`)
                                          .default
                                      }
                                    />
                                  </td>
                                  <td className="align-middle uppercase font-normal p-3 border-t">
                                    •••• •••• •••• {method.last4}
                                  </td>
                                  <td className="align-middle font-normal p-3 border-t">
                                    {toMonthName(method?.expireMonth)},{" "}
                                    {method.expireYear}
                                  </td>
                                </tr>
                              ))}
                            {paymentMethods.length < 1 &&
                              returnNoResultFound(
                                "No payment method available"
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {openTab === 3 && (
                  <div className="relative overflow-x-scroll lg:overflow-x-auto">
                    <div className="my-8 duration-500 transition-all ease-in-out block ">
                      <div className="mb-6">
                        <header className="block relative">
                          <h2 className="text-2xl font-semibold mt-0 mb-2">
                            Recent Invoices
                          </h2>
                        </header>
                        <hr className="w-full mt-2 mb-8 h-0 border-blueGray-400" />
                        <table className="w-full mb-4 text-blueGray-800 border-collapse items-center">
                          {orderHistory && orderHistory.length > 0 && (
                            <thead className="items-center">
                              <tr className="text-left">
                                <th className="align-middle uppercase font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Date</small>
                                </th>
                                <th className="align-middle uppercase font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Service Title</small>
                                </th>
                                <th className="align-middle uppercase font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Package Price</small>
                                </th>
                                <th className="align-middle uppercase font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Amount</small>
                                </th>
                                <th className="align-middle uppercase font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>ACTION</small>
                                </th>
                              </tr>
                            </thead>
                          )}
                          <tbody className="">
                            {orderHistory &&
                              orderHistory.map((history, index) => (
                                <tr key={history?.billingId}>
                                  <td className="align-middle  font-bold text-sm p-3 border-t text-blueGray-500">
                                    {formatDate(history.subscriptionDate)}
                                  </td>
                                  <td className="align-middle font-normal p-3 border-t">
                                    {history.product} SEO Plan
                                  </td>
                                  <td className="align-middle font-normal p-3 border-t">
                                    ${history?.package_price}
                                  </td>
                                  <td className="align-middle font-normal p-3 border-t">
                                    ${history?.payment}
                                  </td>
                                  <td className="align-middle  font-normal p-3 border-t">
                                    {/* <Link
                                      to={`/app/invoice/${history.billingId}`}
                                      onClick={addTab}
                                      className="iinline-block rounded outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out  border border-solid font-bold last:mr-0 mr-2  text-white active:bg-blueGray-50 bg-blueGray-700 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md"
                                    >
                                      Preview
                                    </Link> */}
                                    <Link
                                      to={`/app/invoice/${history.billingId}`}
                                      className="iinline-block rounded outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out font-bold text-blueGray-500 hover:text-blueGray-700 text-xl"
                                      onClick={addTab}
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            {orderHistory.length < 1 &&
                              returnNoResultFound("No recent order found")}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="mb-12 justify-center flex flex-wrap w-full">
                <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-red-500 border-red-500 active:bg-red-600 active:border-red-600 active:text-white text-sm px-6 py-2 shadow hover:shadow-lg rounded-md">
                  Cancel
                </button>
                <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-white bg-blueGray-800 border-blueGray-800 active:bg-blueGray-900 active:border-blueGray-900 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md">
                  Save Change
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
