import React from 'react'

const PageNotFound = ({children}) => {
    return (
        <div className="bg-white  pt-20 pl-4 pr-4 pb-20">
            <div className="text-center text-3xl font-bold pt-4 pb-4 text-red-500">
                {children}
            </div>
        </div>
    )
}

export default PageNotFound