// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../img/hero-img.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "../img/hero-bg.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".project-dropdown {\n  max-height: 250px;\n}\n\n@media (max-width: 768px) {\n  .hero-image {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center center;\n  }\n\n  .mobile_desktop {\n    display: block;\n    margin: 0 auto;\n  }\n}\n\n@media (min-width: 768px) {\n  .hero-image {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center center;\n  }\n}\n\n.bg-primary {\n  background-color: #0773c8;\n}\n", "",{"version":3,"sources":["webpack://src/assets/styles/custom.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,yDAA4C;IAC5C,sBAAsB;IACtB,4BAA4B;IAC5B,kCAAkC;EACpC;;EAEA;IACE,cAAc;IACd,cAAc;EAChB;AACF;;AAEA;EACE;IACE,yDAA2C;IAC3C,sBAAsB;IACtB,4BAA4B;IAC5B,kCAAkC;EACpC;AACF;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".project-dropdown {\n  max-height: 250px;\n}\n\n@media (max-width: 768px) {\n  .hero-image {\n    background-image: url(\"../img/hero-img.png\");\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center center;\n  }\n\n  .mobile_desktop {\n    display: block;\n    margin: 0 auto;\n  }\n}\n\n@media (min-width: 768px) {\n  .hero-image {\n    background-image: url(\"../img/hero-bg.png\");\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center center;\n  }\n}\n\n.bg-primary {\n  background-color: #0773c8;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
