import React from "react";

import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <>
            <div className="bg-hero bg-cover bg-no-repeat md:mt-16">
                <div className="container mx-auto">
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-5 py-10 sm:py-16 px-8">
                        <div className='text-white'>
                            <h3 className="uppercase font-bold mb-5">webwatch</h3>
                            <ul className="text-sm">
                                <li>
                                    <Link to="/pageSpeed"
                                        className="text-white-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                                        rel="noreferrer"
                                    >  Page Speed
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/WebAnalyzer"
                                        className="text-white-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                                        rel="noreferrer"
                                    >  WebAnalyzer

                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/* <div className='text-white'>
                            <h3 className="uppercase font-bold mb-5">More Tool</h3>
                            <ul className="text-sm">
                                <li className="leading-7">Analysis Reports</li>
                                <li className="leading-7">Projects</li>
                                <li className="leading-7">Content Marketplace</li>
                                <li className="leading-7">Stats and Facts</li>
                                <li className="leading-7">Affiliate Program</li>
                                <li className="leading-7">SEOquake</li>
                                <li className="leading-7">Sensor</li>
                                <li className="leading-7">Tools for Amazon</li>
                                <li className="leading-7">Prowly</li>
                                <li className="leading-7">Kompyte</li>
                                <li className="leading-7">App Center</li>
                            </ul>
                        </div> */}
                        <div className='text-white'>
                            <h3 className="uppercase font-bold mb-5"> Useful Links</h3>
                            <ul className="text-sm">
                                <li>
                                    <a
                                        className="text-white-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                                        href="https://marketmediaconnect.com/about-us/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        About Us
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-white-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                                        href="https://marketmediaconnect.com/blog/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-white-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                                        href="https://marketmediaconnect.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Services
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='text-white'>
                            <h3 className="uppercase font-bold mb-5">follow us</h3>
                            <ul className="text-sm">
                                <li className="leading-7">
                                    <a href="https://twitter.com/MarketMediaCon">
                                        <button
                                            className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                            type="button"
                                        >
                                            <i className="fab fa-twitter"></i>
                                        </button>
                                    </a>
                                </li>
                                <li className="leading-7"><a href="https://www.facebook.com/marketmediaconnect/">
                                    <button
                                        className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                        type="button"
                                    >
                                        <i className="fab fa-facebook-square"></i>
                                    </button>
                                </a></li>
                                <li className="leading-7"><a href="https://instagram.com/market.media.connect?igshid=MzRlODBiNWFlZA==">
                                    <button
                                        className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                        type="button"
                                    >
                                        <i className="fab fa-instagram"></i>
                                    </button>
                                </a></li>
                                {/* <li className="leading-7">Instagram</li>
                                <li className="leading-7">Insights</li>
                                <li className="leading-7">Youtube</li>
                                <li className="leading-7">Pinterest</li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}