/* eslint-disable react/prop-types */
import * as React from "react";
import { session } from "constant/constant";
import { signup } from "services/auth";

const AuthContext = React.createContext();
let persist = {};
if (localStorage.getItem("persist") == "undefined") {
  localStorage.removeItem("persist");
} else if (localStorage.getItem("persist") !== null) {
  persist = JSON.parse(localStorage.getItem("persist"));
}
const defaultAuthState = {
  role: persist?.role || "",
  authToken: persist?.token || "",
  userId: persist?.user_id || "",
  firstName: persist?.first_name || "",
  lastName: persist?.last_name || "",
  phone: persist?.phone || "",
  email: persist?.email || "",
  status: persist?.session || session.unauthenticated,
  signupCompleted: persist?.sign_up || false,
  emailVerified: persist?.email_verified || false,
  signupDate: persist?.signed_up_date || "",
  url: persist?.url || "",
  guestUser: persist?.guestUser || false,
};

const actions = {
  UPDATE: "update",
  SET: "set",
  DEAUTHENTICATE: "de-authenticate",
};

function authReducer(state, action) {
  switch (action.type) {
    case "update": {
      const updatedState = {
        ...state,
        ...action.data,
      };
      console.log("auth updated...", updatedState); // TODO: remove when releasing
      return updatedState;
    }
    case "set": {
      console.log("auth set..."); // TODO: remove when releasing
      return {
        ...state,
        ...action.data,
      };
    }
    case "de-authenticate": {
      console.log("user de-authenticated - logged out");
      return { ...defaultAuthState };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AuthProvider({ children }) {
  const [state, dispatch] = React.useReducer(authReducer, {
    ...defaultAuthState,
  });

  // TODO: NOTE: we might need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch, actions };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
