export function composeTitle(title = "") {
  document.title = title + " | Market Media Connect";
}

export function sessionExpire() {
  if (!localStorage.getItem("persist")) {
    window.location.href = "/auth/login";
  }
}

export function toMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", {
    month: "short",
  });
}

export function formatDate(date) {
  var newDate = new Date(date);
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ][newDate.getUTCMonth()];
  return month + " " + newDate.getUTCDate() + ", " + newDate.getUTCFullYear();
}
export const getTextColorClass = (value) => {
  let splittedValue = 0;
  if (typeof value === "string") {
    splittedValue = value.split(" ");
  }
  if (typeof value === "string" && value.includes("ms"))
    return "text-green-500";
  else if (parseFloat(splittedValue[0]) < 2.5 && value.includes("s"))
    return "text-green-500";
  else if (parseFloat(splittedValue[0]) < 4.0 && value.includes("s"))
    return "text-orange-500";
  else if (
    parseFloat(value) < 2.5 &&
    !(value.includes("s") || value.includes("ms"))
  )
    return "text-green-500";
  else return "text-red-500";
};
