import React, { useState } from "react";

const Collapsible = ({ title, subTitle = "", textClass = null, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="col-sm-4 col-sm-offset-4 w-full">
      <div className="panel-group">
        <div className="panel panel-default  p-3  shadow-lg">
          <div
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
            }}
            className={`panel-heading flex justify-between items-center text-xs font-bold uppercase px-3 py-3 rounded block leading-normal  ${
              textClass || "text-lightBlue-600"
            } bg-white cursor-pointer`}
          >
            <h4 className="panel-title ">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(!open);
                }}
              >
                {title}
              </a>
            </h4>
            {subTitle && <span> {subTitle}</span>}
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
          <div
            className={
              open ? "panel-collapse h-96" : "panel-collapse panel-close"
            }
          >
            {children}
            {/* <div className="panel-footer">Footer</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collapsible;
