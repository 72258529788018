/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import UserDropdown from "components/Dropdowns/UserDropdown.js";

/** context import */
import { useApp } from "contexts/app-context";
import { useAuth } from "contexts/auth-context";

import { sessionExpire } from "utils/helper";
import { getUserPlan } from "services/dashboard";
import logoDark from "assets/logo/logo-dark.png";
export default function Sidebar() {
  const appContext = useApp();
  const auth = useAuth();
  const history = useHistory();
  const [change, setChange] = React.useState(false);
  React.useEffect(() => {
    sessionExpire();
  }, [change]);
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [showModal, setShowModal] = React.useState(false);
  const [plan, setPlan] = useState(null);

  const handleClick = () => {
    if (auth?.state.guestUser) {
      history.push("/auth/register");
    } else {
      setShowModal(!showModal);
      appContext.dispatch({
        type: appContext.actions.UPDATE,
        data: {
          showModal: true,
        },
      });
    }
  };

  const getUserPlanInfo = async () => {
    await getUserPlan(auth?.state.authToken)
      .then((response) => {
        setPlan(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserPlanInfo();
    if (localStorage.getItem("currentPage")) {
      localStorage.removeItem("currentPage");
    }
  }, []);

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            <img
             src={logoDark}
              height="50"
              width="150"
            />
          </Link>
          {/* User */}
          <button
            className="text-white active:bg-blueGray-50 bg-blueGray-700 font-normal px-4 py-2 rounded mt-2 focus:outline-none mr-1 mb-1 shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
            type="button"
            onClick={() => handleClick()}
          >
            <i alt="..." className="w-5 mr-1 fas fa-plus" />
            Add a website
          </button>
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    <img
                    src={logoDark}
                      // src={require("assets/logo/logo-dark.png").default || require("assets/logo/logo-dark.png")}
                      height="50"
                      width="150"
                    />
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            {/* <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form> */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/app/dashboard") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setChange(!change)}
                  to="/app/dashboard"
                >
                  <i
                    className={
                      "fas fa-home mr-2 text-sm " +
                      (window.location.href.indexOf("/dashboard") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              <i className={"fas fa-tv mr-2 text-sm text-blueGray-300 "}></i>{" "}
              SEO AUDIT
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none ml-2">
              <li className="items-center">
                <Link
                  className={
                    "text-xs  py-3 font-bold block " +
                    (window.location.href.indexOf("/app/page-speed") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setChange(!change)}
                  to="/app/page-speed"
                >
                  <i
                    className={
                      "fas fa-tachometer-alt mr-2 text-sm text-blueGray-300"
                    }
                  ></i>
                  Page Speed
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs  py-3 font-bold block " +
                    (window.location.href.indexOf("/app/web-analyzer") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setChange(!change)}
                  to="/app/web-analyzer"
                >
                  {" "}
                  <i
                    className={"fas fa-search mr-2 text-sm text-blueGray-300"}
                  ></i>
                  Web Analyzer
                </Link>
              </li>

              {/* <li className="items-center">
                <Link
                  className={
                    "text-xs  py-3 font-bold block " +
                    (window.location.href.indexOf("/app/competitors") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setChange(!change)}
                  to="/app/competitors"
                >
                  Competitors
                </Link>
              </li> */}
            </ul>
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              <i
                className={"fas fa-file-alt mr-2 text-sm text-blueGray-300"}
              ></i>{" "}
              Reports Center
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center ml-2">
                <Link
                  className={
                    "text-xs  py-3 font-bold block " +
                    (window.location.href.indexOf("/app/reports") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setChange(!change)}
                  to="/app/reports"
                >
                  {" "}
                  <i
                    className={
                      "fas fa-file-pdf mr-2 text-sm  text-blueGray-300"
                    }
                  ></i>
                  Generate Reports
                </Link>
              </li>
            </ul>
            {/* <hr className="my-4 md:min-w-full" />
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              <i className={"fas fa-tachometer-alt mr-2 text-sm "}></i> Page
              Speed
            </h6>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs  py-3 font-bold block " +
                    (window.location.href.indexOf("/app/page-speed") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setChange(!change)}
                  to="/app/page-speed"
                >
                  Analyze
                </Link>
              </li>
            </ul> */}
            <hr className="my-4 md:min-w-full" />
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {/* <li className="items-center">
                <Link
                  className={
                    "text-xs  py-3 font-bold block " +
                    (window.location.href.indexOf("/app/pricing") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setChange(!change)}
                  to="/app/pricing"
                >
                  <i
                    className={
                      "fas fa-money-check-alt mr-2 text-sm " +
                      (window.location.href.indexOf("/pricing") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Pricing
                </Link>
              </li> */}
              <li className="items-center">
                <Link
                  className={
                    "text-xs  py-3 font-bold block " +
                    (window.location.href.indexOf("/app/subscription") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setChange(!change)}
                  to="/app/subscription"
                >
                  <i
                    className={
                      "fas fa-credit-card mr-2 text-sm " +
                      (window.location.href.indexOf("/subscription") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Subscription Plans
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
