import React, { useState } from "react";

const Button = ({
  onClick = "",
  loading = "",
  text = "",
  classes = "",
  icon = "",
  disabled = false,
  type = "button",
}) => {
  return (
    <>
      <button
        className={classes}
        type={type}
        onClick={onClick}
        disabled={loading}
      >
        {loading && !disabled && (
          <i
            className="fas fa-spinner fa-spin"
            style={{ marginRight: "5px" }}
          />
        )}
        {icon && <span className={icon}>Loading Data from Server</span>}
        {(!loading || disabled) && <span>{text}</span>}
      </button>
    </>
  );
};

export default Button;
