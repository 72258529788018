import React from "react";

/** components import */
import SpeedStats from "components/Cards/SpeedStats";
import ProgressBar from "components/Shared/ProgressBar";
import CardContent from "components/Cards/CardContent";
import PageDetailStats from "./PageDetailStats";
import Collapsible from "components/Collapse/Collapse";
import Table from "components/Cards/Table";

import { getTextColorClass } from "utils/helper";

import { useAuth } from "contexts/auth-context";

const coreWebVital = (desktopStats) => {
  return (
    <>
      {" "}
      <div className="flex flex-wrap">
        <CardContent
          title="Total time blocking"
          description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
          stats={
            desktopStats?.blocking_time_duration ||
            desktopStats?.["total-blocking-time"]?.displayValue
          }
          link="https://web.dev/lighthouse-total-blocking-time/"
          textClass={getTextColorClass(
            desktopStats?.blocking_time_duration ||
              desktopStats?.["total-blocking-time"]?.displayValue
          )}
        />
        <CardContent
          title="Largest contentful paint"
          description="Largest Contentful Paint (LCP) is one of the metrics tracked in the Performance section of the Lighthouse report."
          stats={
            desktopStats["largest-contentful-paint-displayvalue"] ||
            desktopStats?.["largest-contentful-paint"]?.displayValue
          }
          link="https://web.dev/lighthouse-largest-contentful-paint/"
          textClass={getTextColorClass(
            desktopStats["largest-contentful-paint-displayvalue"] ||
              desktopStats?.["largest-contentful-paint"]?.displayValue
          )}
        />
      </div>
      <div className="flex flex-wrap mt-4">
        <CardContent
          title="Cumulative Layout Shift"
          description="Cumulative Layout Shift  is a measure of the largest burst of layout shift scores for every unexpected layout shift that occurs during the entire lifespan of a page."
          stats={
            desktopStats["cumulative-layout-shift-displayValue"] ||
            desktopStats?.["cumulative-layout-shift"]?.displayValue
          }
          link="https://web.dev/cls/"
          textClass={getTextColorClass(
            desktopStats["cumulative-layout-shift-displayValue"] ||
              desktopStats?.["cumulative-layout-shift"]?.displayValue
          )}
        />
        <CardContent
          title="First Contentful Paint"
          description="First Contentful Paint (FCP) is one of six metrics tracked in the Performance section of the Lighthouse report. Each metric captures some aspect of page load speed."
          stats={
            desktopStats?.["first-contentful-paint-displayvalue"] ||
            desktopStats?.["first-contentful-paint"]?.displayValue
          }
          link="https://web.dev/first-contentful-paint/"
          textClass={getTextColorClass(
            desktopStats?.["first-contentful-paint-displayvalue"] ||
              desktopStats?.["first-contentful-paint"]?.displayValue
          )}
        />
      </div>
      <div className="flex flex-wrap mt-4">
        <CardContent
          title="Speed Index"
          description="Speed Index is one of six metrics tracked in the Performance section of the Lighthouse report. Each metric captures some aspect of page load speed."
          stats={
            desktopStats["speed-index-displayValue"] ||
            desktopStats?.["speed-index"]?.displayValue
          }
          link="https://web.dev/speed-index/"
          textClass={getTextColorClass(
            desktopStats["speed-index-displayValue"] ||
              desktopStats?.["speed-index"]?.displayValue
          )}
        />
        <CardContent
          title="Time to Interactive"
          description="Time to Interactive (TTI) is one of six metrics tracked in the Performance section of the Lighthouse report. Each metric captures some aspect of page load speed."
          stats={
            desktopStats["interactive-displayvalue"] ||
            desktopStats?.interactive?.displayValue
          }
          link="https://web.dev/interactive/"
          textClass={getTextColorClass(
            desktopStats["interactive-displayvalue"] ||
              desktopStats?.interactive?.displayValue
          )}
        />
      </div>
    </>
  );
};

const ErrorsToFix = (desktopStats) => {
  return (
    <>
      {desktopStats["total-byte-weight"]?.details?.items &&
        desktopStats["total-byte-weight"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["total-byte-weight"]?.title}
              subTitle={desktopStats["total-byte-weight"]?.displayValue}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["total-byte-weight"].description}
                </p>
                <Table
                  title={desktopStats["total-byte-weight"]?.title}
                  items={desktopStats["total-byte-weight"]?.details?.items}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["render-blocking-resources"]?.details?.items &&
        desktopStats["render-blocking-resources"]?.details?.items.length >
          0 && (
          <div className="flex flex-wrap">
            <Collapsible
              title={desktopStats["render-blocking-resources"]?.title}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["render-blocking-resources"].description}
                </p>
                <Table
                  title={desktopStats["render-blocking-resources"]?.title}
                  items={
                    desktopStats["render-blocking-resources"]?.details?.items
                  }
                  headings={
                    desktopStats["render-blocking-resources"].details.headings
                  }
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["uses-long-cache-ttl"]?.details?.items &&
        desktopStats["uses-long-cache-ttl"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["uses-long-cache-ttl"]?.title}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["uses-long-cache-ttl"].description}
                </p>
                <Table
                  title={desktopStats["uses-long-cache-ttl"]?.title}
                  items={desktopStats["uses-long-cache-ttl"]?.details?.items}
                  headings={
                    desktopStats["uses-long-cache-ttl"].details.headings
                  }
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["unsized-images"]?.details?.items &&
        desktopStats["unsized-images"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["unsized-images"]?.title}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["unsized-images"].description}
                </p>
                <Table
                  title={desktopStats["unsized-images"]?.title}
                  items={desktopStats["unsized-images"]?.details?.items}
                  headings={desktopStats["unsized-images"].details.headings}
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["long-tasks"]?.details?.items &&
        desktopStats["long-tasks"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["long-tasks"]?.title}
              subTitle={desktopStats["long-tasks"]?.displayValue}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["long-tasks"].description}
                </p>
                <Table
                  title={desktopStats["long-tasks"]?.title}
                  items={desktopStats["long-tasks"]?.details?.items}
                  headings={desktopStats["long-tasks"].details.headings}
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["uses-responsive-images"]?.details?.items &&
        desktopStats["uses-responsive-images"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["uses-responsive-images"]?.title}
              subTitle={desktopStats["uses-responsive-images"]?.displayValue}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["uses-responsive-images"].description}
                </p>
                <Table
                  title={desktopStats["uses-responsive-images"]?.title}
                  items={desktopStats["uses-responsive-images"]?.details?.items}
                  headings={
                    desktopStats["uses-responsive-images"].details.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["unused-javascript"]?.details?.items &&
        desktopStats["unused-javascript"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["unused-javascript"]?.title}
              subTitle={desktopStats["unused-javascript"]?.displayValue}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["unused-javascript"].description}
                </p>
                <Table
                  title={desktopStats["unused-javascript"]?.title}
                  items={desktopStats["unused-javascript"]?.details?.items}
                  headings={desktopStats["unused-javascript"].details.headings}
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["unused-css-rules"]?.details?.items &&
        desktopStats["unused-css-rules"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["unused-css-rules"]?.title}
              subTitle={desktopStats["unused-css-rules"]?.displayValue}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["unused-css-rules"].description}
                </p>
                <Table
                  title={desktopStats["unused-css-rules"]?.title}
                  items={desktopStats["unused-css-rules"]?.details?.items}
                  headings={desktopStats["unused-css-rules"].details.headings}
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
    </>
  );
};

const successfulAudits = (desktopStats) => {
  return (
    <>
      {desktopStats["offscreen-images"]?.details?.items &&
        desktopStats["offscreen-images"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["offscreen-images"]?.title}
              subTitle={desktopStats["offscreen-images"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["offscreen-images"].description}
                </p>
                <Table
                  title={desktopStats["offscreen-images"]?.title}
                  items={desktopStats["offscreen-images"]?.details?.items}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["unminified-css"]?.details?.items &&
        desktopStats["unminified-css"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["unminified-css"].title}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["unminified-css"].description}
                </p>
                <Table
                  title={desktopStats["unminified-css"]?.title}
                  items={desktopStats["unminified-css"]?.details?.items}
                  headings={desktopStats["unminified-css"].details.headings}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["unminified-javascript"]?.details?.items &&
        desktopStats["unminified-javascript"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["unminified-javascript"].title}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["unminified-javascript"].description}
                </p>
                <Table
                  title={desktopStats["unminified-javascript"]?.title}
                  items={desktopStats["unminified-javascript"]?.details?.items}
                  headings={
                    desktopStats["unminified-javascript"].details.headings
                  }
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["unused-css-rules"]?.details?.items &&
        desktopStats["unused-css-rules"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["unused-css-rules"].title}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["unused-css-rules"].description}
                </p>
                <Table
                  title={desktopStats["unused-css-rules"]?.title}
                  items={desktopStats["unused-css-rules"]?.details?.items}
                  headings={desktopStats["unused-css-rules"].details.headings}
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["uses-optimized-images"]?.details?.items &&
        desktopStats["uses-optimized-images"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["uses-optimized-images"]?.title}
              subTitle={desktopStats["uses-optimized-images"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["uses-optimized-images"]?.description}
                </p>
                <Table
                  title={desktopStats["uses-optimized-images"]?.title}
                  items={desktopStats["uses-optimized-images"]?.details?.items}
                  headings={
                    desktopStats["uses-optimized-images"]?.details?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["modern-image-formats"]?.details?.items &&
        desktopStats["modern-image-formats"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["modern-image-formats"]?.title}
              subTitle={desktopStats["modern-image-formats"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["modern-image-formats"]?.description}
                </p>
                <Table
                  title={desktopStats["modern-image-formats"]?.title}
                  items={desktopStats["modern-image-formats"]?.details?.items}
                  headings={
                    desktopStats["modern-image-formats"]?.details?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["uses-text-compression"]?.details?.items &&
        desktopStats["uses-text-compression"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["uses-text-compression"]?.title}
              subTitle={desktopStats["uses-text-compression"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["uses-text-compression"]?.description}
                </p>
                <Table
                  title={desktopStats["uses-text-compression"]?.title}
                  items={desktopStats["uses-text-compression"]?.details?.items}
                  headings={
                    desktopStats["uses-text-compression"]?.details?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["uses-rel-preconnect"]?.details?.items &&
        desktopStats["uses-rel-preconnect"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["uses-rel-preconnect"]?.title}
              subTitle={desktopStats["uses-rel-preconnect"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["uses-rel-preconnect"]?.description}
                </p>
                <Table
                  title={desktopStats["uses-rel-preconnect"]?.title}
                  items={desktopStats["uses-rel-preconnect"]?.details?.items}
                  headings={
                    desktopStats["uses-rel-preconnect"]?.details?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["efficient-animated-content"]?.details?.items &&
        desktopStats["efficient-animated-content"]?.details?.items.length <
          1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["efficient-animated-content"]?.title}
              subTitle={
                desktopStats["efficient-animated-content"]?.displayValue
              }
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["efficient-animated-content"]?.description}
                </p>
                <Table
                  title={desktopStats["efficient-animated-content"]?.title}
                  items={
                    desktopStats["efficient-animated-content"]?.details?.items
                  }
                  headings={
                    desktopStats["efficient-animated-content"]?.details
                      ?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["duplicated-javascript"]?.details?.items &&
        desktopStats["duplicated-javascript"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["duplicated-javascript"]?.title}
              subTitle={desktopStats["duplicated-javascript"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["duplicated-javascript"]?.description}
                </p>
                <Table
                  title={desktopStats["duplicated-javascript"]?.title}
                  items={desktopStats["duplicated-javascript"]?.details?.items}
                  headings={
                    desktopStats["duplicated-javascript"]?.details?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {desktopStats["redirects"]?.details?.items &&
        desktopStats["redirects"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={desktopStats["redirects"]?.title}
              subTitle={desktopStats["redirects"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {desktopStats["redirects"]?.description}
                </p>
                <Table
                  title={desktopStats["redirects"]?.title}
                  items={desktopStats["redirects"]?.details?.items}
                  headings={desktopStats["redirects"]?.details?.headings}
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
    </>
  );
};
const mobileCoreWebVital = (mobileStats) => {
  return (
    <>
      {" "}
      <div className="flex flex-wrap">
        <CardContent
          title="Total time blocking"
          description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
          stats={
            mobileStats?.blocking_time_duration ||
            mobileStats?.["total-blocking-time"]?.displayValue
          }
          link="https://web.dev/lighthouse-total-blocking-time/"
          textClass={getTextColorClass(
            mobileStats?.blocking_time_duration ||
              mobileStats?.["total-blocking-time"]?.displayValue
          )}
        />
        <CardContent
          title="Largest contentful paint"
          description="Largest Contentful Paint (LCP) is one of the metrics tracked in the Performance section of the Lighthouse report."
          stats={
            mobileStats["largest-contentful-paint-displayvalue"] ||
            mobileStats?.["largest-contentful-paint"]?.displayValue
          }
          link="https://web.dev/lighthouse-largest-contentful-paint/"
          textClass={getTextColorClass(
            mobileStats["largest-contentful-paint-displayvalue"] ||
              mobileStats?.["largest-contentful-paint"]?.displayValue
          )}
        />
      </div>
      <div className="flex flex-wrap mt-4">
        <CardContent
          title="Cumulative Layout Shift"
          description="Cumulative Layout Shift  is a measure of the largest burst of layout shift scores for every unexpected layout shift that occurs during the entire lifespan of a page."
          stats={
            mobileStats["cumulative-layout-shift-displayValue"] ||
            mobileStats?.["cumulative-layout-shift"]?.displayValue
          }
          link="https://web.dev/cls/"
          textClass={getTextColorClass(
            mobileStats["cumulative-layout-shift-displayValue"] ||
              mobileStats?.["cumulative-layout-shift"]?.displayValue
          )}
        />
        <CardContent
          title="First Contentful Paint"
          description="First Contentful Paint (FCP) is one of six metrics tracked in the Performance section of the Lighthouse report. Each metric captures some aspect of page load speed."
          stats={
            mobileStats?.["first-contentful-paint-displayvalue"] ||
            mobileStats?.["first-contentful-paint"]?.displayValue
          }
          link="https://web.dev/first-contentful-paint/"
          textClass={getTextColorClass(
            mobileStats?.["first-contentful-paint-displayvalue"] ||
              mobileStats?.["first-contentful-paint"]?.displayValue
          )}
        />
      </div>
      <div className="flex flex-wrap mt-4">
        <CardContent
          title="Speed Index"
          description="Speed Index is one of six metrics tracked in the Performance section of the Lighthouse report. Each metric captures some aspect of page load speed."
          stats={
            mobileStats["speed-index-displayValue"] ||
            mobileStats?.["speed-index"]?.displayValue
          }
          link="https://web.dev/speed-index/"
          textClass={getTextColorClass(
            mobileStats["speed-index-displayValue"] ||
              mobileStats?.["speed-index"]?.displayValue
          )}
        />
        <CardContent
          title="Time to Interactive"
          description="Time to Interactive (TTI) is one of six metrics tracked in the Performance section of the Lighthouse report. Each metric captures some aspect of page load speed."
          stats={
            mobileStats["interactive-displayvalue"] ||
            mobileStats?.interactive?.displayValue
          }
          link="https://web.dev/interactive/"
          textClass={getTextColorClass(
            mobileStats["interactive-displayvalue"] ||
              mobileStats?.interactive?.displayValue
          )}
        />
      </div>
    </>
  );
};

const mobileErrorsToFix = (mobileStats) => {
  return (
    <>
      {mobileStats["total-byte-weight"]?.details?.items &&
        mobileStats["total-byte-weight"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["total-byte-weight"]?.title}
              subTitle={mobileStats["total-byte-weight"]?.displayValue}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["total-byte-weight"].description}
                </p>
                <Table
                  title={mobileStats["total-byte-weight"]?.title}
                  items={mobileStats["total-byte-weight"]?.details?.items}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["render-blocking-resources"]?.details?.items &&
        mobileStats["render-blocking-resources"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap">
            <Collapsible
              title={mobileStats["render-blocking-resources"]?.title}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["render-blocking-resources"].description}
                </p>
                <Table
                  title={mobileStats["render-blocking-resources"]?.title}
                  items={
                    mobileStats["render-blocking-resources"]?.details?.items
                  }
                  headings={
                    mobileStats["render-blocking-resources"].details.headings
                  }
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["uses-long-cache-ttl"]?.details?.items &&
        mobileStats["uses-long-cache-ttl"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["uses-long-cache-ttl"]?.title}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["uses-long-cache-ttl"].description}
                </p>
                <Table
                  title={mobileStats["uses-long-cache-ttl"]?.title}
                  items={mobileStats["uses-long-cache-ttl"]?.details?.items}
                  headings={mobileStats["uses-long-cache-ttl"].details.headings}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["unsized-images"]?.details?.items &&
        mobileStats["unsized-images"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["unsized-images"]?.title}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["unsized-images"].description}
                </p>
                <Table
                  title={mobileStats["unsized-images"]?.title}
                  items={mobileStats["unsized-images"]?.details?.items}
                  headings={mobileStats["unsized-images"].details.headings}
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["long-tasks"]?.details?.items &&
        mobileStats["long-tasks"]?.details?.items.length > 0 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["long-tasks"]?.title}
              subTitle={mobileStats["long-tasks"]?.displayValue}
              textClass="text-red-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["long-tasks"].description}
                </p>
                <Table
                  title={mobileStats["long-tasks"]?.title}
                  items={mobileStats["long-tasks"]?.details?.items}
                  headings={mobileStats["long-tasks"].details.headings}
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
    </>
  );
};

const mobileSuccessfulAudits = (mobileStats) => {
  return (
    <>
      {mobileStats["offscreen-images"]?.details?.items &&
        mobileStats["offscreen-images"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["offscreen-images"].title}
              subTitle={mobileStats["offscreen-images"].displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["offscreen-images"].description}
                </p>
                <Table
                  title={mobileStats["offscreen-images"]?.title}
                  items={mobileStats["offscreen-images"]?.details?.items}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["unminified-css"]?.details?.items &&
        mobileStats["unminified-css"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["unminified-css"].title}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["unminified-css"].description}
                </p>
                <Table
                  title={mobileStats["unminified-css"]?.title}
                  items={mobileStats["unminified-css"]?.details?.items}
                  headings={mobileStats["unminified-css"].details.headings}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["unminified-javascript"]?.details?.items &&
        mobileStats["unminified-javascript"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["unminified-javascript"].title}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["unminified-javascript"].description}
                </p>
                <Table
                  title={mobileStats["unminified-javascript"]?.title}
                  items={mobileStats["unminified-javascript"]?.details?.items}
                  headings={
                    mobileStats["unminified-javascript"].details.headings
                  }
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["unused-css-rules"]?.details?.items &&
        mobileStats["unused-css-rules"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["unused-css-rules"].title}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["unused-css-rules"].description}
                </p>
                <Table
                  title={mobileStats["unused-css-rules"]?.title}
                  items={mobileStats["unused-css-rules"]?.details?.items}
                  headings={mobileStats["unused-css-rules"].details.headings}
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["uses-optimized-images"]?.details?.items &&
        mobileStats["uses-optimized-images"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["uses-optimized-images"]?.title}
              subTitle={mobileStats["uses-optimized-images"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["uses-optimized-images"]?.description}
                </p>
                <Table
                  title={mobileStats["uses-optimized-images"]?.title}
                  items={mobileStats["uses-optimized-images"]?.details?.items}
                  headings={
                    mobileStats["uses-optimized-images"]?.details?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["modern-image-formats"]?.details?.items &&
        mobileStats["modern-image-formats"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["modern-image-formats"]?.title}
              subTitle={mobileStats["modern-image-formats"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["modern-image-formats"]?.description}
                </p>
                <Table
                  title={mobileStats["modern-image-formats"]?.title}
                  items={mobileStats["modern-image-formats"]?.details?.items}
                  headings={
                    mobileStats["modern-image-formats"]?.details?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["uses-text-compression"]?.details?.items &&
        mobileStats["uses-text-compression"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["uses-text-compression"]?.title}
              subTitle={mobileStats["uses-text-compression"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["uses-text-compression"]?.description}
                </p>
                <Table
                  title={mobileStats["uses-text-compression"]?.title}
                  items={mobileStats["uses-text-compression"]?.details?.items}
                  headings={
                    mobileStats["uses-text-compression"]?.details?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["uses-rel-preconnect"]?.details?.items &&
        mobileStats["uses-rel-preconnect"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["uses-rel-preconnect"]?.title}
              subTitle={mobileStats["uses-rel-preconnect"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["uses-rel-preconnect"]?.description}
                </p>
                <Table
                  title={mobileStats["uses-rel-preconnect"]?.title}
                  items={mobileStats["uses-rel-preconnect"]?.details?.items}
                  headings={
                    mobileStats["uses-rel-preconnect"]?.details?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["efficient-animated-content"]?.details?.items &&
        mobileStats["efficient-animated-content"]?.details?.items.length <
          1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["efficient-animated-content"]?.title}
              subTitle={mobileStats["efficient-animated-content"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["efficient-animated-content"]?.description}
                </p>
                <Table
                  title={mobileStats["efficient-animated-content"]?.title}
                  items={
                    mobileStats["efficient-animated-content"]?.details?.items
                  }
                  headings={
                    mobileStats["efficient-animated-content"]?.details?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["duplicated-javascript"]?.details?.items &&
        mobileStats["duplicated-javascript"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["duplicated-javascript"]?.title}
              subTitle={mobileStats["duplicated-javascript"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["duplicated-javascript"]?.description}
                </p>
                <Table
                  title={mobileStats["duplicated-javascript"]?.title}
                  items={mobileStats["duplicated-javascript"]?.details?.items}
                  headings={
                    mobileStats["duplicated-javascript"]?.details?.headings
                  }
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
      {mobileStats["redirects"]?.details?.items &&
        mobileStats["redirects"]?.details?.items.length < 1 && (
          <div className="flex flex-wrap ">
            <Collapsible
              title={mobileStats["redirects"]?.title}
              subTitle={mobileStats["redirects"]?.displayValue}
              textClass="text-green-500"
            >
              <>
                <p className="text-xs my-4 px-3">
                  {mobileStats["redirects"]?.description}
                </p>
                <Table
                  title={mobileStats["redirects"]?.title}
                  items={mobileStats["redirects"]?.details?.items}
                  headings={mobileStats["redirects"]?.details?.headings}
                  isStaticHeader={false}
                ></Table>
              </>
            </Collapsible>
          </div>
        )}
    </>
  );
};

const Tabs = ({
  desktopStats,
  desktopPerformance,
  desktopLoading,
  mobileStats,
  mobilePerformance,
  mobileLoading,
}) => {
  const authContext = useAuth();
  const [openTab, setOpenTab] = React.useState(1);
  const [auth, setAuth] = React.useState(authContext.state);
  return (
    <>
      <div className="flex flex-wrap justify-center w-full overflow-x-hidden">
        <ul
          className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row mr-3 my-4 w-full"
          role="tablist"
        >
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 1
                  ? "text-white bg-lightBlue-600"
                  : "text-lightBlue-600 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              <i className="fas fa-desktop text-base mr-1"></i> Desktop
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 2
                  ? "text-white bg-lightBlue-600"
                  : "text-lightBlue-600 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                if (!authContext.state.guestUser) {
                  setOpenTab(2);
                }
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              <i className="fas fa-mobile-alt text-base mr-1"></i> Mobile
            </a>
          </li>
        </ul>
        <div className="w-full px-4 flex justify-between items-center justify-center shadow-lg">
          <div className="relative flex flex-col min-w-0 break-words bg-lightBlue-900 w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <div className=" md:py-3 px-3 py-3 flex justify-between items-center">
                    <div className="">
                      <ProgressBar
                        strokeWidth={8}
                        percentage={
                          parseInt(desktopStats?.performance) ||
                          parseInt(desktopPerformance?.score * 100)
                        }
                      />
                      <h2 className="text-white bold text-xl mt-2">
                        Preformance
                      </h2>
                      <p className="text-white">
                        Values are estimated and may vary. The performance score
                        <br></br>
                        is calculated directly from these metrics.
                        <a
                          href="https://web.dev/performance-scoring/?utm_source=lighthouse&utm_medium=lr"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          See calculator.
                        </a>
                      </p>
                    </div>
                  </div>
                  {/* Errors & diagonatics key max-potential-fid, render-blocking-resources, bootup-time, critical-request-chains
                  duplicated-javascript, legacy-javascript, long-tasks, modern-image-formats
                  unused-css-rules, unsized-images, unminified-css,unminified-javascript*/}
                  <div className=" md:pt-3 pb-3 pt-3 flex flex-col lg:flex-row">
                    <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                      <SpeedStats
                        statSubtitle="Page load time"
                        statTitle={
                          desktopStats?.["interactive-displayvalue"] ||
                          desktopStats?.["bootup-time"]?.displayValue
                        }
                        statArrow="up"
                        statPercent="3.48"
                        statPercentColor="text-emerald-500"
                        statIconName="fas fa-info-circle"
                      />
                    </div>
                    <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                      <SpeedStats
                        statSubtitle="Potential First Input"
                        statTitle={
                          desktopStats[
                            "max-potential-Firstinputdelay-displayvalue"
                          ] || desktopStats?.["max-potential-fid"]?.displayValue
                        }
                        statArrow="up"
                        statPercent="3.48"
                        statPercentColor="text-emerald-500"
                        statIconName="fas fa-info-circle"
                      />
                    </div>
                    <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                      <SpeedStats
                        statSubtitle={
                          desktopStats?.blocking_time_score
                            ? "Blocking Time Score"
                            : "Render Blocking Resources"
                        }
                        statTitle={
                          desktopStats?.blocking_time_score ||
                          desktopStats?.["render-blocking-resources"]
                            ?.displayValue
                        }
                        statArrow="up"
                        statPercent="3.48"
                        statPercentColor="text-emerald-500"
                        statIconName="fas fa-info-circle"
                      />
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0 break-words w-full mt-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                    <div className="flex-auto  py-10 mt-2">
                      <div className="relative flex flex-col min-w-0 break-words  rounded mb-6 xl:mb-0 shadow-lg bg-blueGray-100 border-0">
                        <div className="flex-auto  p-5 mt-2">
                          <h2 className="text-center mb-3 text-2xl font-semibold">
                            Screenshot's
                          </h2>
                          <div className="flex">
                            <div className="w-full lg:w-12/12 xl:w-12/12 px-4">
                              {/* {desktopStats["screenshot-thumbnails"]?.details
                                ?.items.length > 0 &&
                                desktopStats[
                                  "screenshot-thumbnails"
                                ]?.details?.items.map(
                                  (item, index) =>
                                    index + 1 ===
                                      desktopStats["screenshot-thumbnails"]
                                        ?.details?.items.length && (
                                      <img
                                        key={index}
                                        src={item?.data}
                                        style={{ display: "inline" }}
                                        className="mb-3 mr-3"
                                      />
                                    )
                                )} */}
                              {desktopStats["final-screenshot"]?.details
                                .data && (
                                <img
                                  src={
                                    desktopStats["final-screenshot"]?.details
                                      .data
                                  }
                                  className="text-center mx-auto"
                                />
                              )}
                            </div>
                            {/* <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
                              {desktopStats["full-page-screenshot"]?.details
                                ?.screenshot?.data && (
                                <img
                                  src={
                                    desktopStats["full-page-screenshot"]
                                      ?.details?.screenshot?.data
                                  }
                                  className="text-center"
                                />
                              )}
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <header className="block relative px-4 mt-5">
                        <h2 className="text-2xl font-semibold mt-0 mb-2">
                          Core Web Vitals Assessment | Metrics{" "}
                        </h2>
                      </header>
                      <PageDetailStats
                        WebPageLoadingEmulation={coreWebVital(desktopStats)}
                        ErrorsToFix={ErrorsToFix(desktopStats)}
                        successfulAudits={successfulAudits(desktopStats)}
                      />
                    </div>
                  </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <div className=" md:py-3 px-3 py-3 flex justify-between items-center">
                    {mobileStats && (
                      <div className="">
                        <ProgressBar
                          strokeWidth={8}
                          percentage={
                            parseInt(mobileStats?.performance) ||
                            parseInt(mobilePerformance?.score * 100)
                          }
                        />
                        <h2 className="text-white bold text-xl mt-2">
                          Preformance
                        </h2>
                        <p className="text-white">
                          Values are estimated and may vary. The performance
                          score
                          <br></br>
                          is calculated directly from these metrics.
                          <a
                            href="https://web.dev/performance-scoring/?utm_source=lighthouse&utm_medium=lr"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            See calculator.
                          </a>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className=" md:pt-3 pb-3 pt-3 flex flex-col lg:flex-row">
                    <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                      {mobileStats && (
                        <SpeedStats
                          statSubtitle="Page load time"
                          statTitle={
                            mobileStats?.["interactive-displayvalue"] ||
                            mobileStats?.["bootup-time"]?.displayValue
                          }
                          statArrow="up"
                          statPercent="3.48"
                          statPercentColor="text-emerald-500"
                          statIconName="fas fa-info-circle"
                        />
                      )}
                    </div>
                    <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                      {mobileStats && (
                        <SpeedStats
                          statSubtitle="Potential First Input"
                          statTitle={
                            mobileStats?.[
                              "max-potential-Firstinputdelay-displayvalue"
                            ] ||
                            mobileStats?.["max-potential-fid"]?.displayValue
                          }
                          statArrow="up"
                          statPercent="3.48"
                          statPercentColor="text-emerald-500"
                          statIconName="fas fa-info-circle"
                        />
                      )}
                    </div>
                    <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                      {mobileStats && (
                        <SpeedStats
                          statSubtitle={
                            mobileStats?.blocking_time_score
                              ? "Blocking Time Score"
                              : "Render Blocking Resources"
                          }
                          statTitle={
                            mobileStats?.blocking_time_score ||
                            mobileStats?.["render-blocking-resources"]
                              ?.displayValue
                          }
                          statArrow="up"
                          statPercent="3.48"
                          statPercentColor="text-emerald-500"
                          statIconName="fas fa-info-circle"
                        />
                      )}
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0 break-words w-full mt-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                    <div className="flex-auto  py-10 mt-2">
                      <div className="relative flex flex-col min-w-0 break-words  rounded mb-6 xl:mb-0 shadow-lg bg-blueGray-100 border-0">
                        {/* <div className="flex-auto  p-5 mt-2">
                          {" "}
                          <h3 className="text-center">
                            {" "}
                            Core Web Vitals Assessment
                          </h3>
                        </div> */}
                        <div className="flex-auto  p-5 mt-2">
                          <h2 className="text-center mb-3 text-2xl font-semibold">
                            Screenshot's
                          </h2>
                          <div className="flex justify-center">
                            <div className="w-full lg:w-12/12 xl:w-12/12 px-4">
                              {/* {mobileStats &&
                                mobileStats["screenshot-thumbnails"]?.details
                                  ?.items.length > 0 &&
                                mobileStats[
                                  "screenshot-thumbnails"
                                ]?.details?.items.map((item, index) => (
                                  <img
                                    key={index}
                                    src={item?.data}
                                    style={{ display: "inline" }}
                                    className="mb-3 mr-3"
                                  />
                                ))} */}
                              {mobileStats &&
                                mobileStats["final-screenshot"]?.details
                                  .data && (
                                  <img
                                    src={
                                      mobileStats["final-screenshot"]?.details
                                        .data
                                    }
                                    className="text-center mx-auto"
                                  />
                                )}
                            </div>
                            {/* <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
                              {mobileStats &&
                                mobileStats["full-page-screenshot"]?.details
                                  ?.screenshot?.data && (
                                  <img
                                    src={
                                      mobileStats["full-page-screenshot"]
                                        ?.details?.screenshot?.data
                                    }
                                    className="text-center"
                                  />
                                )}
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <header className="block relative px-4 mt-5">
                        <h2 className="text-2xl font-semibold mt-0 mb-2">
                          Core Web Vitals Assessment | Metrics
                        </h2>
                      </header>
                      {mobileStats && (
                        <PageDetailStats
                          WebPageLoadingEmulation={mobileCoreWebVital(
                            mobileStats
                          )}
                          ErrorsToFix={mobileErrorsToFix(mobileStats)}
                          successfulAudits={mobileSuccessfulAudits(mobileStats)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tabs;
