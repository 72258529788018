import React, { useState } from "react";
import { createPopper } from "@popperjs/core";
import { Link, useHistory } from "react-router-dom";

/**Authcontext import */
import { useAuth } from "contexts/auth-context";

const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.useRef();
  const popoverDropdownRef = React.useRef();
  const authContext = useAuth();
  const history = useHistory();

  const [auth, setAuth] = useState(authContext?.state);
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleLogout = async () => {
    localStorage.clear();
    await authContext.dispatch({
      type: authContext.actions.DEAUTHENTICATE,
    });
    history.push("/");
  };

  React.useEffect(() => {
    const closeDropDown = (e) => {
      if (
        btnDropdownRef.current &&
        !btnDropdownRef.current.contains(e.target)
      ) {
        closeDropdownPopover();
      }
    };

    document.addEventListener("click", closeDropDown, true);

    // Cleanup function
    return () => {
      document.removeEventListener("click", closeDropDown, true);
    };
  }, []);
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <div
              title={auth?.firstName + " " + auth?.lastName}
              className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full  bg-lightBlue-500"
            >
              <span className="font-bold">
                {auth?.firstName
                  ? auth?.firstName?.charAt(0) + "" + auth?.lastName?.charAt(0)
                  : "Guest"}
              </span>
            </div>
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <p className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
          {!auth?.guestUser
            ? auth?.firstName + " " + auth?.lastName
            : "Guest User"}
        </p>
        <Link
          to="/app/account-settings"
          onClick={closeDropdownPopover}
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
        >
          My account
        </Link>
        <Link
          to="/app/change-password"
          onClick={closeDropdownPopover}
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
        >
          Change Password
        </Link>
        {/* <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => e.preventDefault()}
        >
          Downloads
        </a> */}
        <div className="h-0 my-2 border border-solid border-blueGray-100" />
        <a
          href="#logout"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={handleLogout}
        >
          <i className="fas fa-sign-out-alt mr-2 text-sm "></i> Logout
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
