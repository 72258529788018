import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "contexts/auth-context";

// components
import PercentageCircularBar from "components/Shared/PercentageCircularBar";
import Skeleton from "components/Shared/Skeleton";
import PageNotFound from "components/Shared/PageNotFound";

/** hooks import */

import useAccountMiddleware from "hooks/useAccountMiddleware";
import {
  auditsCount,
  errorCount,
  warningCount,
  filterExternalLinks,
  filterInternalLinks,
} from "utils/pagestats";

/**alert import */
import PercentageProgressBar from "components/Shared/PercentageProgressBar";
import toast from "react-hot-toast";
import { analyzeViewDetail } from "services/project";

export default function ProjectAnalyzerCard({ project }) {
  const authContext = useAuth();
  const history = useHistory();
  const [isSignUpCompleted, isPlanSubscribed, plan] = useAccountMiddleware();
  const [loading, setLoading] = useState(false);
  const [averageResponseTime, setAverageResponseTime] = useState(0);
  const [projectImage, setProjectImage] = useState(null);
  const [tags, setTags] = useState([]);
  const [headings, setHeadings] = useState({
    h1: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
  });
  const [images, setImages] = useState([]);
  const [internalLinks, setInternalLinks] = useState([]);
  const [externalLinks, setExternalLinks] = useState([]);
  const [scripts, setScripts] = useState([]);
  const [favIcon, setFavIcon] = useState(null);
  const [wordsPerPage, setWordsPerPage] = useState(0);
  const [commonOneWords, setCommonOneWords] = useState([]);
  const [commonTwoWords, setCommonTwoWords] = useState([]);
  const [commonThreeWords, setCommonThreeWords] = useState([]);
  const [Tscore, setTscore] = useState(null);
  const [successfulAudits, setSuccessfulAudits] = useState(0);
  const [errorAudits, setErrorAudits] = useState(0);
  const [warningAudits, setwarningAudits] = useState(0);
  const [status, setStatus] = useState(false);

  const getAuditsCount = (data) => {
    setSuccessfulAudits(auditsCount(data));
  };
  const getErrorAuditsCount = (data) => {
    setErrorAudits(errorCount(data));
  };
  const getWarningAuditsCount = (data) => {
    setwarningAudits(warningCount(data));
  };

  const filterOutExternalLinks = (data) => {
    setExternalLinks(filterExternalLinks(data));
  };

  const filterOutInternalLinks = (data) => {
    setInternalLinks(filterInternalLinks(data));
  };

  function myprogress() {
    setAverageResponseTime((preState) => {
      if (preState < 80) {
        return preState + 20;
      } else {
        return preState;
      }
    });
  }

  let myInterval = null;

  const loadPageData = async () => {
    setAverageResponseTime(0);
    myInterval = setInterval(() => {
      myprogress();
    }, 5000);
    setLoading(true);
    await analyzeViewDetail(project, authContext?.state.authToken)
      .then((response) => {
        if (response.analyzeData) {
          setStatus(true);
          setAverageResponseTime(100);
          setLoading(false);
          clearInterval(myInterval);
          setProjectImage(response.analyzeData?.siteImage);
          setTags(
            Object.entries(response.analyzeData?.tags).map(([key, value]) => ({
              key,
              value,
            }))
          );
          setHeadings({
            ...headings,
            h1: response.analyzeData?.h1,
            h2: response.analyzeData?.h2,
            h3: response.analyzeData?.h3,
            h4: response.analyzeData?.h4,
            h5: response.analyzeData?.h5,
            h6: response.analyzeData?.h6,
          });
          setImages(response.analyzeData?.images);
          setFavIcon(response.analyzeData?.favicon_url);
          setCommonOneWords(response.analyzeData?.commonOneWords);
          setCommonTwoWords(response.analyzeData?.commonTwoWords);
          setCommonThreeWords(response.analyzeData?.commonThreeWords);
          setWordsPerPage(response.analyzeData?.words_per_page);
          setScripts(response.analyzeData?.scripts);
          setTscore(Math.floor(response?.Tscore));
          getAuditsCount(response?.analyzeData);
          getErrorAuditsCount(response?.analyzeData);
          getWarningAuditsCount(response?.analyzeData);
          filterOutExternalLinks(response?.analyzeData);
          filterOutInternalLinks(response?.analyzeData);
        } else {
          setLoading(false);
          setStatus(false);
          setAverageResponseTime(0);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAverageResponseTime(0);
        toast.error(error);
        setStatus(false);
      });
  };

  useEffect(() => {
    isPlanSubscribed();
    isSignUpCompleted();
    if (project === "null") {
      toast.error("You have not generated a project report.");
    } else {
      loadPageData();
    }
  }, []);

  return (
    <>
      <div className="w-full relative flex flex-col min-w-0 break-words  mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0 py-10 overflow-x-hidden">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex flex-col lg:flex-row justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Project Analyze Detail
            </h6>
            <Link
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 mt-5 lg:mt-0"
              to="/app/dashboard"
            >
              Go To homepage
            </Link>
          </div>

          <div className="mt-10">
            <div className="flex flex-col justify-center items-center">
              {loading ? (
                <PercentageProgressBar
                  percentage={averageResponseTime}
                ></PercentageProgressBar>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && <Skeleton />}
      {!loading && status && (
        <div className="font-arimo bg-white  pt-20 pl-4 pr-4 pb-20">
          <div className="font-arimo flex flex-col lg:flex-row">
            <div className="font-arimo w-full lg:w-6/12 xl:w-4/12 px-4">
              <PercentageCircularBar percentage={Tscore} />
            </div>
            <div className="font-arimo w-full lg:w-6/12 xl:w-4/12 px-4 mt-5 lg:mt-0">
              <div className="font-arimo text-teal-500 border-2 border-grey-300 rounded py-5 px-4 pt-5">
                <h1 className="font-arimo font-bold">Successfull</h1>
                <p>{successfulAudits}</p>
              </div>
              <div className="font-arimo border-2 border-grey-300 rounded py-5 px-4 mt-5">
                <h1 className="font-arimo text-red-500 font-bold">Errors</h1>
                <p>{errorAudits}</p>
              </div>
              <div className="font-arimo border-2 border-grey-300 rounded py-5 px-4  mt-5">
                <h1 className="font-arimo text-orange-500 font-bold">
                  Warnings
                </h1>
                <p>{warningAudits}</p>
              </div>
            </div>
            <div className="font-arimo w-full lg:w-6/12 xl:w-4/12 px-4 mt-5 lg:mt-0">
              <img src={projectImage} alt="" height="300" width="300" />
            </div>
          </div>
          <div className="font-arimo pt-20">
            <h1 className="font-arimo text-2xl mx-4">All Tags</h1>
            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <table className="font-arimo w-full bg-transparent overflow-x-scroll lg:overflow-x-hidden">
                {tags?.map((tag, index) => (
                  <tr
                    className={index % 2 !== 0 ? "flex" : "bg-blueGray-50 flex"}
                    key={index}
                  >
                    <th
                      className={
                        "lg:w-3/12 md:w-3/12 px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left font-bold"
                      }
                    >
                      {tag.key}
                    </th>
                    <td className="font-arimo flex-1 px-6 odd:bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-sm border-l-0 border-r-0 whitespace-wrap font-semibold text-left">
                      {tag.value}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>

          <div className="font-arimo pt-20">
            <h1 className="font-arimo text-2xl">Headings</h1>
            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                H1 ( {headings?.h1?.length} )
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {headings?.h1 &&
                  headings?.h1.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="w-full font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        {index + 1} : {value}
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                H2 ({headings?.h2?.length})
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {headings?.h2 &&
                  headings?.h2.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        {index + 1} : {value}
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                H3 ({headings?.h3?.length})
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {headings?.h3 &&
                  headings?.h3.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        {index + 1} : {value}
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                H4 ({headings?.h4?.length})
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {headings?.h4 &&
                  headings?.h4.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        {index + 1} : {value}
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                H5 ({headings?.h5?.length})
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {headings?.h5 &&
                  headings?.h5.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        {index + 1} : {value}
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                H6 ({headings?.h6?.length})
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {headings?.h6 &&
                  headings?.h6.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        {index + 1} : {value}
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">Images ({images?.length})</h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {images &&
                  images?.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left cursor-pointer">
                        <a href={value} target="_blank">
                          {index + 1} :{" "}
                          {value?.length > 50
                            ? value.substring(0, 100) + "....."
                            : value}
                        </a>
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                Internal Url ({internalLinks?.length})
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {internalLinks &&
                  internalLinks?.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        <a href={value} target="_blank">
                          {index + 1} :{" "}
                          {value?.length > 50
                            ? value.substring(0, 100) + "....."
                            : value}
                        </a>
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                External Url ({externalLinks?.length})
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {externalLinks &&
                  externalLinks?.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        <a href={value} target="_blank">
                          {index + 1} :{" "}
                          {value?.length > 40
                            ? value.substring(0, 100) + "....."
                            : value}
                        </a>
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">Favicon</h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                <tr>
                  <th className="font-arimo px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left mt-1">
                    <img src={favIcon} height="100" width="100" />
                  </th>
                </tr>
              </table>
            </div>

            <div className="font-arimo block w-full pt-20">
              <h2 className="font-arimo text-2xl">
                Words Per Page ({wordsPerPage})
              </h2>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                Most Common Words ({commonOneWords?.length})
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {commonOneWords &&
                  commonOneWords?.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        <a href={value} target="_blank">
                          {index + 1} : {value}
                        </a>
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                Most Common Two Words ({commonTwoWords?.length})
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {commonTwoWords &&
                  commonTwoWords?.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        <a href={value} target="_blank">
                          {index + 1} : {value}
                        </a>
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                Most Common Three Words ({commonThreeWords?.length})
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {commonThreeWords &&
                  commonThreeWords?.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        <a href={value} target="_blank">
                          {index + 1} : {value}
                        </a>
                      </th>
                    </tr>
                  ))}
              </table>
            </div>

            <div className="font-arimo block w-full pt-20 overflow-x-scroll lg:overflow-x-hidden">
              <h2 className="font-arimo text-2xl">
                Scripts ({scripts?.length})
              </h2>
              <table className="font-arimo w-full bg-transparent pt-10 overflow-x-scroll lg:overflow-x-hidden">
                {scripts &&
                  scripts?.map((value, index) => (
                    <tr
                      className={index % 2 !== 0 ? " " : "bg-blueGray-50"}
                      key={index}
                    >
                      <th className="font-arimo px-6 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        <a href={value} target="_blank">
                          {index + 1} :{" "}
                          {value?.length > 40
                            ? value.substring(0, 100) + "....."
                            : value}
                        </a>
                      </th>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
        </div>
      )}

      {!loading && !status && (
        <PageNotFound>Please, generate report.</PageNotFound>
      )}
    </>
  );
}
