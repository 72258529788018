import React from "react";
import Joi from "joi";
import toast from "react-hot-toast";

import { completeSignup } from "services/auth";

import { useAuth } from "contexts/auth-context";

import Button from "components/Shared/Button";

import { store, session } from "constant/constant";

const schema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  password: Joi.string().required(),
  email: Joi.string().required(),
});

const SignupModal = ({ showModal, hideModal }) => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [clientError, setClientError] = React.useState("");
  const authContext = useAuth();
  const userId = useAuth()?.state.userId;

  const handleSubmit = async () => {
    let validator = schema.validate({
      firstName,
      lastName,
      password,
      email,
    });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setLoading(true);
      await completeSignup({ firstName, lastName, email, password, userId })
        .then(async (response) => {
          if (response.status === false) {
            toast.error(response.message);
            setLoading(false);
          } else {
            toast.success(response?.message);
            setLoading(false);
            hideModal();
            let user = response.data;
            user.session = session.authenticated;
            localStorage.removeItem(store.session);
            localStorage.setItem(store.session, JSON.stringify(user));
            authContext.dispatch({
              type: authContext.actions.SET,
              data: {
                role: user.role,
                authToken: user.token,
                userId: user.user_id,
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                status: session.authenticated,
                signupCompleted: user.sign_up,
                emailVerified: user.email_verified,
                signupDate: user.signed_up_date,
              },
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error);
        });
    }
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">SignUp</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={hideModal}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <form>
                  <div className="relative p-6 flex-auto">
                    <div className="flex-auto">
                      <div className="flex flex-wrap -mx-4 justify-center">
                        <div className="px-4 relative w-full  md:w-12/12  ">
                          <div className="mb-3 pt-0 relative">
                            <label className="block  text-blueGray-500 text-xs font-bold mb-2 ml-1">
                              First Name
                            </label>
                            <div className="relative  mb-3">
                              <input
                                placeholder="Enter your First Name"
                                type="text"
                                name="first_name"
                                onChange={(e) => setFirstName(e.target.value)}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              />
                              {clientError &&
                                clientError?.context?.key === "firstName" && (
                                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {clientError?.message}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-4 justify-center">
                        <div className="px-4 relative w-full  md:w-12/12  ">
                          <div className="mb-3 pt-0 relative">
                            <label className="block  text-blueGray-500 text-xs font-bold mb-2 ml-1">
                              Last Name
                            </label>
                            <div className="relative  mb-3">
                              <input
                                placeholder="Enter your Last Name"
                                type="text"
                                name="last_name"
                                onChange={(e) => setLastName(e.target.value)}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              />
                              {clientError &&
                                clientError?.context?.key === "lastName" && (
                                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {clientError?.message}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-4 justify-center">
                        <div className="px-4 relative w-full  md:w-12/12  ">
                          <div className="mb-3 pt-0 relative">
                            <label className="block  text-blueGray-500 text-xs font-bold mb-2 ml-1">
                              Email
                            </label>
                            <div className="relative mb-3">
                              <input
                                placeholder="Enter your email"
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              />
                              {clientError &&
                                clientError?.context?.key === "email" && (
                                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {clientError?.message}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-4 justify-center">
                        <div className="px-4 relative w-full  md:w-12/12  ">
                          <div className="mb-3 pt-0 relative">
                            <label className="block  text-blueGray-500 text-xs font-bold mb-2 ml-1">
                              Password
                            </label>
                            <div className="relative mb-3">
                              <input
                                placeholder="Enter your Password"
                                type="password"
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              />
                              {clientError &&
                                clientError?.context?.key === "password" && (
                                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {clientError?.message}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <Button
                      onClick={handleSubmit}
                      loading={loading}
                      text={"Sign Up"}
                      classes={
                        "bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      Submit
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default SignupModal;
