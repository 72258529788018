import React, { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { useAuth } from "contexts/auth-context";

// components
import PercentageCircularBar from "components/Shared/PercentageCircularBar";
import toast from "react-hot-toast";
import Skeleton from "components/Shared/Skeleton";
import ProjectCardContent from "components/Cards/ProjectCardContent";
import PageNotFound from "components/Shared/PageNotFound";

/**alert import */
import PercentageProgressBar from "components/Shared/PercentageProgressBar";
import { viewDetail } from "services/project";
import ProjectDetailTable from "./Tables/ProjectDetailTable";
/** hooks import */

import useAccountMiddleware from "hooks/useAccountMiddleware";

export default function ProjectPageSpeedCard({ project }) {
  const authContext = useAuth();
  const history = useHistory();
  const [averageResponseTime, setAverageResponseTime] = useState(0);
  const [isSignUpCompleted, isPlanSubscribed, plan] = useAccountMiddleware();
  const [image, setImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [desktopPageLoadTime, setDesktopPageLoadTime] = useState(null);
  const [desktopPerformance, setDesktopPerformance] = useState(null);
  const [desktopAuditCount, setDesktopAuditCount] = useState(null);
  const [desktopTotalTimeBlocking, setDesktopTotalTimeBlocking] =
    useState(null);
  const [desktopLargestContentfullPaint, setDesktopLargestContentfullPaint] =
    useState(null);
  const [desktopCumulativeShiftLayout, setDesktopCumulativeShiftLayout] =
    useState(null);
  const [desktopFirstContentfullPaint, setDesktopFirstContentfullPaint] =
    useState(null);
  const [desktopSpeedIndex, setDesktopSpeedIndex] = useState(null);
  const [desktopInteractive, setDesktopInteractive] = useState(null);
  const [errorToFixCount, setErrorToFixCount] = useState(0);
  const [desktopResourceSummary, setDesktopResourceSummary] = useState({});
  const [resourceSummaryItems, setResourceSummaryItems] = useState([]);
  const [desktopTotalByteWeight, setDesktopTotalByteWeight] = useState({});
  const [totalByteWeightItems, setTotalByteWeightItems] = useState([]);
  const [fontDisplay, setFontDisplay] = useState({});
  const [fontDisplayItems, setFontDisplayItems] = useState([]);
  const [usesPassiveEventListeners, setUsesPassiveEventListeners] = useState(
    {}
  );
  const [usesPassiveEventListenersItems, setUsesPassiveEventListenersItems] =
    useState([]);
  const [noDocumentWrite, setNoDocumentWrite] = useState({});
  const [noDocumentWriteItems, setNoDocumentWriteItems] = useState([]);
  const [unsizedImages, setUnsizedImages] = useState({});
  const [unsizedImagesItems, setUnsizedImagesItems] = useState([]);
  const [largestContentfulPaintElement, setLargestContentfulPaintElement] =
    useState({});
  const [
    largestContentfulPaintElementItems,
    setLargestContentfulPaintElementItems,
  ] = useState([]);
  const [layoutShiftElements, setLayoutShiftElements] = useState({});
  const [layoutShiftElementsItems, setLayoutShiftElementsItems] = useState([]);
  const [longTasks, setLongTasks] = useState({});
  const [longTasksItems, setLongTasksItems] = useState([]);
  const [offscreenImages, setOffscreenImages] = useState({});
  const [offscreenImagesItems, setOffscreenImagesItems] = useState({});
  const [currentProject, setCurrentProject] = useState();

  //mobile starts

  const [mobilePageLoadTime, setMobilePageLoadTime] = useState(null);
  const [mobilePerformance, setMobilePerformance] = useState(null);
  const [mobileAuditCount, setMobileAuditCount] = useState(null);
  const [mobileTotalTimeBlocking, setMobileTotalTimeBlocking] = useState(null);
  const [mobileLargestContentfullPaint, setMobileLargestContentfullPaint] =
    useState(null);
  const [mobileCumulativeShiftLayout, setMobileCumulativeShiftLayout] =
    useState(null);
  const [mobileFirstContentfullPaint, setMobileFirstContentfullPaint] =
    useState(null);
  const [mobileSpeedIndex, setMobileSpeedIndex] = useState(null);
  const [mobileInteractive, setMobileInteractive] = useState(null);
  const [mobileErrorToFixCount, setMobileErrorToFixCount] = useState(0);
  const [mobileResourceSummary, setMobileResourceSummary] = useState({});
  const [mobileresourceSummaryItems, setMobileResourceSummaryItems] = useState(
    []
  );
  const [mobileTotalByteWeight, setMobileTotalByteWeight] = useState({});
  const [mobileTotalByteWeightItems, setMobileTotalByteWeightItems] = useState(
    []
  );
  const [mobileFontDisplay, setMobileFontDisplay] = useState({});
  const [mobileFontDisplayItems, setMobileFontDisplayItems] = useState([]);
  const [mobileUsesPassiveEventListeners, setMobileUsesPassiveEventListeners] =
    useState({});
  const [
    mobileUsesPassiveEventListenersItems,
    setMobileUsesPassiveEventListenersItems,
  ] = useState([]);
  const [mobileNoDocumentWrite, setMobileNoDocumentWrite] = useState({});
  const [mobileNoDocumentWriteItems, setMobileNoDocumentWriteItems] = useState(
    []
  );
  const [mobileUnsizedImages, setMobileUnsizedImages] = useState({});
  const [mobileUnsizedImagesItems, setMobileUnsizedImagesItems] = useState([]);
  const [
    mobileLargestContentfulPaintElement,
    setMobileLargestContentfulPaintElement,
  ] = useState({});
  const [
    mobileLargestContentfulPaintElementItems,
    setMobileLargestContentfulPaintElementItems,
  ] = useState([]);
  const [mobileLayoutShiftElements, setMobileLayoutShiftElements] = useState(
    {}
  );
  const [mobileLayoutShiftElementsItems, setMobileLayoutShiftElementsItems] =
    useState([]);
  const [mobileLongTasks, setMobileLongTasks] = useState({});
  const [mobileLongTasksItems, setMobileLongTasksItems] = useState([]);
  const [mobileOffscreenImages, setMobileOffscreenImages] = useState({});
  const [mobileOffscreenImagesItems, setMobileOffscreenImagesItems] = useState(
    {}
  );
  const [mobileUnminifiedCss, setMobileUnminifiedCss] = useState({});
  const [mobileUnminifiedCssItems, setMobileUnminifiedCssItems] = useState([]);
  const [mobileUnminifiedJavascript, setMobileUnminifiedJavascript] = useState(
    {}
  );
  const [
    mobileUnminifiedJavascriptDetails,
    setMobileUnminifiedJavascriptDetails,
  ] = useState([]);
  const [mobileUsesOptimizedImages, setMobileUsesOptimizedImages] = useState(
    {}
  );
  const [
    mobileUsesOptimizedImagesDetails,
    setMobileUsesOptimizedImagesDetails,
  ] = useState([]);
  const [mobileModernImageFormats, setMobileModernImageFormats] = useState({});
  const [mobileModernImageFormatsDetails, setMobileModernImageFormatsDetails] =
    useState({});
  const [mobileUsesTextCompression, setMobileUsesTextCompression] = useState(
    {}
  );
  const [mobileUsesTextCompressionItems, setMobileUsesTextCompressionItems] =
    useState([]);
  const [mobileUsesRelPreconnect, setMobileUsesRelPreconnect] = useState({});
  const [mobileUsesRelPreconnectItems, setMobileUsesRelPreconnectItems] =
    useState([]);
  const [mobileServerResponseTime, setMobileServerResponseTime] = useState({});
  const [mobileServerResponseTimeDetails, setMobileServerResponseTimeDetails] =
    useState([]);
  const [mobileRedirects, setMobileRedirects] = useState({});
  const [mobileRedirectsitems, setMobileRedirectsitems] = useState([]);
  const [mobileEfficientAnimatedContent, setMobileEfficientAnimatedContent] =
    useState({});
  const [
    mobileEfficientAnimatedContentItems,
    setMobileEfficientAnimatedContentItems,
  ] = useState([]);
  const [mobileDuplicatedJavascript, setMobileDuplicatedJavascript] = useState(
    {}
  );
  const [mobileDuplicatedJavascriptItems, setMobileDuplicatedJavascriptItems] =
    useState([]);
  const [mobileLegacyJavascript, setMobileLegacyJavascript] = useState({});
  const [mobileLegacyJavascriptItems, setMobileLegacyJavascriptItems] =
    useState([]);
  const [mobilePreloadLcpiIage, setMobilePreloadLcpiIage] = useState({});
  const [mobilePreloadLcpiIageItems, setMobilePreloadLcpiIageItems] = useState(
    []
  );
  const [status, setStatus] = useState(false);

  //mobile ends

  let myInterval = null;

  function myprogress() {
    setAverageResponseTime((preState) => {
      if (preState < 80) {
        return preState + 20;
      } else {
        return preState;
      }
    });
  }

  const fetchProjectDetail = async () => {
    setAverageResponseTime(0);
    myInterval = setInterval(() => {
      myprogress();
    }, 5000);
    setLoading(true);
    await viewDetail(project, authContext?.state?.authToken)
      .then((response) => {
        if (response.desktopStats && response.mobileStats) {
          setAverageResponseTime(100);
          setLoading(false);
          clearInterval(myInterval);
          setStatus(true);
          setImage(response.desktopStats?.fullImage);
          setDesktopPageLoadTime(response.desktopStats?.desktopPageLoadTime);
          setDesktopPerformance(
            Math.floor(response.desktopStats?.desktopPerformance)
          );
          setDesktopAuditCount(response.desktopStats?.successfullAuditsCount);
          setDesktopTotalTimeBlocking(
            response.desktopStats?.desktopTotalTimeBlocking
          );
          setDesktopLargestContentfullPaint(
            response.desktopStats?.desktopLargestContentfullPaint
          );
          setDesktopCumulativeShiftLayout(
            response.desktopStats?.desktopCumulativeShiftLayout
          );
          setDesktopFirstContentfullPaint(
            response.desktopStats?.desktopFirstContentfullPaint
          );
          setDesktopSpeedIndex(response.desktopStats?.desktopSpeedIndex);
          setDesktopInteractive(response.desktopStats?.desktopInteractive);
          setErrorToFixCount(
            response.desktopStats?.categories?.performance?.auditRefs.length
          );
          setDesktopResourceSummary(response.desktopStats?.resourceSummary);
          setResourceSummaryItems(response.desktopStats?.resourceSummaryItems);
          setDesktopTotalByteWeight(response.desktopStats?.totalByteWeight);
          setTotalByteWeightItems(response.desktopStats?.totalByteWeightItems);
          setFontDisplay(response.desktopStats?.fontDisplay);
          setFontDisplayItems(response.desktopStats?.fontDisplayItems);
          setUsesPassiveEventListeners(
            response.desktopStats?.usesPassiveEventListeners
          );
          setUsesPassiveEventListenersItems(
            response.desktopStats?.usesPassiveEventListenersItems
          );
          setNoDocumentWrite(response.desktopStats?.noDocumentWrite);
          setNoDocumentWriteItems(response.desktopStats?.noDocumentWriteItems);
          setUnsizedImages(response.desktopStats?.unsizedImages);
          setUnsizedImagesItems(response.desktopStats?.unsizedImagesItems);
          setLargestContentfulPaintElement(
            response.desktopStats?.largestContentfulPaintElement
          );
          setLargestContentfulPaintElementItems(
            response.desktopStats?.largestContentfulPaintElementItems
          );
          setLayoutShiftElements(response.desktopStats?.layoutShiftElements);
          setLayoutShiftElementsItems(
            response.desktopStats?.layoutShiftElementsItems
          );
          setLongTasks(response.desktopStats?.longTasks);
          setLongTasksItems(response.desktopStats?.longTasksItems);
          setOffscreenImages(response.desktopStats?.offscreenImages);
          setOffscreenImagesItems(response.desktopStats?.offscreenImagesItems);
          setCurrentProject(response.desktopStats?.project);

          //mobile starts

          setMobileImage(response.mobileStats?.mobileImage);
          setMobilePageLoadTime(response.mobileStats?.mobilePageLoadTime);
          setMobilePerformance(
            Math.floor(response.mobileStats?.mobilePerformance)
          );
          setMobileAuditCount(
            response.mobileStats?.mobileSuccessfullAuditsCount
          );
          setMobileTotalTimeBlocking(
            response.mobileStats?.mobileTotalTimeBlocking
          );
          setMobileLargestContentfullPaint(
            response.mobileStats?.mobileLargestContentfullPaint
          );
          setMobileCumulativeShiftLayout(
            response.mobileStats?.mobileCumulativeShiftLayout
          );
          setMobileFirstContentfullPaint(
            response.mobileStats?.mobileFirstContentfullPaint
          );
          setMobileSpeedIndex(response.mobileStats?.mobileSpeedIndex);
          setMobileInteractive(response.mobileStats?.mobileInteractive);
          setMobileErrorToFixCount(
            response.mobileStats?.mobileCategories?.performance?.auditRefs
              .length
          );
          setMobileResourceSummary(response.mobileStats.mobileResourceSummary);
          setMobileResourceSummaryItems(
            response.mobileStats?.mobileResourceSummaryItems
          );
          setMobileTotalByteWeight(response.mobileStats?.mobileTotalByteWeight);
          setMobileTotalByteWeightItems(
            response.mobileStats?.mobileTotalByteWeightItems
          );
          setMobileFontDisplay(response.mobileStats?.mobileFontDisplay);
          setMobileFontDisplayItems(
            response.mobileStats?.mobileFontDisplayItems
          );
          setMobileUsesPassiveEventListeners(
            response.mobileStats?.mobileUsesPassiveEventListeners
          );
          setMobileUsesPassiveEventListenersItems(
            response.mobileStats?.mobileUsesPassiveEventListenersItems
          );
          setMobileNoDocumentWrite(response.mobileStats?.mobileNoDocumentWrite);
          setMobileNoDocumentWriteItems(
            response.mobileStats?.mobileNoDocumentWriteItems
          );
          setMobileUnsizedImages(response.mobileStats?.mobileUnsizedImages);
          setMobileUnsizedImagesItems(
            response.mobileStats?.mobileUnsizedImagesItems
          );
          setMobileLargestContentfulPaintElement(
            response.mobileStats?.mobileLargestContentfulPaintElement
          );
          setMobileLargestContentfulPaintElementItems(
            response.mobileStats?.mobileLargestContentfulPaintElementItems
          );
          setMobileLayoutShiftElements(
            response.mobileStats?.modernImageFormats
          );
          setMobileLayoutShiftElementsItems(
            response.mobileStats?.mobileLayoutShiftElementsItems
          );
          setMobileLongTasks(response.mobileStats?.mobileLongTasks);
          setMobileLongTasksItems(response.mobileStats?.mobileLongTasksItems);
          setMobileOffscreenImages(response.mobileStats?.offscreenImages);
          setMobileOffscreenImagesItems(
            response.mobileStats?.offscreenImagesItems
          );
          setMobileUnminifiedCss(response.mobileStats?.unminifiedCss);
          setMobileUnminifiedJavascript(
            response.mobileStats?.unminifiedJavascript
          );
          setMobileUnminifiedJavascriptDetails(
            response.mobileStats?.unminifiedJavascriptDetails
          );
          setMobileUnminifiedCssItems(response.mobileStats?.unminifiedCssItems);
          setMobileUsesOptimizedImages(
            response.mobileStats?.usesOptimizedImages
          );
          setMobileUsesOptimizedImagesDetails(
            response.mobileStats?.usesOptimizedImagesDetails
          );
          setMobileModernImageFormats(response.mobileStats?.modernImageFormats);
          setMobileModernImageFormatsDetails(
            response.mobileStats?.modernImageFormatsDetails
          );
          setMobileUsesTextCompression(
            response.mobileStats?.usesTextCompression
          );
          setMobileUsesTextCompressionItems(
            response.mobileStats?.usesTextCompressionItems
          );
          setMobileUsesRelPreconnect(response.mobileStats?.usesRelPreconnect);
          setMobileUsesRelPreconnectItems(
            response.mobileStats?.usesRelPreconnectItems
          );
          setMobileServerResponseTime(response.mobileStats?.serverResponseTime);
          setMobileServerResponseTimeDetails(
            response.mobileStats?.serverResponseTimeDetails
          );
          setMobileRedirects(response.mobileStats?.redirects);
          setMobileRedirectsitems(response.mobileStats?.redirectsitems);
          setMobileEfficientAnimatedContent(
            response.mobileStats?.efficientAnimatedContent
          );
          setMobileEfficientAnimatedContentItems(
            response.mobileStats?.efficientAnimatedContentItems
          );
          setMobileDuplicatedJavascript(
            response.mobileStats?.duplicatedJavascript
          );
          setMobileDuplicatedJavascriptItems(
            response.mobileStats?.duplicatedJavascriptItems
          );
          setMobileLegacyJavascript(response.mobileStats?.legacyJavascript);
          setMobileLegacyJavascriptItems(
            response.mobileStats?.legacyJavascriptItems
          );
          setMobilePreloadLcpiIage(response.mobileStats?.preloadLcpiIage);
          setMobilePreloadLcpiIageItems(
            response.mobileStats?.preloadLcpiIageItems
          );
          //mobile ends
        } else {
          setLoading(false);
          setStatus(false);
          setAverageResponseTime(0);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAverageResponseTime(0);
        toast.error(error);
        setStatus(false);
      });
  };

  useEffect(() => {
    isPlanSubscribed();
    isSignUpCompleted();
    if (project === "null") {
      toast.error("You have not generated a project report.");
    } else {
      fetchProjectDetail();
    }
  }, []);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0 py-10 overflow-x-hidden lg:overflow-x-auto">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between flex-col lg:flex-row">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Project Page Speed Detail
            </h6>
            <Link
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 mt-5 lg:mt-0"
              to="/app/dashboard"
            >
              Go To homepage
            </Link>
          </div>

          <div className="mt-10">
            <div className="flex flex-col justify-center items-center">
              {loading ? (
                <PercentageProgressBar
                  percentage={averageResponseTime}
                ></PercentageProgressBar>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && <Skeleton />}
      {!loading && status && (
        <div className="font-arimo bg-white  pt-20 pl-4 pr-4 pb-20">
          <div className="font-arimo flex justify-between pt-4 pb-4">
            <div>Project: {currentProject}</div>
            <div>Desktop Audits</div>
          </div>
          <div className="font-arimo flex flex-col lg:flex-row">
            <div className="font-arimo w-full lg:w-6/12 xl:w-4/12 px-4">
              <PercentageCircularBar percentage={desktopPerformance} />
            </div>
            <div className="font-arimo w-full lg:w-6/12 xl:w-4/12 px-4 mt-5 lg:mt-0">
              <div className="font-arimo border-2 border-grey-300 rounded py-5 px-4 pt-5">
                <h1 className="font-arimo font-bold">Page Load Time</h1>
                <p>{desktopPageLoadTime}</p>
              </div>
              <div className="font-arimo border-2 border-grey-300 rounded py-5 px-4 mt-5">
                <h1 className="font-arimo text-teal-500 font-bold">
                  Successful Audits
                </h1>
                <p>{desktopAuditCount}</p>
              </div>
              <div className="font-arimo border-2 border-grey-300 rounded py-5 px-4  mt-5">
                <h1 className="font-arimo text-red-500 font-bold">
                  Errors To Fix
                </h1>
                <p>{errorToFixCount}</p>
              </div>
            </div>
            <div className="font-arimo w-full lg:w-6/12 xl:w-4/12 px-4 mt-5 lg:mt-0 flex justify-center">
              <img src={image} alt="" height="300" width="300" />
            </div>
          </div>
          <div className="font-arimo pt-20 w-full break-words">
            <h1 className="font-arimo text-2xl">Webpage loading emulation</h1>
            <p className="font-arimo mt-5 w-full break-words">
              The following metrics are obtained by emulating the webpage
              loading process in a desktop browser and a mobile browser. They
              show how user-friendly your webpage is and how well it is
              optimized for Google SERP promotion.{" "}
            </p>
          </div>
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex flex-wrap pt-5">
              <ProjectCardContent
                title="Total time blocking"
                description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                textClass="text-red-500"
                stats={desktopTotalTimeBlocking}
              />

              <ProjectCardContent
                title="Largest Contentful Paint"
                description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                textClass="text-red-500"
                stats={desktopLargestContentfullPaint}
              />
            </div>

            <div className="font-arimo flex flex-wrap mt-4">
              <ProjectCardContent
                title="Cumulative Layout Shift"
                stats={desktopCumulativeShiftLayout}
                description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                textClass="text-red-500"
              />
              <ProjectCardContent
                title="First Content Paint"
                description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                textClass="text-red-500"
                stats={desktopFirstContentfullPaint}
              />
            </div>

            <div className="font-arimo flex flex-wrap mt-4">
              <ProjectCardContent
                title="Speed Index"
                description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                textClass="text-green-500"
                stats={desktopSpeedIndex}
              />
              <ProjectCardContent
                title="Time to Interative"
                description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                textClass="text-green-500"
                stats={desktopInteractive}
              />
            </div>
          </div>
          <div className="font-arimo pt-20 w-full break-words">
            <h1 className="font-arimo text-2xl text-red-500 font-bold">
              Errors To Fix
            </h1>
            <p className="font-arimo mt-5">
              The following metrics show how faster your webpage can be loaded
              (improvements are calculated in seconds) if you follow the
              recommendations. Fix these errors to attract more visitors to your
              website{" "}
            </p>
          </div>

          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-red-500">
                {desktopResourceSummary.title}
              </h1>
              <p className="font-arimo text-md text-red-500">
                {desktopResourceSummary.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5 break-words">
              {desktopResourceSummary.description}
            </p>
          </div>
          <ProjectDetailTable items={resourceSummaryItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-red-500">
                {desktopTotalByteWeight?.title}
              </h1>
              <p className="font-arimo text-md text-red-500">
                {desktopTotalByteWeight?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">
              {desktopTotalByteWeight?.description}
            </p>
          </div>

          <ProjectDetailTable items={totalByteWeightItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-red-500">
                {fontDisplay?.title}
              </h1>
              <p className="font-arimo text-md text-red-500">
                {fontDisplay?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">{fontDisplay?.description}</p>
          </div>
          <ProjectDetailTable items={fontDisplayItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-red-500">
                {usesPassiveEventListeners?.title}
              </h1>
              <p className="font-arimo text-md text-red-500">
                {usesPassiveEventListeners?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">
              {usesPassiveEventListeners?.description}
            </p>
          </div>
          <ProjectDetailTable items={usesPassiveEventListenersItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-red-500">
                {noDocumentWrite?.title}
              </h1>
              <p className="font-arimo text-md text-red-500">
                {noDocumentWrite.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">{noDocumentWrite?.description}</p>
          </div>
          <ProjectDetailTable items={noDocumentWriteItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-red-500">
                {unsizedImages?.title}
              </h1>
              <p className="font-arimo text-md text-red-500">
                {unsizedImages?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">{unsizedImages?.description}</p>
          </div>
          <ProjectDetailTable items={unsizedImagesItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-red-500">
                {largestContentfulPaintElement?.title}
              </h1>
              <p className="font-arimo text-md text-red-500">
                {largestContentfulPaintElement?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">
              {largestContentfulPaintElement?.description}
            </p>
          </div>
          <ProjectDetailTable items={largestContentfulPaintElementItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-red-500">
                {layoutShiftElements?.title}
              </h1>
              <p className="font-arimo text-md text-red-500">
                {layoutShiftElements?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">
              {layoutShiftElements?.description}
            </p>
          </div>
          <ProjectDetailTable items={layoutShiftElementsItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-red-500">
                {longTasks?.title}
              </h1>
              <p className="font-arimo text-md text-red-500">
                {longTasks?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">{longTasks?.description}</p>
          </div>
          <ProjectDetailTable items={longTasksItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <h1 className="font-arimo text-2xl text-teal-500 font-bold">
              Success Full Audits dfdfs
            </h1>
            <p className="font-arimo mt-5">
              The audit results are satisfactory for the optimum web page
              performance. It’s recommended that you regularly analyze the speed
              of your main web pages and improve their metrics to attract more
              visitors and increase your website’s conversion rate.
            </p>
          </div>
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-teal-500 ">
                {offscreenImages.title}
              </h1>
              <p className="font-arimo text-md text-teal-500">
                {offscreenImages.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">{offscreenImages?.description}</p>
          </div>
          <ProjectDetailTable items={offscreenImagesItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-teal-500">
                {desktopTotalByteWeight?.title}
              </h1>
              <p className="font-arimo text-md text-teal-500">
                {desktopTotalByteWeight?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">
              {desktopTotalByteWeight?.description}
            </p>
          </div>
          <ProjectDetailTable items={totalByteWeightItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-teal-500">
                {fontDisplay?.title}
              </h1>
              <p className="font-arimo text-md text-teal-500">
                {fontDisplay?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">{fontDisplay?.description}</p>
          </div>
          <ProjectDetailTable items={fontDisplayItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-teal-500">
                {usesPassiveEventListeners?.title}
              </h1>
              <p className="font-arimo text-md text-teal-500">
                {usesPassiveEventListeners?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">
              {usesPassiveEventListeners?.description}
            </p>
          </div>
          <ProjectDetailTable items={usesPassiveEventListenersItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-teal-500">
                {noDocumentWrite?.title}
              </h1>
              <p className="font-arimo text-md text-teal-500">
                {noDocumentWrite?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">{noDocumentWrite?.description}</p>
          </div>
          <ProjectDetailTable items={noDocumentWriteItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-teal-500">
                {unsizedImages?.title}
              </h1>
              <p className="font-arimo text-md text-teal-500">
                {unsizedImages?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">{unsizedImages?.description}</p>
          </div>
          <ProjectDetailTable items={unsizedImagesItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-teal-500">
                {largestContentfulPaintElement?.title}
              </h1>
              <p className="font-arimo text-md text-teal-500">
                {largestContentfulPaintElement?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">
              {largestContentfulPaintElement?.description}
            </p>
          </div>
          <ProjectDetailTable items={largestContentfulPaintElementItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-teal-500">
                {layoutShiftElements?.title}
              </h1>
              <p className="font-arimo text-md text-teal-500">
                {layoutShiftElements?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">
              {layoutShiftElements?.description}
            </p>
          </div>
          <ProjectDetailTable items={layoutShiftElementsItems} />
          <div className="font-arimo pt-20 w-full break-words">
            <div className="font-arimo flex justify-between">
              <h1 className="font-arimo text-2xl text-teal-500">
                {longTasks?.title}
              </h1>
              <p className="font-arimo text-md text-teal-500">
                {longTasks?.displayValue}
              </p>
            </div>
            <p className="font-arimo mt-5">{longTasks?.description}</p>
          </div>
          <ProjectDetailTable items={longTasksItems} />

          <div className="font-arimo pt-20">
            <div className="font-arimo flex justify-between pt-4 pb-4">
              <div>Project: {currentProject}</div>
              <div>Mobile Audits</div>
            </div>
            <div className="font-arimo flex flex-col lg:flex-row">
              <div className="font-arimo w-full lg:w-6/12 xl:w-4/12 px-4">
                <PercentageCircularBar percentage={mobilePerformance} />
              </div>
              <div className="font-arimo w-full lg:w-6/12 xl:w-4/12 px-4 mt-5 lg:mt-0">
                <div className="font-arimo border-2 border-grey-300 rounded py-5 px-4 pt-5">
                  <h3>Page Load Time</h3>
                  <p>{mobilePageLoadTime}</p>
                </div>
                <div className="font-arimo border-2 border-grey-300 rounded py-5 px-4 mt-5">
                  <h1 className="font-arimo text-teal-500">
                    Successful Audits
                  </h1>
                  <p>{mobileAuditCount}</p>
                </div>
                <div className="font-arimo border-2 border-grey-300 rounded py-5 px-4  mt-5">
                  <h1 className="font-arimo text-red-500">Errors To Fix</h1>
                  <p>{mobileErrorToFixCount}</p>
                </div>
              </div>
              <div className="font-arimo w-full lg:w-6/12 xl:w-4/12 px-4 mt-5 lg:mt-0 flex justify-center">
                <img
                  src={mobileImage}
                  alt=""
                  style={{ height: "300", width: "300" }}
                />
              </div>
            </div>
            <div className="font-arimo pt-20">
              <h1 className="font-arimo text-2xl">Webpage loading emulation</h1>
              <p className="font-arimo mt-5">
                The following metrics are obtained by emulating the webpage
                loading process in a desktop browser and a mobile browser. They
                show how user-friendly your webpage is and how well it is
                optimized for Google SERP promotion.{" "}
              </p>
            </div>
            <div className="font-arimo pt-20">
              <div className="font-arimo flex flex-wrap pt-5">
                <ProjectCardContent
                  title="Total time blocking"
                  description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                  textClass="text-red-500"
                  stats={mobileTotalTimeBlocking}
                />

                <ProjectCardContent
                  title="Largest Contentful Paint"
                  description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                  textClass="text-red-500"
                  stats={mobileLargestContentfullPaint}
                />
              </div>

              <div className="font-arimo flex flex-wrap mt-4">
                <ProjectCardContent
                  title="Cumulative Layout Shift"
                  stats={mobileCumulativeShiftLayout}
                  description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                  textClass="text-red-500"
                />
                <ProjectCardContent
                  title="First Content Paint"
                  description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                  textClass="text-red-500"
                  stats={mobileFirstContentfullPaint}
                />
              </div>

              <div className="font-arimo flex flex-wrap mt-4">
                <ProjectCardContent
                  title="Speed Index"
                  description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                  textClass="text-red-500"
                  stats={mobileSpeedIndex}
                />
                <ProjectCardContent
                  title="Time to Interative"
                  description="Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds."
                  textClass="text-red-500"
                  stats={mobileInteractive}
                />
              </div>
            </div>
            <div className="font-arimo pt-20">
              <h1 className="font-arimo text-2xl text-red-500 font-bold">
                Errors To Fix
              </h1>
              <p className="font-arimo mt-5">
                The following metrics show how faster your webpage can be loaded
                (improvements are calculated in seconds) if you follow the
                recommendations. Fix these errors to attract more visitors to
                your website{" "}
              </p>
            </div>
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-red-500">
                  {mobileResourceSummary?.title}
                </h1>
                <p className="font-arimo text-md text-red-500">
                  {mobileResourceSummary?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileResourceSummary?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileresourceSummaryItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-red-500">
                  {mobileTotalByteWeight?.title}
                </h1>
                <p className="font-arimo text-md text-red-500">
                  {mobileTotalByteWeight?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileTotalByteWeight?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileTotalByteWeightItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-red-500">
                  {mobileFontDisplay.title}
                </h1>
                <p className="font-arimo text-md text-red-500">
                  {mobileFontDisplay?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileFontDisplay?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileFontDisplayItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-red-500">
                  {mobileUsesPassiveEventListeners?.title}
                </h1>
                <p className="font-arimo text-md text-red-500">
                  {mobileUsesPassiveEventListeners?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileUsesPassiveEventListeners?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileUsesPassiveEventListenersItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-red-500">
                  {mobileNoDocumentWrite?.title}
                </h1>
                <p className="font-arimo text-md text-red-500">
                  {mobileNoDocumentWrite?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileNoDocumentWrite?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileNoDocumentWriteItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-red-500">
                  {mobileUnsizedImages?.title}
                </h1>
                <p className="font-arimo text-md text-red-500">
                  {mobileUnsizedImages?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileUnsizedImages?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileUnsizedImagesItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-red-500">
                  {mobileLargestContentfulPaintElement?.title}
                </h1>
                <p className="font-arimo text-md text-red-500">
                  {mobileLargestContentfulPaintElement?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileLargestContentfulPaintElement?.description}
              </p>
            </div>
            <ProjectDetailTable
              items={mobileLargestContentfulPaintElementItems}
            />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-red-500">
                  {mobileLayoutShiftElements.title}
                </h1>
                <p className="font-arimo text-md text-red-500">
                  {mobileLayoutShiftElements.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileLayoutShiftElements?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileLayoutShiftElementsItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-red-500">
                  {mobileLongTasks?.title}
                </h1>
                <p className="font-arimo text-md text-red-500">
                  {mobileLongTasks?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">{mobileLongTasks?.description}</p>
            </div>
            <ProjectDetailTable items={mobileLongTasksItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <h1 className="font-arimo text-2xl text-teal-500 font-bold">
                Success Full Audits
              </h1>
              <p className="font-arimo mt-5">
                The audit results are satisfactory for the optimum web page
                performance. It’s recommended that you regularly analyze the
                speed of your main web pages and improve their metrics to
                attract more visitors and increase your website’s conversion
                rate.
              </p>
            </div>
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500 ">
                  {mobileOffscreenImages?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileOffscreenImages?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileOffscreenImages?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileOffscreenImagesItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobileUnminifiedCss?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileUnminifiedCss?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileUnminifiedCss?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileUnminifiedCssItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobileUnminifiedJavascript?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileUnminifiedJavascript?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileUnminifiedJavascript?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileUnminifiedJavascriptDetails} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobileUsesOptimizedImages?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileUsesOptimizedImages?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileUsesOptimizedImages?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileUsesOptimizedImagesDetails} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobileModernImageFormats?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileModernImageFormats?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileModernImageFormats?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileModernImageFormatsDetails} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobileUsesTextCompression?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileUsesTextCompression?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileUsesTextCompression?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileUsesTextCompressionItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobileUsesRelPreconnect?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileUsesRelPreconnect?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileUsesRelPreconnect?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileUsesRelPreconnectItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobileServerResponseTime?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileServerResponseTime?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileServerResponseTime?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileServerResponseTimeDetails} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobileRedirects?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileRedirects?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">{mobileRedirects?.description}</p>
            </div>
            <ProjectDetailTable items={mobileRedirectsitems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobileEfficientAnimatedContent?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileEfficientAnimatedContent?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileEfficientAnimatedContent?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileEfficientAnimatedContentItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobileDuplicatedJavascript?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileDuplicatedJavascript?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileDuplicatedJavascript?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileDuplicatedJavascriptItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobileLegacyJavascript?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobileLegacyJavascript?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobileLegacyJavascript?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobileLegacyJavascriptItems} />
            <div className="font-arimo pt-20 w-full break-words">
              <div className="font-arimo flex justify-between">
                <h1 className="font-arimo text-2xl text-teal-500">
                  {mobilePreloadLcpiIage?.title}
                </h1>
                <p className="font-arimo text-md text-teal-500">
                  {mobilePreloadLcpiIage?.displayValue}
                </p>
              </div>
              <p className="font-arimo mt-5">
                {mobilePreloadLcpiIage?.description}
              </p>
            </div>
            <ProjectDetailTable items={mobilePreloadLcpiIageItems} />
          </div>
        </div>
      )}

      {!loading && !status && (
        <PageNotFound>Please, generate report.</PageNotFound>
      )}
    </>
  );
}
