import { currency } from "./constant";
export const products = [
  // {
  //   id: "prod_MfnkxkZA7YEME2",
  //   name: "Free Dashboard",
  //   title: "Free",
  //   description: [
  //     { desc: "Keywords Distribution", included: true },
  //     { desc: "WEB ANALYZER", included: true },
  //     { desc: "Page speed analyzer", included: true },
  //     { desc: "Site Keywords Explorer", included: false },
  //     { desc: "Rank Tracker", included: false },
  //     { desc: "Alerts", included: false },
  //   ],
  //   others: {
  //     projects: 1,
  //     updateFrequency: "weekly",
  //     numberOfKeywordsUpdate: 200,
  //   },
  //   actionTitle: "Subscribe",
  // },
  {
    id: "prod_OKjAu5zxHNRFhT",
    paypalPlanId: "P-5LS78336WU236133VMRXRSWI",
    planId: "plan_1LnmakFKhlPTitAsaRVeuTF",
    priceId: "price_1NY3iNLLHyFwRjDxixLxL5tg",
    name: "Basic Dashboard",
    title: "Basic",
    price: "19",
    duration: "per month",
    currency: currency?.symbol,
    description: [
      { desc: "Full SEO Dashboard", included: true },
      { desc: "WEB ANALYZER", included: true },
      { desc: "Successful Audits", included: true },
      { desc: "Errors", included: true },
      { desc: "Warnings", included: true },
      { desc: "Basic Info", included: true },
      { desc: "PAGE SPEED ANALYZER", included: true },
      { desc: "Web Loading Emulations", included: true },
      { desc: "Error To Fix", included: true },
      { desc: "Successful Audits", included: true },
      { desc: "REPORT CENTER", included: true },
      { desc: "Download Now", included: true },
      { desc: "Schedule Report", included: true },
    ],
    others: {
      projects: 1,
      updateFrequency: "Bi-weekly",
    },
    actionTitle: "Subscribe",
    actionDesc: 'Click and get 30 days "FREE TRIAL"',
  },
  {
    id: "prod_OKjFwf2mL2wEyq",
    paypalPlanId: "P-0GE2861273903471JMRXRUPY",
    priceId: "price_1NY3nGLLHyFwRjDxIoIpFMYL",
    planId: "plan_3LnmakFKhlPTitAsaRVeuTB",
    name: "Standard Dashboard",
    title: "Standard",
    price: "49",
    duration: "per month",
    currency: currency?.symbol,
    description: [
      { desc: "Full SEO Dashboard", included: true },
      { desc: "WEB ANALYZER", included: true },
      { desc: "Successful Audits", included: true },
      { desc: "Errors", included: true },
      { desc: "Warnings", included: true },
      { desc: "Basic Info", included: true },
      { desc: "PAGE SPEED ANALYZER", included: true },
      { desc: "Web Loading Emulations", included: true },
      { desc: "Error To Fix", included: true },
      { desc: "Successful Audits", included: true },
      { desc: "REPORT CENTER", included: true },
      { desc: "Download Now", included: true },
      { desc: "Schedule Report", included: true },
    ],
    others: {
      projects: 3,
      updateFrequency: "Weekly",
    },
    actionTitle: "Subscribe",
  },
  {
    id: "prod_OKjIHcVlqPNRSp",
    paypalPlanId: "P-1WY38190UP344474FMRXRZAQ",
    priceId: "price_1NY3pQLLHyFwRjDxChtGx9WU",
    planId: "plan_uLnmakFKhlPTitAsaRVeuTS",
    name: "Premium Dashboard",
    title: "Premium",
    price: "99",
    duration: "per month",
    currency: currency?.symbol,
    description: [
      { desc: "Full SEO Dashboard", included: true },
      { desc: "WEB ANALYZER", included: true },
      { desc: "Successful Audits", included: true },
      { desc: "Errors", included: true },
      { desc: "Warnings", included: true },
      { desc: "Basic Info", included: true },
      { desc: "PAGE SPEED ANALYZER", included: true },
      { desc: "Web Loading Emulations", included: true },
      { desc: "Error To Fix", included: true },
      { desc: "Successful Audits", included: true },
      { desc: "REPORT CENTER", included: true },
      { desc: "Download Now", included: true },
      { desc: "Schedule Report", included: true },
    ],
    others: {
      projects: "unlimited",
      updateFrequency: "Daily",
    },
    actionTitle: "Subscribe",
  },
];
