export const session = {
  authenticated: "AUTHENTICATED",
  unauthenticated: "UNAUTHENTICATED",
};

export const pages = {
  dashboard: "Dashboard",
  pageSpeed: "Page Speed",
  pageDetail: "Project Detail",
  pricing: "Pricing",
  subscription: "Subscription",
  accountSettings: "Account Settings",
  changePassword: "Change Password",
  invoice: "Invoice",
  checkout: "Checkout",
  BestPages: "Best Pages",
  Competitors: "Competitors",
  Keywords: "Top Keywords",
  reportCenter: "Report Center",
  webAnalyzer: "Web Analyzer",
};

export const contacts = {
  addressLine1: "1345 Avenue of the Americas, 2nd Floor",
  addressLine2: "New York, NY",
  email: "info@marketmediaconnect.com",
  phone: "(201)-479-4906",
};

export const currency = {
  symbol: "$",
  title: "Dollar",
};

export const store = {
  CART: "cart",
  FREE_TRAIL: "free_trail",
  session: "persist",
};
