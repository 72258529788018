/* eslint-disable react/prop-types */
import * as React from "react";

const AppContext = React.createContext();

const defaultAuthState = {
  pageTitle: "",
  showModal:false
};

const actions = {
  UPDATE: "update",
  SET: "set",
};

function appReducer(state, action) {
  switch (action.type) {
    case "update": {
      const updatedState = {
        ...state,
        ...action.data,
      };
      return updatedState;
    }
    case "set": {
      return {
        ...state,
        ...action.data,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AppProvider({ children }) {
  const [state, dispatch] = React.useReducer(appReducer, {
    ...defaultAuthState,
  });

  // TODO: NOTE: we might need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch, actions };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useApp() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
}

export { AppProvider, useApp };
