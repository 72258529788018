import React from "react";

const ProgressBar = ({
  strokeWidth,
  percentage = 0.0,
  isPercentage = true,
  customClass = "",
  customColor = "",
}) => {
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;

  const getBarColor = (range) => {
    if (range >= 90) return "#0c6";
    if (range >= 50 && range < 90) return "#fa3";
    if (range < 50) return "#f33";
  };

  const progressStyle = {
    stroke: customColor ? customColor : getBarColor(percentage),
    strokeLinecap: "round",
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };

  return (
    <svg
      className={`CircularProgressbar ${customClass}`}
      viewBox="0 0 100 100"
      width={100}
      height={100}
    >
      <path
        className="CircularProgressbar-trail"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          stroke: "#d6d6d6",
        }}
      />

      <path
        className="CircularProgressbar-path"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={progressStyle}
      />

      <text
        className="CircularProgressbar-text"
        x={50}
        y={50}
        style={{
          fill: customColor ? customColor : getBarColor(percentage),
          fontSize: "24px",
          dominantBaseline: "central",
          textAnchor: "middle",
        }}
      >
        {`${percentage}`}
        {isPercentage && "%"}
      </text>
    </svg>
  );
};

export default ProgressBar;
