import React, { useEffect } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "components/Shared/CheckoutForm";

/** context import */
import { useApp } from "contexts/app-context";

/**constant import */
import { pages } from "constant/constant";
import { composeTitle } from "utils/helper";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Checkout = () => {
  const appContext = useApp();
  const [plan, setPlan] = React.useState(null);

  useEffect(() => {
    composeTitle(pages?.checkout);
    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.checkout,
      },
    });

    setPlan(localStorage.getItem("plan"));
  }, []);
  return (
    <>
      <Elements stripe={stripePromise}>
        <CheckoutForm plan={plan} />
      </Elements>
    </>
  );
};

export default Checkout;
