import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { createPopper } from "@popperjs/core";
import PropTypes from "prop-types";
import CardDeleteAlertModal from "./CardDeleteAlertModal";
import { deleteProject } from "services/dashboard";
import menu from "assets/img/menu.png";

import { useAuth } from "contexts/auth-context";
import { Link } from "react-router-dom";

export default function CardStats({
  setFilterProjects,
  domain = null,
  projectId = null,
  statSubtitle = null,
  statTitle = null,
  statArrow = null,
  statPercent = null,
  statPercentColor = null,
  statDescripiron = null,
  statIconName = null,
  statIconColor = null,
  performance = null,
  date = null,
  reportId = null,
  analyzerId = null,
}) {
  const [loading, setLoading] = React.useState(false);
  const authToken = useAuth()?.state?.authToken;
  const [showModal, setShowModal] = useState(false);
  const handleOnClose = () => setShowModal(false);
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.useRef([]);
  const popoverDropdownRef = React.useRef([]);
  const [isMounted, setIsMounted] = useState(false);
  const [percentageColor, setPercentageColor] = useState("");

  const color = (performance) => {
    if (performance >= 90) {
      setPercentageColor("text-teal-500");
    }
    if (parseInt(performance) >= 50 && performance < 90) {
      setPercentageColor("text-orange-500");
    }
    if (performance < 50) {
      setPercentageColor("text-red-500");
    }
  };

  const openDropdownPopover = () => {
    // createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
    //   placement: "start",
    // });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    await deleteProject(authToken, projectId)
      .then(async (response) => {
        if (response.status === false) {
          toast.error(response.error);
          setLoading(false);
        } else {
          setLoading(false);
          setFilterProjects(projectId);
          setTimeout(() => {
            toast.success(response.message);
            handleOnClose();
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    setIsMounted(true);
    if (isMounted) {
      const closeDropDown = (e) => {
        if (
          btnDropdownRef.current &&
          !btnDropdownRef.current.contains(e.target)
        ) {
          closeDropdownPopover();
        }
      };

      document.addEventListener("click", closeDropDown, true);

      // Cleanup function
      return () => {
        document.removeEventListener("click", closeDropDown, true);
        setIsMounted(false);
      };
    }
  }, [isMounted]);

  useEffect(() => {
    color(performance);
  }, [performance]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="flex flex-col relative w-full pr-4 max-w-full flex-grow flex-1">
              {statSubtitle && (
                <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                  {statSubtitle}
                </h5>
              )}
              <span className="font-semibold text-xl text-blueGray-700">
                <a href={domain} target="_blank">
                  {statTitle}
                </a>
              </span>
              {performance ? (
                <span
                  className={`font-semibold text-2xl mt-4 ${percentageColor}`}
                >
                  {" "}
                  {performance + "%"}
                </span>
              ) : (
                <span className="font-semibold text-2xl mt-4">---</span>
              )}
              <span className="font-semibold text-sm text-blueGray-500 mt-4">
                {date}
              </span>
            </div>
            <div
              className="relative w-auto pl-4 flex-initial cursor-pointer"
              ref={btnDropdownRef}
              onClick={(e) => {
                e.preventDefault();
                dropdownPopoverShow
                  ? closeDropdownPopover()
                  : openDropdownPopover();
              }}
            >
              <div
                className={
                  "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow rounded-full hover:shadow-lg cursor-pointer " +
                  statIconColor
                }
              >
                <img src={menu} width="50" />
              </div>
            </div>

            <div
              ref={popoverDropdownRef}
              style={{
                position: "absolute",
                right: "70px",
                top: "50px",
              }}
              className={
                dropdownPopoverShow
                  ? "block z-40 bg-blueGray-50 rounded shadow-lg p-4"
                  : "hidden "
              }
            >
              <ul className="cursor-pointer">
                <li className="pb-2">
                  <Link
                    to={"project-detail/" + reportId + "/page-speed"}
                    className="block w-full text-left"
                  >
                    Page Speed Report
                  </Link>
                </li>
                <div className="border border-solid border-blueGray-200"></div>
                <li className="pt-2 pb-2">
                  <Link
                    to={"project-detail/" + analyzerId + "/web-analyzer"}
                    className="block w-full text-left"
                  >
                    Web analyzer Report
                  </Link>
                </li>
                <div className="border border-solid border-blueGray-200"></div>
                <li className="pt-2">
                  <button
                    onClick={() => setShowModal(true)}
                    className="pr-6 w-full text-left"
                  >
                    Delete
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* {statPercent && (
            <p className="text-sm text-blueGray-400 mt-4">
              <span className={statPercentColor + " mr-2"}>
                <i
                  className={
                    statArrow === "up"
                      ? "fas fa-arrow-up"
                      : statArrow === "down"
                      ? "fas fa-arrow-down"
                      : ""
                  }
                ></i>{" "}
                {statPercent}%
              </span>
              <span className="whitespace-nowrap">{statDescripiron}</span>
            </p>
          )} */}
        </div>
      </div>

      <CardDeleteAlertModal
        visible={showModal}
        onClose={handleOnClose}
        projectId={projectId}
        onSubmit={handleOnSubmit}
        loading={loading}
      />
    </>
  );
}

CardStats.defaultProps = {
  projectId: "12232",
  domain: "https://www.example.com",
  statSubtitle: "Traffic",
  statTitle: "350,897",
  statArrow: "up",
  statPercent: "3.48",
  statPercentColor: "text-emerald-500",
  statDescripiron: "Since last month",
  statIconName: "far fa-chart-bar",
  statIconColor: "bg-red-500",
};

CardStats.propTypes = {
  projectId: PropTypes.string,
  statSubtitle: PropTypes.string,
  statTitle: PropTypes.string || PropTypes.number,
  statArrow: PropTypes.oneOf(["up", "down"]),
  statPercent: PropTypes.string,
  domain: PropTypes.string,
  // can be any of the text color utilities
  // from tailwindcss
  statPercentColor: PropTypes.string,
  statDescripiron: PropTypes.string,
  statIconName: PropTypes.string,
  // can be any of the background color utilities
  // from tailwindcss
  statIconColor: PropTypes.string,
};
