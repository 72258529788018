import React, { useState, useEffect } from "react";
import { createPopper } from "@popperjs/core";
import { Link } from "react-router-dom";

import { store } from "constant/constant";

const CartDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.useRef();
  const popoverDropdownRef = React.useRef();
  const [cart, setCart] = useState(null);

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleClick = () => {
    setDropdownPopoverShow(!dropdownPopoverShow);
  };
  useEffect(() => {
    if (localStorage.getItem(store.CART)) {
      setCart(JSON.parse(localStorage.getItem(store.CART)));
    }
  }, []);

  useEffect(() => {
    const closeDropDown = (e) => {
      if (
        btnDropdownRef.current &&
        !btnDropdownRef.current.contains(e.target)
      ) {
        closeDropdownPopover();
      }
    };

    document.addEventListener("click", closeDropDown, true);

    // Cleanup function
    return () => {
      document.removeEventListener("click", closeDropDown, true);
    };
  }, []);
  return (
    <>
      <a
        className="text-blueGray-500 block mr-2"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex relative">
          {cart && (
            <span className="text-xs font-semibold inline-block  bg-lightBlue-200 uppercase last:mr-0 mr-1 badge-count">
              1
            </span>
          )}
          <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full  bg-lightBlue-500">
              <span className="font-bold fas fa-shopping-cart"></span>
            </div>
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <div className="w-full md:w-1/3">
          <div className="flex justify-center bg-white border-0 shadow-lg rounded-lg relative flex flex-col min-w-0 break-words w-full">
            {!cart && (
              <h6 className="text-lg font-semibold leading-normal pl-3 inline-block">
                Your cart is empty
              </h6>
            )}
            {cart && (
              <div className="mt-4 flex pl-0 mb-3 flex-col">
                <div className="mx-4 rounded-md relative block my-1 bg-blueGray-800 text-white">
                  <Link to="/app/checkout" onClick={handleClick}>
                    <div className="flex items-center p-3">
                      <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
                        <div
                          title="Muhammad Sohail"
                          className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full  bg-lightBlue-500"
                        >
                          <span className="font-bold">SEO</span>
                        </div>
                      </span>

                      <div className="flex-1">
                        <div className="justify-between items-center">
                          {cart?.title && (
                            <h6 className="text-lg font-semibold leading-normal pl-3 inline-block">
                              {cart?.title} Plan
                            </h6>
                          )}
                          {cart?.others.projects && (
                            <p className="ml-3 text-blueGray-200 uppercase font-bold text-xs inline-block float-right text-blueGray-200 ">
                              ({cart?.others?.projects})
                              <span className="p-1 mt-2 ml-2 text-xs font-semibold inline-block uppercase last:mr-0 mr-1 p-1 rounded bg-emerald-400"></span>
                            </p>
                          )}
                          <div className="pl-3">
                            {cart?.price && (
                              <span className="block truncate text-blueGray-100">
                                {cart?.price}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CartDropdown;
