import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Joi from "joi";

/*Service import*/
import { resetPassword, fetchForgotPasswordCode } from "services/auth";

/*Common components import*/
import Button from "components/Shared/Button";

/* toast alert import*/
import toast from "react-hot-toast";
import { composeTitle } from "utils/helper";

/** fiedls validator */
const schema = Joi.object({
  newPassword: Joi.string()
    .required()
    .min(8)
    .pattern(new RegExp("^[a-zA-Z0-9]{3,30}$"))
    .messages({
      "string.pattern.base": `Password must contain alphanumeric characters`,
      "string.empty": "New Password field is required.",
      "string.min": "Password must be at least 8 characters.",
    }),
  confirmPassword: Joi.string()
    .required()
    .pattern(new RegExp("^[a-zA-Z0-9]{3,30}$"))
    .min(8)
    .valid(Joi.ref("newPassword"))
    .messages({
      "string.pattern.base": `Password must contain alphanumeric characters`,
      "string.empty": "Confirm Password field is required.",
      "string.min": "Password must be at least 8 characters.",
      "any.only": "Confirm password does not match new password. ",
    }),
});

export default function ResetPassword() {
  const { email, randomCode } = useParams();
  const [userData, setUserData] = useState({
    email: email,
    otp: randomCode
  });
  const [user, setUser] = useState({
    newPassword: "",
    confirmPassword: ""
  });
  const [loading, setLoading] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false);
  const [clientError, setClientError] = useState(null);
  const history = useHistory();

  const handleClick = async () => {
    let validator = schema.validate({ ...user });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setClientError(null);

      try {
        setLoading(true);
        const response = await resetPassword({ ...user, email });
        if (response?.status === false) {
          setLoading(false);
          toast.error(response.message);
        } else {
          setLoading(false);
          toast.success(response.message);
          history.push('/auth/login');
        }
      } catch (err) {
        setLoading(false);
        let message =
          new Error(err)?.message ||
          "Something went wrong. Please Check you connection & try again";
        toast.error(message);
      }
    }
  };

  const fetchResetCode = async () => {
    setResponseLoading(true);
    const response = await fetchForgotPasswordCode({ email: userData.email, otp: userData.otp });
    if (response?.status === false) {
      toast.error(response.message);
      setTimeout(() => {
        setResponseLoading(false);
        history.push('/');
      }, 1000);
    } else {
      setResponseLoading(false);
      toast.success(response.message);
    }
  }

  useEffect(() => {
    composeTitle("Reset Password");

    fetchResetCode();
  }, []);
  return (
    <>
      {/* {
      !responseLoading && ( */}
      <div className="container mx-auto px-4 h-full">
        <div className="w-full flex content-center items-center justify-center  h-full">
          <div className="w-full px-4 flex justify-center">
            <div className="w-1/2 relative flex break-words mb-6 shadow-lg rounded-lg bg-white border-0">
              <div className="w-full">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-xl font-bold">
                      Reset Password
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-10">
                  <form>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        New Password
                      </label>
                      <input
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Enter new password"
                        onChange={(e) =>
                          setUser({ ...user, newPassword: e.target.value })
                        }
                      />
                      {clientError &&
                        clientError?.context?.key === "newPassword" && (
                          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                            {clientError?.message}
                          </span>
                        )}
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Confirm New Password
                      </label>
                      <input
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Confirm New Password"
                        onChange={(e) =>
                          setUser({ ...user, confirmPassword: e.target.value })
                        }
                      />
                      {clientError &&
                        clientError?.context?.key === "confirmPassword" && (
                          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                            {clientError?.message}
                          </span>
                        )}
                    </div>

                    <div className="text-center mt-6">
                      <Button
                        classes="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        text={"Update Password"}
                        onClick={handleClick}
                        loading={loading}
                      ></Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ) 
    } */}
    </>
  );
}
