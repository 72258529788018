import React from "react";
import ProgressBar from "components/Shared/ProgressBar";
import Image from "../../../assets/img/exxe.png";
import Button from "components/Shared/Button";

const ReportHeader = ({
  totalAudits = 0,
  successfulAudits = 0,
  errorsAudits = 0,
  warningAudits = 0,
  domain = "",
  loading = false,
  totalScore = null,
  projectImage = null,
}) => {
  const computedTotalScores = () => {
    let avgSuccessfulAudits =
      (parseInt(successfulAudits) / parseInt(totalAudits)) * 100;
    let avgErrorAudits = (errorsAudits / totalAudits) * 100;
    let avgWarningAudits = (warningAudits / totalAudits) * 100;
    let Tscore =
      avgSuccessfulAudits * 0.7 + avgErrorAudits * 0.2 + avgWarningAudits * 0.1;

    // return (
    //   Tscore
    // );
  };
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="flex-auto px-4 ">
            <h2 className="text-center text-base  py-3 font-bold block text-blueGray-700 hover:text-blueGray-500 flex flex-wrapjustify-center  items-center">
              Report for:
              <span className="pl-4 text-sm">{domain}</span>
            </h2>
            <div className="flex flex-wrap justify-center  items-center flex-col md:flex-row">
              <div className="w-full lg:w-4/12 px-4 text-center">
                <div className="relative w-full mb-3 mx-auto">
                  <ProgressBar
                    strokeWidth={8}
                    percentage={totalScore}
                    customClass="mx-auto"
                  />

                  <h3 className="text-center text-base  py-3 font-bold block text-blueGray-700 hover:text-blueGray-500">
                    Good result! Your web page is well optimized, but there's
                    always a room for improvement.
                  </h3>
                  {/* <Button
                    classes="bg-orange-500 text-white active:bg-orange-600 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    text={"Improve my Website"}
                    onClick={() => { }}
                    loading={loading}
                    type={"submit"}
                  ></Button> */}
                </div>
              </div>

              <div className="w-full lg:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <img src={projectImage} style={{ width: "90%" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportHeader;
