import React from "react";
import PropTypes from "prop-types";

// Import all possible card brand images
import visa from 'assets/img/cards/visa.png';
import mastercard from 'assets/img/cards/mastercard.png';
import amex from 'assets/img/cards/amex.png';
// Add all other necessary imports

// Create a map to link brand names to their respective images
const cardImages = {
  'visa': visa,
  'mastercard': mastercard,
  'amex': amex,
  // Add other card images as necessary
};

export default function CardPayment({
  last4 = null,
  expiry = null,
  brand = null,
  onChange = () => { },
  active = false,
  paymentMethod = null,
}) {
  return (
    <>
      {brand && brand !== 'paypalCard' && (
        <div>
          <input
            className="hidden"
            id={paymentMethod}
            type="radio"
            name="radio"
            checked=""
            onChange={onChange}
          />
          <label
            className={`flex flex-col m-2 border-2 border-gray-400 cursor-pointer rounded ${active ? "active-method" : ""}`}
            htmlFor={paymentMethod}
            onChange={onChange}
          >
            <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
              <div className="flex-auto p-2">
                <div className="flex flex-wrap">
                  <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                    {brand && (
                      <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                        {brand}
                      </h5>
                    )}
                    <p className="text-blueGray-400 uppercase font-bold text-xs"></p>
                    <span className="font-semibold text-xl text-blueGray-700">
                      ****{last4}
                    </span>
                  </div>
                  {brand && (
                    <div className="relative w-auto pl-4 flex-initial">
                      <div
                        className={
                          "text-white text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full "
                        }
                      >
                        <img
                          src={cardImages[brand]}  // Use the imported image map here
                          alt={brand}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {expiry && (
                  <p className="text-sm text-blueGray-400 mt-4">expiry: {expiry}</p>
                )}
              </div>
            </div>
          </label>
        </div>
      )}
    </>
  );
}

// Define prop types for the component
CardPayment.propTypes = {
  last4: PropTypes.string,
  expiry: PropTypes.string,
  brand: PropTypes.string,
  onChange: PropTypes.func,
  active: PropTypes.bool,
  paymentMethod: PropTypes.string,
};
