import React from "react";
// import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import toast from "react-hot-toast";
import { useAuth } from "contexts/auth-context";
import PayPalButton from "components/Shared/PayPalButton";
import { processPayPalPayments } from "services/payment";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PaypalSubscription = ({ planId, product, plan }) => {
  const [userPlan, setUserPlan] = React.useState(JSON.parse(plan));

  const authContext = useAuth();
  const initialOptionsSubscription = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "subscription",
    vault: true,
  };

  // const createSubscriptionHandler = (data, actions) => {
  //   console.log("actions", actions);
  //   return actions.subscription.create({
  //     plan_id: planId,
  //   });
  // };

  const createSubscriptionHandler = (data, actions) => {
    if (userPlan && userPlan?.subscriptionId === "null") {
      return actions.subscription
        .create({
          plan_id: planId,
        })
        .then((data) => {
          console.log("mydata", data);
        });
    } else {
      return actions.subscription
        .revise({
          plan_id: userPlan?.subscriptionId,
        })
        .then((data) => {
          console.log("mydata", data);
        });
    }
  };

  const paypalOnApprove = async (data, actions) => {
    console.log("onApprove actions", actions);
    return actions.subscription.capture().then((details) => {
      const name = details.payer.name.given_name;
      alert(`Transaction completed by ${name}`);
    });
  };

  // const paypalOnApprove = async (data, detail) => {
  //   // call the backend api to store transaction details
  //   // alert("Payapl approved")
  //   await processPayPalPayments(authContext?.state.authToken, {
  //     data: data,
  //     product: product,
  //   })
  //     .then((res) => console.log("backend", res))
  //     .catch((error) => console.log(error));
  // };

  // const onError = (err) => {
  //   // Show an error page here, when an error occurs
  //   toast.error(err);
  // };
  return (
    <>
      <h3>Subscription order:</h3>
      {/* <PayPalScriptProvider options={initialOptionsSubscription}> */}
      <PayPalScriptProvider options={initialOptionsSubscription}>
        <PayPalButton
          createSubscription={createSubscriptionHandler}
          onApprove={paypalOnApprove}
        />
      </PayPalScriptProvider>
    </>
  );
};

export default PaypalSubscription;
