export async function login({ email, password }) {
  const response = await fetch("/auth/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email, password: password }),
  });
  return await response.json();
}

export async function signup({
  firstName,
  lastName,
  email,
  password,
  userId,
  phone,
}) {
  const response = await fetch(`/auth/register`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      userId: userId,
      phone: phone,
    }),
  });
  return await response.json();
}
export async function completeSignup({
  firstName,
  lastName,
  email,
  password,
  userId,
  phone,
}) {
  const response = await fetch(`/auth/completeSignUp`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      userId: userId,
      phone: phone,
    }),
  });
  return await response.json();
}

export async function shortSignup({ domain }) {
  const response = await fetch(`/api/shortSignup`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ domain: domain }),
  });
  return await response.json();
}

export async function shortSignup2({ domain }) {
  const response = await fetch(`/api/shortSignup2`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ domain: domain }),
  });
  return await response.json();
}

export async function forgotPassword({ email }) {
  const response = await fetch("/auth/forgotPassword", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email }),
  });
  return await response.json();
}

export async function fetchForgotPasswordCode({ email, otp }) {
  const response = await fetch("/auth/verifyOtp", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email, otp: otp }),
  });
  return await response.json();
}

export async function resetPassword({ email, newPassword, confirmPassword }) {
  const response = await fetch("/auth/resetPassword", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email, new_password: newPassword, confirm_password:confirmPassword }),
  });
  return await response.json();
}
