import React, { useEffect, useState } from "react";
import { useAuth } from "contexts/auth-context";
import { useUserActivePlan } from "hooks/useUserActivePlan";
export default function MiniModal({
  showMiniModal,
  setShowMiniModal,
  children,
  when,
}) {
  const auth = useAuth().state;
  const [userPlan, setUserPlan] = useState(null);
  const [status, plan, error] = useUserActivePlan("getUserActivePlan");

  const [day, setDay] = useState(when);
  const onChangeHandler = (event) => {
    setDay(event.target.value);
  };

  useEffect(() => {
    if (plan) {
      setUserPlan(plan);
    }
  }, [plan]);

  return (
    <>
      {showMiniModal.show ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            // onClick={() => setShowMiniModal({
            //   show: false,
            //   when: when
            // })}
          >
            <div className="relative w-full my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Schedule Report</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() =>
                      setShowMiniModal({
                        show: false,
                        when: when,
                      })
                    }
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div
                  className="relative p-6 flex-auto"
                  onClick={(e) => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                  }}
                >
                  <p className="mb-5 text-blueGray-500 text-sm leading-relaxed">
                    Send to Contact
                  </p>
                  <div className="relative w-full mb-3">
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="someone@example.com"
                      value={`${auth.email}`}
                      name="email"
                      readOnly
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="someone"
                      value={`${auth.firstName} ${auth.lastName}`}
                      name="name"
                      readOnly
                    />
                  </div>
                  <p className="m-5 text-blueGray-500 text-sm leading-relaxed">
                    Active Package
                  </p>
                  <div className="relative w-full mt-3">
                    <input
                      type="text"
                      className="border-0 px-3 py-3  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="someone"
                      value={`${
                        userPlan?.title ? userPlan?.title : "Not Subscribed"
                      }`}
                      name="name"
                      readOnly
                    />
                  </div>
                  <p className="m-5 text-blueGray-500 text-sm leading-relaxed">
                    When to
                  </p>
                  <div className="relative w-full mt-3">
                    <select
                      name="whenTo"
                      className="w-full m-5 text-blueGray-500 text-sm leading-relaxed border-0 px-3 py-3  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={onChangeHandler}
                      value={day}
                    >
                      <option value="1">Monday</option>
                      <option value="2">Tuesday</option>
                      <option value="3">Wednesday</option>
                      <option value="4">Thursday</option>
                      <option value="5">Friday</option>
                      <option value="6">Saturday</option>
                      <option value="7">Sunday</option>
                    </select>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() =>
                      setShowMiniModal({
                        show: false,
                        when: when,
                      })
                    }
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() =>
                      setShowMiniModal({
                        show: false,
                        when: day,
                      })
                    }
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
