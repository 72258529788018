import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Joi from "joi";

/*Service import*/
import { forgotPassword } from "services/auth";

/*Common components import*/
import Button from "components/Shared/Button";

/* toast alert import*/
import toast from "react-hot-toast";

import signup_image from "assets/img/signup_image.png";
import logo_dark from "assets/logo/logo-dark.png";
import { composeTitle } from "utils/helper";

/** fiedls validator */
const schema = Joi.object({
  email: Joi.string()
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: ["com", "net"] },
    }),
  password: Joi.string().pattern(new RegExp("^[a-zA-Z0-9]{3,30}$")),
});

export default function ForgotPassword() {
  const [user, setUser] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [clientError, setClientError] = useState(null);
  const history = useHistory();

  const handleClick = async () => {
    let validator = schema.validate({ ...user });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      try {
        setLoading(true);
        const response = await forgotPassword({ ...user });
        if (response?.status === false) {
          setLoading(false);
          toast.error(response.message);
        } else {
          setLoading(false);
          toast.success(response.message);
        }
      } catch (err) {
        setLoading(false);
        let message =
          new Error(err)?.message ||
          "Something went wrong. Please Check you connection & try again";
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    composeTitle("Forgot Password");
  }, []);
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full px-4 flex justify-center">
            <div className="lg:w-8/12 relative flex  min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <div className="lg:w-6/12 hidden md:block">
                <Link to="/">
                  <img
                    src={signup_image}
                    alt="Signup image"
                    className="h-full w-full"
                  />
                </Link>
                <div className="lg:w-6/12 w-full md:w-6/12 absolute top-0 mt-10 left-0 z-10 flex h-auto flex-col justify-center items-center">
                  <img
                    src={logo_dark}
                    alt="Signup image"
                    height="100"
                    width="200"
                  />
                  <p className="text-2xl text-white mt-20 md:mt-10 text-center">
                    Enter an email to access password reset link.
                  </p>
                </div>
              </div>
              <div className="lg:w-6/12 w-full md:w-6/12">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-xl font-bold">
                      Forgot Password
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-10">
                  <form>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        onChange={(e) =>
                          setUser({ ...user, email: e.target.value })
                        }
                      />
                      {clientError && clientError?.context?.key === "email" && (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {clientError?.message}
                        </span>
                      )}
                    </div>

                    <div className="text-center mt-6">
                      <Button
                        classes="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        text={"Send Password Reset Link"}
                        onClick={handleClick}
                        loading={loading}
                      ></Button>
                    </div>
                    <p className="mt-5">
                      Register New Account{" "}
                      <span className="font-bold">
                        <Link className="underline" to="/auth/register">
                          Register
                        </Link>
                      </span>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
