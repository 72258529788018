import React, { useEffect, useState } from "react";
import { createPopper } from "@popperjs/core";
import { Link, useHistory } from "react-router-dom";

/** Hooks Import */

import { useProjectList } from "hooks/useProjectList";

/**Authcontext import */
import { useAuth } from "contexts/auth-context";
import toast from "react-hot-toast";

const ProjectDropDown = ({ project, setProject, setProjectCount = 1 }) => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.useRef();
  const popoverDropdownRef = React.useRef();
  const authContext = useAuth();

  const [auth, setAuth] = useState(authContext?.state);

  const [status, projects, error] = useProjectList("getProjects");

  useEffect(() => {
    setProjectCount(projects?.length);
    if (projects?.length === 1) {
      setProject(projects[0]);
    }
  }, [projects]);

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(
      auth?.guestUser
        ? false
        : projects?.length === 1
        ? false
        : projects?.length === 0
        ? false
        : true
    );

    if (projects?.length === 0 && status === "fetched") {
      toast.error("No project added.");
    } else if (status === "idle") {
      toast.loading("Please wait...");
    }
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  useEffect(() => {
    const closeDropDown = (e) => {
      if (
        btnDropdownRef.current &&
        !btnDropdownRef.current.contains(e.target)
      ) {
        closeDropdownPopover();
      }
    };

    document.addEventListener("click", closeDropDown, true);

    // Cleanup function
    return () => {
      document.removeEventListener("click", closeDropDown, true);
    };
  }, []);

  return (
    <>
      <a
        className="text-blueGray-400 block inline-block outline-none focus:outline-non  e align-middle transition-all duration-150 ease-in-out  border border-solid last:mr-0 mr-2 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md border-0 px-3 py-3  bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        {projects?.length > 1
          ? project?.domain || "Choose Project"
          : projects && projects.length > 0
          ? projects[0]?.domain
          : "Choose Project"}
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow
            ? "block overflow-y-scroll project-dropdown "
            : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 w-full "
        }
      >
        {projects &&
          projects?.length > 0 &&
          projects.map((project, index) => (
            <div key={project?.uuid}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setProject(auth?.guestUser ? project[0] : project);
                  setDropdownPopoverShow(!dropdownPopoverShow);
                  closeDropdownPopover();
                }}
                className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
              >
                {project?.domain}
              </button>

              <div className="h-0 my-2 border border-solid border-blueGray-100" />
            </div>
          ))}
      </div>
    </>
  );
};

export default ProjectDropDown;
