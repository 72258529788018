import React, { useState, useEffect } from "react";

/** service import */
import { fetchCountries } from "services/common";

export const CountryDropdown = () => {
  const [display, setDisplay] = useState(false);
  const [countries, setCountries] = useState([]);

  async function fetchData() {
    await fetchCountries().then((response) => {
      setCountries(response);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="relative">
      <div className="mb-3 pt-0">
        <input
          placeholder="Select Country"
          type="text"
          className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 "
          value=""
          onClick={(e) => setDisplay(!display)}
        />
      </div>
      {display && (
        <div className="z-50">
          <div className=" absolute opacity-0 border bg-white text-base z-50 float-left p-2 text-left rounded-lg shadow-lg min-w-48 transition-all duration-200 ease-in-out transform scale-95 origin-top-left origin-top-right">
            {countries.length > 0 &&
              countries.map((item) => (
                <a
                  key={item?.alpha2Code}
                  href="#pablo"
                  className="text-sm px-3 py-2 font-normal block w-full whitespace-nowrap transition-all duration-200 hover:bg-blueGray-100 rounded"
                >
                  {item?.name}
                </a>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
