import React from "react";

// components

export default function ProjectCardContent({
  title = null,
  description = null,
  stats = null,
  textClass = null,
}) {
  return (
    <>
      <div className="w-full lg:w-6/12 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
          <div className="flex-auto p-4">
            <div className="flex flex-wrap">
              <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                {title && (
                  <h5
                    className={`font-bold text-md whitespace-nowrap font-semibold font-arimo   ${textClass}`}
                  >
                    {title}
                  </h5>
                )}
              </div>
              {stats && (
                <div
                  className={`relative w-auto pl-4 flex-initial font-semibold font-arimo ${textClass}`}
                >
                  {stats}
                </div>
              )}
            </div>
            {description && (
              <p className="text-sm text-blueGray-400 font-arimo ">{description}</p>
            )}
          </div>
        </div>
      </div>

      <hr className="mt-6 border-b-1 border-blueGray-300" />
    </>
  );
}
