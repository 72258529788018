import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { session } from "constant/constant";

/** context import */
import { useApp } from "contexts/app-context";
import { useAuth } from "contexts/auth-context";

/** constant import */
import { pages } from "constant/constant";

// components
import WebAnalyzerCard from "components/Cards/WebAnalyzerCard";
import Modal from "components/Modals/Modal";

/** utils import */
import { composeTitle } from "utils/helper";

export default function WebAnalyzer() {
  const appContext = useApp();
  const authContext = useAuth();
  const history = useHistory();
  const [showModal, setShowModal] = useState(
    appContext?.state?.showModal || false
  );

  useEffect(() => {
    composeTitle(pages?.webAnalyzer);
    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.webAnalyzer,
      },
    });

    if (authContext?.state?.guestUser) {
      authContext.dispatch({
        type: authContext.actions.SET,
        data: {
          guestUser: false,
        },
      });
      localStorage.setItem("previousPage", "/app/page-speed");
      localStorage.removeItem("persist");
      history.push("/auth/register");
    }
    localStorage.removeItem("openTab");
  }, []);

  const hideModal = () => {
    setShowModal(!showModal);
    appContext.dispatch({
      type: appContext.actions.UPDATE,
      data: {
        showModal: !showModal,
      },
    });
  };

  useEffect(() => {
    setShowModal(appContext.state.showModal);
  }, [appContext.state.showModal]);
  return (
    <>
      <Modal showModal={showModal} hideModal={hideModal} />
      <div className="flex flex-wrap mt-4 overflow-x-hidden">
        <WebAnalyzerCard />
      </div>
    </>
  );
}
