import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "contexts/auth-context";

import Joi from "joi";

import { fetchWebPageDetails } from "services/pagespeed";
import {
  auditsCount,
  errorCount,
  warningCount,
  filterExternalLinks,
  filterInternalLinks,
} from "utils/pagestats";
// components
import Button from "components/Shared/Button";
import Skeleton from "components/Shared/Skeleton";
import ProjectDropDown from "components/Dropdowns/ProjectsDropDown";
import ReportHeader from "./WebAnalyzer/ReportHeader";
import Stats from "./WebAnalyzer/Stats";
import SignupModal from "components/Modals/SignupModal";
import PercentageProgressBar from "components/Shared/PercentageProgressBar";

/** hooks import */

import useAccountMiddleware from "hooks/useAccountMiddleware";

/**alert import */
import toast from "react-hot-toast";

/** fiedls validator */
const schema = Joi.object({
  domain: Joi.string()
    .regex(
      /^(https?:\/\/)?((?!-)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z]{2,})+)(\/.*)?$/
    )
    .label("Domain")
    .required()
    .messages({ "string.pattern.base": "* Invalid Domain" }),
});

export default function WebAnalyzerCard({}) {
  const authContext = useAuth();
  const history = useHistory();
  const [
    isSignUpCompleted,
    isPlanSubscribed,
    plan,
    ifSubscriptionCancelled,
    cancelPlan,
  ] = useAccountMiddleware();
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState("");
  const [project, setProject] = useState(null);
  const [projectCount, setProjectCount] = useState(null);
  const [webPageData, setWebPageData] = useState(null);
  const [webPageScore, setWebPageScore] = useState(null);
  const [error, setError] = useState(null);
  const [successfulAudits, setSuccessfulAudits] = useState(0);
  const [errorAudits, setErrorAudits] = useState(0);
  const [warningAudits, setwarningAudits] = useState(0);
  const [successfullData, setSuccessfullData] = useState(null);
  const [errorData, setErrorData] = useState(null);
  const [warningData, setWarningData] = useState(null);
  const [internalLinks, setInternalLinks] = useState([]);
  const [externalLinks, setExternalLinks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [projectImage, setProjectImage] = useState(null);
  const [averageResponseTime, setAverageResponseTime] = useState(0);

  const getAuditsCount = (data) => {
    setSuccessfulAudits(auditsCount(data));
  };
  const getErrorAuditsCount = (data) => {
    setErrorAudits(errorCount(data));
  };
  const getWarningAuditsCount = (data) => {
    setwarningAudits(warningCount(data));
  };

  const filterOutExternalLinks = (data) => {
    setExternalLinks(filterExternalLinks(data));
  };
  const filterOutInternalLinks = (data) => {
    setInternalLinks(filterInternalLinks(data));
  };

  function myprogress() {
    setAverageResponseTime((preState) => {
      if (preState < 80) {
        return preState + 20;
      } else {
        return preState;
      }
    });
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (isSignUpCompleted() === false) {
      setShowModal(true);
      return;
    }
    if ((!plan || (plan && plan?.title === "Free")) && cancelPlan === null) {
      toast.error("You do not subscribed any plan!");
      setTimeout(() => {
        history.push("/app/subscription");
      }, 1000);
      return;
    }
    let validator = schema.validate({ domain });
    if (!validator?.error) {
      let myInterval = setInterval(() => {
        myprogress();
      }, 5000);
      setAverageResponseTime(0);
      setLoading(true);
      await fetchWebPageDetails(authContext?.state.authToken, domain)
        .then(async (response) => {
          setAverageResponseTime(100);
          clearInterval(myInterval);
          if (response.status === false) {
            toast.error(response?.message);
            setLoading(false);
            setAverageResponseTime(0);
          } else {
            setProjectImage(response.analyzeData?.siteImage);
            setWebPageData(response.analyzeData);
            setWebPageScore(response.Tscore);
            getAuditsCount(response.analyzeData);
            setErrorData(response.error);
            setWarningData(response.warnings);
            setSuccessfullData(response.success);
            setTimeout(() => {
              setLoading(false);
              setAverageResponseTime(0);
            }, 1000);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAverageResponseTime(0);
          toast.error(error);
        });
    } else {
      setLoading(false);
      setAverageResponseTime(0);
      setError(validator.error.details?.[0]);
    }
  };

  useEffect(() => {
    if (project && project.domain) setDomain(project.domain);
  }, [project]);
  useEffect(() => {
    if (webPageData) {
      getAuditsCount(webPageData);
      getErrorAuditsCount(webPageData);
      getWarningAuditsCount(webPageData);
      filterOutExternalLinks(webPageData);
      filterOutInternalLinks(webPageData);
    }
  }, [webPageData]);

  useEffect(() => {
    isPlanSubscribed();
    ifSubscriptionCancelled();
  }, []);

  return (
    <>
      <SignupModal
        showModal={showModal}
        hideModal={() => setShowModal(false)}
      />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0 py-10">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Web Analyzer
            </h6>
            <Link
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              to="/app/dashboard"
            >
              Go To homepage
            </Link>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={onFormSubmit}>
            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold">
              Website Analyzer reviews several SEO-related factors responsible
              for a website's rankings.
            </h6>
            <div className="flex flex-wrap py-10 justify-center  items-center">
              <div className="w-full lg:w-6/12 px-4 ">
                <div className="relative w-full mb-3">
                  <ProjectDropDown
                    project={project}
                    setProject={setProject}
                    setProjectCount={setProjectCount}
                  />
                  {error && error?.context?.key === "domain" && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {error?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <Button
                    classes="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    text={"Check"}
                    onClick={() => {}}
                    loading={loading}
                    type={"submit"}
                  ></Button>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4 ">
                <div className="relative w-full mb-3">
                  {loading ? (
                    <PercentageProgressBar
                      percentage={averageResponseTime}
                    ></PercentageProgressBar>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Skeleton />}
      {!loading && webPageData && (
        <>
          <ReportHeader
            projectImage={projectImage}
            totalAudits={successfulAudits + errorAudits + warningAudits}
            successfulAudits={successfulAudits}
            errorAudits={errorAudits}
            warningAudits={warningAudits}
            domain={webPageData.domain || "N/a"}
            totalScore={webPageScore}
          />
          <Stats
            totalAudits={successfulAudits + errorAudits + warningAudits}
            successfulAudits={successfulAudits}
            errorAudits={errorAudits}
            warningAudits={warningAudits}
            internalLinks={internalLinks}
            externalLinks={externalLinks}
            webPageData={webPageData}
            successfullData={successfullData}
            errorData={errorData}
            warningData={warningData}
          />
        </>
      )}
      {/* {!loading && (
        <Tabs
          desktopStats={importantAudit}
          desktopPerformance={desktopPerformance}
          desktopLoading={desktopLoading}
          mobileStats={mobileImportantAudit}
          mobilePerformance={mobilePerformance}
          mobileLoading={mobileLoading}
        />
      )} */}
    </>
  );
}
