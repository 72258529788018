export async function storeReportData(
  token,
  projectId,
  desktopStats,
  mobileStats
) {
  try {
    const response = await fetch("/report/store", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        projectId: projectId,
        desktopStats: desktopStats,
        mobileStats: mobileStats,
      }),
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export const generateReport = async (
  token,
  project,
  reportName,
  reportType,
  reportFormat,
  delivery,
  day,
  controller
) => {
  try {
    const response = await fetch("/report/generate_report", {
      signal: controller.current.signal,
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify({
        project: project,
        report_name: reportName,
        report_type: reportType,
        reportFormat: reportFormat,
        delivery: delivery,
        when: day.when,
      }),
    });
    return await response.json();
  } catch (error) {
    return error;
  }
};

export const getReportSchedule = async (token) => {
  try {
    const response = await fetch("/report/report_list_schedule", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
};

export const sentReport = async (token, email, project) => {
  try {
    const response = await fetch("/report/sendReport", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        email: email,
        project: project,
      }),
    });

    return await response.json();
  } catch (error) {
    return error;
  }
};
