/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import FooterNew from "components/Footers/FooterNew";
import CardLead from "components/Cards/CardLead";
import mobile_desktop from "assets/img/mobile_desktop.png";
import Button from "components/Shared/Button";
import crawler from "assets/img/crawler.jpg";
import web1 from "assets/img/web1.png";

import { composeTitle } from "utils/helper";

export default function Index() {
  React.useEffect(() => {
    composeTitle("SEO tool");
    if (localStorage.getItem("userId")) {
      localStorage.clear();
    }
  }, []);
  return (
    <>
      <IndexNavbar fixed />
      <section className="header relative  items-center flex min-h-screen">
        <div className="w-full min-h-screen max-h-860-px flex flex-row items-center pt-24 hero-image">
          <div className="container mx-auto">
            <div className="flex flex-wrap items-center">
              <div className="w-full md:w-6/12 px-4">
                <div className="flex flex-wrap">
                  <div className="w-full">
                    <div className="relative flex flex-col mt-4">
                      <div className="pt-32 sm:pt-0">
                        <h1 className="relative font-semibold text-4xl text-white text-center">
                          Run Free Website Performance Test
                        </h1>
                        <div className="mt-5 text-center">
                          <CardLead />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 xl:hidden md:hidden lg:hidden">
                <img
                onScroll={mobile_desktop}
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                <img
                  alt="..."
                  src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80"
                  className="w-full align-middle rounded-t-lg"
                  loading="lazy"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                    loading="lazy"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-lightBlue-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold">
                    Great for your awesome project
                  </h4>
                  <p className="text-md font-light mt-2">
                    Putting together a page has never been easier than matching
                    together pre-made components. From landing pages
                    presentation to login areas, you can easily customise and
                    built your pages.
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto flex flex-col">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-search"></i>
                      </div>
                      <h3 className="text-3xl mb-2 font-semibold leading-normal">
                        SEO Audit
                      </h3>
                      <p className="mb-4 text-blueGray-500">
                        Every website deserves a comprehensive SEO audit. SEO
                        audit tool can help check your website audit issues in
                        just a few minutes. Simply enter your website URL and
                        you’ll receive a comprehensive report with actionable
                        insights on how to improve your website’s SEO. The
                        report includes an overview of your website’s current
                        SEO health, as well as detailed information on each of
                        the key metric categories: ranking positions for
                        specific keywords, on-page optimization errors, traffic
                        analysis, competing websites, keyword research, and much
                        more.{" "}
                      </p>
                      <p className="mb-4 text-blueGray-500">
                        With this valuable information at your fingertips, you
                        can make the necessary changes to improve your website’s
                        SEO and attract more organic traffic.{' '}
                      </p>
                      <div className=" w-full flex justify-center">
                        <Link
                          to="/auth/login"
                          className="bg-lightBlue-500 w-fit active:bg-lightBlue-600 font-bold text-white rounded-full px-6 py-2 ml-2 mt-10  fit-content"
                        >
                          Audit Website Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}


        <div className="container mx-auto overflow-hidden pb-20">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-6/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-tachometer-alt text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Page Speed Analysis
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                Internet is a fast-paced and ever-changing landscape. In order
                to keep up with the competition, you need to make sure your
                website is running at optimal speed. But did you know that the
                speed of your website can also affect your search engine
                ranking? That's why it's important to analyze your page speed
                and Core web vitals regularly.
              </p>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                ‘Now you can do that by using our
                tool which has free speed analyzing feature & core web vitals. You can measure exact website page speed on all devices.
                Simply enter your URL and it'll provide you with a detailed
                report on what is slowing it down and how to speed it up. Best
                of all, there's no need to install anything - it works right in
                your web browser.
              </p>
              <p>
                <Link
                  to="/auth/login"
                  className="bg-lightBlue-500 active:bg-lightBlue-600 font-bold text-white rounded-full px-6 py-2 ml-2"
                >
                  Check Page Speed Now
                </Link>
              </p>
            </div>

            <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-xl"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(10deg) rotateX(2deg) rotate(2deg)",
                }}
                src={crawler}
                loading="lazy"
              />

            </div>
          </div>
        </div>

        <div className="container mx-auto overflow-hidden pb-20">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-6/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-search text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Why Choose Us?
              </h3>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                <span className="font-bold text-black">One-Month Free Trial:</span> Experience WebWatch's features without any cost for a month. Cancel anytime if it's not the right fit for you.
              </p>
              <p className="mt-2 text-lg leading-relaxed text-blueGray-500">
                <span className="font-bold text-black">24/7 Customer Support:</span> We're always available to help you with any queries or issues, ensuring a smooth experience.
              </p>
              <p className="mt-2 text-lg leading-relaxed text-blueGray-500">
                <span className="font-bold text-black"> Expert SEO Assistance:</span> Our team of SEO experts is ready to provide personalized support to enhance your website's performance.
              </p>
              <p className="mt-2 text-lg leading-relaxed text-blueGray-500">
                <span className="font-bold text-black"> Comprehensive PDF Reports:</span> Get detailed insights into your website's SEO health with easy-to-understand PDF reports.
              </p>
            </div>

            <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-xl"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(10deg) rotateX(2deg) rotate(2deg)",
                }}
                src={web1}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="block relative z-1 bg-blueGray-600"></section>

      <section className="pb-16 bg-blueGray-200 relative pt-32">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
            <div className="w-full text-center lg:w-8/12">
              <p className="text-4xl text-center">
                <span role="img" aria-label="love">
                  😍
                </span>
              </p>
              <h3 className="font-semibold text-3xl">
                Do you love this Starter Kit?
              </h3>
              <p className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Cause if you do, it can be yours now. Hit the buttons below to
                navigate to get the Free version for your next project. Build a
                new web app or give an old project a new look!
              </p>
              <div className="sm:block flex flex-col mt-10">
                <Link
                  to="/auth/login"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Get started
                </Link>
              </div>
              <div className="text-center mt-16"></div>
            </div>
          </div>
        </div>
      </section>
      <FooterNew />
    </>
  );
}
