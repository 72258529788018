import react, { useEffect, useState } from "react";

import { useAuth } from "contexts/auth-context";

import { getUserPlan } from "services/dashboard";

export const useUserActivePlan = (query) => {
  const authToken = useAuth()?.state?.authToken;
  const [plan, setPlan] = useState([]);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("idle");
  useEffect(() => {
    if (!query) return;
    getUserPlan(authToken)
      .then((response) => setPlan(response.data))
      .catch((error) => setError(error));
    setStatus("fetched");
  }, [query]);
  return [status, plan, error];
};
