import React, { useEffect, useState } from "react";
import { createPopper } from "@popperjs/core";

const items = [
  {
    id: 1,
    title: "Page Speed",
    value: "page speed",
  },
  {
    id: 2,
    title: "Web Analyzer",
    value: "web analyzer",
  },
  // {
  //   id: 3,
  //   title: "All",
  //   value: "all",
  // },
];
const ProjectType = ({ projectType, setProjectType }) => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.useRef();
  const popoverDropdownRef = React.useRef();

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  useEffect(() => {
    const closeDropDown = (e) => {
      if (
        btnDropdownRef.current &&
        !btnDropdownRef.current.contains(e.target)
      ) {
        closeDropdownPopover();
      }
    };

    document.addEventListener("click", closeDropDown, true);

    // Cleanup function
    return () => {
      document.removeEventListener("click", closeDropDown, true);
    };
  }, []);
  return (
    <>
      <a
        className="text-blueGray-400 block inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out  border border-solid last:mr-0 mr-2 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md border-0 px-3 py-3  bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        {projectType?.title || "Choose Report Type"}
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 w-full"
        }
      >
        {items &&
          items.length > 0 &&
          items.map((item) => (
            <div key={item?.id}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setProjectType(item);
                  setDropdownPopoverShow(!dropdownPopoverShow);
                  closeDropdownPopover();
                }}
                className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-left"
              >
                {item?.title}
              </button>

              <div className="h-0 my-2 border border-solid border-blueGray-100" />
            </div>
          ))}
      </div>
    </>
  );
};

export default ProjectType;
