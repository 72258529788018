import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";

import "assets/styles/tailwind.css";
import CardLead from "components/Cards/CardLead";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import FooterNew from "components/Footers/FooterNew";

import webanalyzer from "assets/images/webanalyzer-illustration.png";
import competitorIcon from 'assets/images/round-icons/competitor.png';
import monitorIcon from 'assets/images/round-icons/monitor.png';
import analyzeIcon from 'assets/images/round-icons/analyze.png';
import saveIcon from 'assets/images/round-icons/save.png';
import actIcon from 'assets/images/round-icons/act.png';
import trialIcon from 'assets/images/round-icons/trial.png';
import businessIllustration from 'assets/images/business-illustration.png';
import step3 from 'assets/images/step-3.png';
import step1 from 'assets/images/step-1.png';
import step5 from 'assets/images/step-5.png';

const WebAnalyzer = () => {
  return (
    <>
      <IndexNavbar fixed />
      <div className="bg-hero bg-cover bg-no-repeat place-items-center relative">
        <div className="hidden lg:block">
          <img src={webanalyzer} alt="" className='ml-auto h-full w-3/4 2xl:w-3/5' />
        </div>
        <div className="lg:absolute lg:top-0 w-full h-full lg:flex place-items-center">
          <div className="container mx-auto p-8">
            <div className="lg:grid lg:grid-cols-2">
              <div className='py-8 text-white'>
                <h1 className="text-3xl md:text-5xl lg:text-5xl font-bold md:leading-[55px] lg:leading-[69px] mb-5"><span>Ultimate On-Page  </span><span className="block">SEO Checker</span></h1>
                <div className="mt-5 text-center">
                  <CardLead />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-8 md:mt-5 lg:mt-8">
        <h2 className="text-center text-3xl md:text-4xl lg:text-5xl font-medium mb-3 text-theme-secondary uppercase">Explore On-Page SEO Checker Features</h2>
        <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-8">
          <div className="text-center text-theme-secondary p-5 md:p-10 hover:border hover:border-theme-primary hover:bg-theme-primary/10">
            <img src={competitorIcon} alt="" className="mx-auto" />
            <h3 className="font-medium text-xl md:text-2xl mb-2 md:mb-4 uppercase">Headings Breakdown</h3>
            <p>Our SEO WebAnalyzer helps you analyze and sort various headings like H1, H2, H3, and more. It highlights any missing elements to ensure your page has an optimal structure for SEO.</p>
          </div>
          <div className="text-center text-theme-secondary p-5 md:p-10 hover:border hover:border-theme-primary hover:bg-theme-primary/10">
            <img src={monitorIcon} alt="" className="mx-auto" />
            <h3 className="font-medium text-xl md:text-2xl mb-2 md:mb-4 uppercase">Meta Analysis</h3>
            <p>Our On page SEO Tool checks your meta titles and descriptions for proper length and presence, aligning them with SEO best practices for better performance.</p>
          </div>
          <div className="text-center text-theme-secondary p-5 md:p-10 hover:border hover:border-theme-primary hover:bg-theme-primary/10">
            <img src={analyzeIcon} alt="" className="mx-auto" />
            <h3 className="font-medium text-xl md:text-2xl mb-2 md:mb-4 uppercase">Link Insights</h3>
            <p>Get a comprehensive report on your page's internal and external links. This feature is designed to improve your site's navigation and boost its SEO authority.</p>
          </div>
          <div className="text-center text-theme-secondary p-5 md:p-10 hover:border hover:border-theme-primary hover:bg-theme-primary/10">
            <img src={saveIcon} alt="" className="mx-auto" />
            <h3 className="font-medium text-xl md:text-2xl mb-2 md:mb-4 uppercase">Snippet and Favicon Review</h3>
            <p>Our On page SEO Anaylsis Tool allows you to preview your website’s search snippet and check the favicon, boosting your brand's visibility and appeal.</p>
          </div>
          <div className="text-center text-theme-secondary p-5 md:p-10 hover:border hover:border-theme-primary hover:bg-theme-primary/10">
            <img src={actIcon} alt="" className="mx-auto" />
            <h3 className="font-medium text-xl md:text-2xl mb-2 md:mb-4 uppercase">Content Depth and Keywords</h3>
            <p>Analyze your page's word count and keyword usage in-depth. This assists in developing a richer content strategy for better SEO results.</p>
          </div>
          <div className="text-center text-theme-secondary p-5 md:p-10 hover:border hover:border-theme-primary hover:bg-theme-primary/10">
            <img src={trialIcon} alt="" className="mx-auto" />
            <h3 className="font-medium text-xl md:text-2xl mb-2 md:mb-4 uppercase">It’s Free</h3>
            <p>Try our On-Page SEO Checker with a 30-day free trial. Experience all the features and see how they can transform your SEO strategy.</p>
          </div>
        </div>
        <button className="block mt-4 mx-auto px-6 py-2 font-medium text-lg bg-theme-primary hover:bg-theme-secondary text-white rounded shadow-lg">Get Free SEO Audit Report</button>
      </div>
      <div className="container mx-auto p-8 lg:mt-8">
        <div className="md:grid md:grid-cols-2">
          <div className="lg:p-8 flex items-center">
            <img src={businessIllustration} alt="" className='w-full' />
          </div>
          <div className="md:px-8 mt-10 md:mt-0 flex items-center">
            <div>
              <h2 className="text-3xl md:text-4xl lg:text-5xl md:leading-[45px] lg:leading-[60px]  font-medium uppercase text-theme-secondary mb-5 md:mb-8 lg:mb-16"><span>Building a Stronger Online Presence with On page </span><span className="lg:block">SEO Analysis Tool</span></h2>
              <div className="mt-5">
                <h4 className="text-xl uppercase font-medium text-theme-secondary">Effortless SEO Audits:</h4>
                <p className="md:mt-4 text-theme-basic">Quickly get a clear picture of your site's SEO health with our easy-to-use auditing tool. Ideal for a Full SEO Audit Report without the complexity.</p>
                <hr className="md:w-2/3 mt-3 md:mt-5 mx-auto border-2 border-theme-basic/30" />
              </div>
              <div className="mt-5">
                <h4 className="text-xl uppercase font-medium text-theme-secondary">Clear, Simple Reports:</h4>
                <p className="md:mt-4 text-theme-basic">We turn SEO data into reports that are straightforward and informative. Perfect for those seeking a Full On page SEO Audit.</p>
                <hr className="md:w-2/3 mt-3 md:mt-5 mx-auto border-2 border-theme-basic/30" />
              </div>
              <div className="mt-5">
                <h4 className="text-xl uppercase font-medium text-theme-secondary">Custom Recommendations:</h4>
                <p className="md:mt-4 text-theme-basic">Our suggestions are practical and easy to apply, helping you improve your site step by step</p>
              </div>
              <button className="block mt-5 md:mt-10 px-6 py-2 font-medium text-lg bg-theme-primary hover:bg-theme-secondary text-white rounded shadow-lg">READY FOR A 30-DAY FREE TRAIL</button>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto lg:mt-16">
        <div className="flex flex-col-reverse md:grid md:grid-cols-2">
          <div className="p-8 py-0 md:py-8 flex items-center">
            <div>
              <h2 className="text-3xl md:text-4xl lg:text-5xl md:leading-[45px] lg:leading-[60px] font-medium uppercase text-theme-secondary md:mb-8 lg:mb-16"><span>SEO  Score</span><span className="md:block"></span></h2>
              <div className="mt-5">
                 <p className="md:mt-5 text-theme-basic">Discover your website's SEO health quickly with our SEO Score feature. It provides a clear view of what's working and what needs attention, helping you prioritize your efforts effectively. By understanding your site's strengths and weaknesses, you can focus on key areas to enhance your overall performance.</p>
               </div>
              <button className="block mt-5 md:mt-10 px-6 py-2 font-medium text-lg bg-theme-primary hover:bg-theme-secondary text-white rounded shadow-lg">Check Your SEO Score Now</button>
            </div>
          </div>
          <div className="p-8 flex items-center">
            <img src={step3} alt="" className='w-full' />
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-8 lg:mt-16">
        <div className="md:grid md:grid-cols-2">
          <div className="p-8 flex items-center">
            <img src={step1} alt="" className='w-full' />
          </div>
          <div className="p-8 flex items-center">
            <div>
              <h2 className="text-3xl md:text-4xl lg:text-5xl md:leading-[45px] lg:leading-[60px] font-medium uppercase text-theme-secondary lg:mb-16"><span>Full SEO Audit  </span><span className="md:block">Report</span></h2>
              <div className="mt-5">
                <p className="md:mt-5 text-theme-basic">Our Full SEO Audit Report goes beyond basic analysis. It offers an in-depth review of your website's SEO, providing insights and recommendations. This report is key for those seeking a detailed understanding and strategic direction to bolster their site's SEO standing.</p>
              </div>
              <button className="block mt-5 md:mt-10 px-6 py-2 font-medium text-lg bg-theme-primary hover:bg-theme-secondary text-white rounded shadow-lg">Get Your Free SEO Audit Report</button>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto lg:mt-16">
        <div className="flex flex-col-reverse md:grid md:grid-cols-2">
          <div className="p-8 flex items-center">
            <div>
              <h2 className="text-3xl md:text-4xl lg:text-5xl md:leading-[45px] lg:leading-[60px] font-medium uppercase text-theme-secondary lg:mb-16"><span>Experience the Difference with  </span><span className="md:block">Our SEO WebAnalyzer</span></h2>
              <div className="mt-5"> 
                <p className="md:mt-5 text-theme-basic">Start your journey to SEO excellence today. Explore Web Analyzer and unlock the full potential of your website. With our On-Page SEO Checker, success is not just a possibility; it's a guarantee.</p>
              </div>
              <button className="block mt-4 sm:mt-10 px-6 py-2 font-medium text-lg bg-theme-primary hover:bg-theme-secondary text-white rounded shadow-lg">Start Free On-Page SEO Audit</button>
            </div>
          </div>
          <div className="p-8 flex items-center">
            <img src={step5} alt="" className='w-full' />
          </div>
        </div>
      </div>
      <div className="container mx-auto md:mt-16">
        <div className="md:grid md:grid-cols-2">
          <div className="p-8 flex sm:items-center bg-sidebar bg-cover bg-left-bottom lg:w-2/3 sm:ml-8 rounded">
            <div className="lg:pl-5">
              <div className="flex items-center">
                <div className="bg-purple-50 p-3 sm:p-4 mr-5 text-theme-secondary rounded-full"><FaCheck /></div>
                <div className="border-b-2 border-white pb-1 sm:pb-3 text-white uppercase font-normal text-md sm:text-l ">Sign up for the 30-day free trial.</div>
              </div>
              <div className="flex items-center mt-5">
                <div className="bg-purple-50 p-3 sm:p-4 mr-5 text-theme-secondary rounded-full"><FaCheck /></div>
                <div className="border-b-2 border-white pb-1 sm:pb-3 text-white uppercase font-normal text-md sm:text-l">Insert your website URL for analysis.</div>
              </div>
              <div className="flex items-center mt-5">
                <div className="bg-purple-50 p-3 sm:p-4 mr-5 text-theme-secondary rounded-full"><FaCheck /></div>
                <div className="border-b-2 border-white pb-1 sm:pb-3 text-white uppercase font-normal text-md sm:text-l">Review headings and meta tags.</div>
              </div>
              <div className="flex items-center mt-5">
                <div className="bg-purple-50 p-3 sm:p-4 mr-5 text-theme-secondary rounded-full"><FaCheck /></div>
                <div className="border-b-2 border-white pb-1 sm:pb-3 text-white uppercase font-normal text-md sm:text-l">Analyze link structure and content.</div>
              </div>
              <div className="flex items-center mt-5">
                <div className="bg-purple-50 p-3 sm:p-4 mr-5 text-theme-secondary rounded-full"><FaCheck /></div>
                <div className="border-b-2 border-white pb-1 sm:pb-3 text-white uppercase font-normal text-md sm:text-l">Check snippets and favicons.</div>
              </div>
              <div className="flex items-center mt-5">
                <div className="bg-purple-50 p-3 sm:p-4 mr-5 text-theme-secondary rounded-full"><FaCheck /></div>
                <div className="border-b-2 border-white pb-1 sm:pb-3 text-white uppercase font-normal text-md sm:text-l">Implement recommendations for improvement.</div>
              </div>
            </div>
          </div>
          <div className="p-8 flex items-center">
            <div className="w-full">
              <h2 className="text-2xl md:text-4xl lg:text-5xl line-clamp-2 font-bold sm:font-medium uppercase text-theme-secondary sm:mb-2">Have Questions? We’re Here to Help!</h2>
               <h4 className="text-xl uppercase font-medium text-theme-secondary">seo does not need to be difficult</h4>
              <form className="mt-1 sm:mt-5 lg:w-4/5">
                <div className="md:grid md:grid-cols-2 gap-4">
                  <input type="text" placeholder="First Name" className="w-full mt-3 sm:mt-5 p-3 rounded border border-theme-basic/30 bg-theme-primary/5" />
                  <input type="text" placeholder="Last Name" className="w-full mt-3 sm:mt-5 p-3 rounded border border-theme-basic/30 bg-theme-primary/5" />
                </div>
                <input type="email" placeholder="Email Address" className="w-full mt-3 sm:mt-5 p-3 rounded border border-theme-basic/30 bg-theme-primary/5" />
                <input type="password" placeholder="Password" className="w-full mt-3 sm:mt-5 p-3 rounded border border-theme-basic/30 bg-theme-primary/5" />
                <input type="password" placeholder="Repeat Password" className="w-full mt-3 sm:mt-5 p-3 rounded border border-theme-basic/30 bg-theme-primary/5" />
                <div className="flex sm:items-center mt-2 sm:mt-5">
                  <input type="checkbox" value="" className="w-4 h-4 mt-1 sm:mt-0" />
                  <label htmlFor="checked-checkbox" className="ms-4 text-md font-medium text-black">I Agree To The <span className="text-theme-secondary">Terms Of Services</span></label>
                </div>
                <button className="block w-full mt-4 sm:mt-8 px-6 py-2 font-medium text-lg bg-theme-primary hover:bg-theme-secondary text-white rounded shadow-lg">START MY 30-DAY TRAIL</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FooterNew />
    </>
  )
}

export default WebAnalyzer;
