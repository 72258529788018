import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useApp } from "contexts/app-context";
import { useAuth } from "contexts/auth-context";

/** constant import */
import { pages } from "constant/constant";

/** utils import */
import { composeTitle } from "utils/helper";

// components
import ProjectDetailCard from "components/Cards/ProjectDetailCard";
import Modal from "components/Modals/Modal";

const PageDetail = () => {
  const appContext = useApp();
  const authContext = useAuth();
  const history = useHistory();
  const [showModal, setShowModal] = useState(
    appContext?.state?.showModal || false
  );
  const hideModal = () => {
    setShowModal(!showModal);
    appContext.dispatch({
      type: appContext.actions.UPDATE,
      data: {
        showModal: !showModal,
      },
    });
  };

  useEffect(() => {
    setShowModal(appContext.state.showModal);
  }, [appContext.state.showModal]);
  useEffect(() => {
    if (authContext.state.guestUser) {
      localStorage.removeItem("persist");
      localStorage.removeItem("userId");
      authContext.dispatch({
        type: authContext.actions.DEAUTHENTICATE,
      });
      history.push("/auth/register");
    }
    composeTitle(pages?.pageDetail);
    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.pageDetail,
      },
    });
  }, []);
  return (
    <div className="w-full oveflow-x-hidden">
      <Modal showModal={showModal} hideModal={hideModal} />
      <ProjectDetailCard />
    </div>
  );
};

export default PageDetail;
