import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
/**Context  import*/
import { useApp } from "contexts/app-context";
import { useAuth } from "contexts/auth-context";
/**Component import */
import PriceCard from "components/Cards/PriceCard";
import { composeTitle } from "utils/helper";

import { getActivePlan } from "services/subscription";

/** data/constant import */
import { pages, store } from "constant/constant";
import { products } from "constant/products";
export default function Pricing() {
  const appContext = useApp();
  const history = useHistory();
  const auth = useAuth()?.state;
  const [plan, setPlan] = useState(null);

  const handleClick = async (product) => {
    if (plan?.title !== "Free" && product.title === "Free") {
      toast.error("You're not allowed to perform this action.");
      return;
    }
    if (!localStorage.getItem(store.CART)) {
      product.coupon = null;
      localStorage.setItem(store.CART, JSON.stringify(product));
      history.push("/app/checkout");
    } else {
      toast("You've already item in cart! Please clear if you want.", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      history.push("/app/checkout");
    }
  };

  const getUserActivePlan = async () => {
    let response = await getActivePlan(auth?.authToken);
    if (response.status === true) {
      setPlan(response.data);
    } else {
      // toast.error(response.message);
    }
  };

  useEffect(() => {
    composeTitle(pages?.pricing);

    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.pricing,
      },
    });
    getUserActivePlan();
  }, []);
  return (
    <div className="flex flex-wrap justify-center">
      {products &&
        products.map((product, index) => (
          <div className="w-full lg:w-4/12 px-4" key={index}>
            <PriceCard
              title={product?.title}
              price={product?.price}
              type={product?.duration}
              currency={product.currency}
              description={product?.description}
              actionTitle={product.actionTitle}
              other={product.others}
              action={(e) => handleClick(product)}
              activePlan={plan}
              actionDesc={product?.actionDesc}
              paypalPlanId={product?.paypalPlanId}
            />
          </div>
        ))}
    </div>
  );
}
