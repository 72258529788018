import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Joi from "joi";
import { shortSignup2 } from "services/auth";

/*Common components import*/
import Button from "components/Shared/Button";
import SignupModal from "components/Modals/SignupModal";

/* toast alert import*/
import toast from "react-hot-toast";

/*AuthContext import*/
import { useAuth } from "contexts/auth-context";

import { session, store } from "constant/constant";

import { useEmailValidation } from "utils/validator";

/** fiedls validator */
const schema = Joi.object({
  // domain: Joi.string().uri().label("Domain").required(),
  domain: Joi.string()
    .regex(
      /^(https?:\/\/)?((?!-)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z]{2,})+)(\/.*)?$/
    )
    .label("Domain")
    .required()
    .messages({ "string.pattern.base": "* Invalid Domain" }),
});

export default function CardLead() {
  const history = useHistory();
  const [domain, setDomain] = useState("");
  const [loading, setLoading] = useState(false);
  const [clientError, setClientError] = useState(null);
  const authContext = useAuth();

  /*Handlers*/

  const handleClick = () => {
    let validator = schema.validate({ domain });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setLoading(true);
      shortSignup2({ domain: domain })
        .then(async (response) => {
          if (response.status === false) {
            toast.error(response.message);
            setLoading(false);
          } else {
            toast.success(response.message);
            let user = response.data;
            user.session = session.authenticated;
            user.url = domain;
            user.guestUser = true;

            if (localStorage.getItem(store.session) !== "") {
              localStorage.setItem(store.session, JSON.stringify(user));
              localStorage.setItem("userId", user.user_id);
            } else if (localStorage.getItem(store.session).email !== user.email)
              localStorage.setItem(store.session, JSON.stringify(user));
            authContext.dispatch({
              type: authContext.actions.SET,
              data: {
                role: user.role,
                authToken: user.token,
                userId: user.user_id,
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                status: session.authenticated,
                signupCompleted: user.sign_up,
                emailVerified: user.email_verified,
                signupDate: user.signed_up_date,
                url: domain,
                guestUser: true,
              },
            });
            history.push("/app/page-speed");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-tranparent border-0">
        <div className="flex-auto px-4 lg:px-10">
          <form onSubmit={handleClick}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="w-full flex flex-row">
                  <input
                    type="url"
                    className="border-2 px-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue=""
                    placeholder="https://domainname.com"
                    onChange={(e) => setDomain(e.target.value)}
                    required
                    style={{
                      borderColor: "black",
                    }}
                  />
                  <div
                    style={{
                      width: "150px",
                    }}
                  >
                    <Button
                      onClick={handleClick}
                      loading={loading}
                      text="Scan Now"
                      type="submit"
                      classes={
                        "bg-theme-primary text-white active:bg-blueGray-600 text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      }
                    />
                  </div>
                </div>
                <span>
                  {clientError && clientError?.context?.key === "domain" && (
                    <span className="flex items-center font-bold tracking-wide text-white text-xs mt-1 ml-1">
                      {clientError?.message}
                    </span>
                  )}
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
