import React, { useEffect, useState, useRef } from "react";
import Joi from "joi";
import toast from "react-hot-toast";
import { createPopper } from "@popperjs/core";
import menu from "assets/img/menu.png";

import { useHistory } from "react-router-dom";

/** utils import */
import { composeTitle } from "utils/helper";

/** constant import */
import { pages } from "constant/constant";

import { generateReport, getReportSchedule } from "services/report";

/** Components Import */
import ProjectDropDown from "components/Dropdowns/ProjectsDropDown";
import ProjectType from "components/Dropdowns/ReportType";
import Modal from "components/Modals/Modal";
import SentToModal from "components/Modals/SentToModal";
import MiniModal from "components/Modals/MiniModal";
import Button from "components/Shared/Button";

/** context import */
import { useAuth } from "contexts/auth-context";
import { useApp } from "contexts/app-context";

import useAccountMiddleware from "hooks/useAccountMiddleware";

const returnForm = (
  project,
  setProject,
  projectType,
  setProjectType,
  handleChange,
  handleGenerateReport,
  error,
  loading,
  reportLink,
  setProjectCount,
  cancelReport
) => {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold"></h6>
            {/* <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Settings
            </button> */}
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form id="reportForm" onSubmit={handleGenerateReport}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Report Info
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Choose Project
                  </label>
                  <ProjectDropDown
                    project={project}
                    setProject={setProject}
                    setProjectCount={setProjectCount}
                  />
                  {error && error?.context?.key === "domain" && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {error?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Report Name
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Report Name"
                    name="reportName"
                    onChange={handleChange}
                  />
                  {error && error?.context?.key === "reportName" && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {error?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Report Type
                  </label>
                  <ProjectType
                    projectType={projectType}
                    setProjectType={setProjectType}
                  />
                  {error && error?.context?.key === "reportType" && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {error?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Report Formatting
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Export Format
                  </label>
                  <div className="flex items-center gap-4">
                    <>
                      <input
                        type="radio"
                        name="reportFormat"
                        checked
                        onChange={handleChange}
                        data-value="pdf"
                        className="form-checkbox appearance-none mt-3  ml-1 w-5 h-5 ease-linear transition-all duration-150 border border-blueGray-300 rounded checked:bg-blueGray-700 checked:border-blueGray-700 focus:border-blueGray-300"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-500 mt-3">
                        PDF
                      </span>
                    </>
                    <>
                      {/* <input
                        type="radio"
                        name="reportFormat"
                        data-value="csv"
                        onChange={handleChange}
                        className="form-checkbox appearance-none mt-3  ml-1 w-5 h-5 ease-linear transition-all duration-150 border border-blueGray-300 rounded checked:bg-blueGray-700 checked:border-blueGray-700 focus:border-blueGray-300"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-500 mt-3">
                        CSV
                      </span> */}
                    </>
                  </div>
                  {error && error?.context?.key === "reportFormat" && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {error?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3 d-flex align-items-center">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Delivery
                  </label>
                  <div className="flex items-center gap-4">
                    <>
                      <input
                        type="radio"
                        name="delivery"
                        onChange={handleChange}
                        data-value="email"
                        className="form-checkbox appearance-none mt-3  ml-1 w-5 h-5 ease-linear transition-all duration-150 border border-blueGray-300 rounded checked:bg-blueGray-700 checked:border-blueGray-700 focus:border-blueGray-300"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-500 mt-3">
                        Set email Delivery
                      </span>
                    </>
                    <>
                      <input
                        type="radio"
                        name="delivery"
                        data-value="download"
                        onChange={handleChange}
                        className="form-checkbox appearance-none mt-3  ml-1 w-5 h-5 ease-linear transition-all duration-150 border border-blueGray-300 rounded checked:bg-blueGray-700 checked:border-blueGray-700 focus:border-blueGray-300"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-500 mt-3">
                        Download Now
                      </span>
                      {/* <Popover /> */}
                    </>
                  </div>
                  {error && error?.context?.key === "delivery" && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {error?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <div className="mb-12 justify-center flex flex-wrap w-full mt-5">
              {loading && (
                <button
                  className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-red-500 border-red-500 active:bg-red-600 active:border-red-600 active:text-white text-sm px-6 py-2 shadow hover:shadow-lg rounded-md"
                  onClick={cancelReport}
                >
                  Cancel
                </button>
              )}
              {reportLink ? (
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={reportLink}
                  className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out  border border-solid font-bold last:mr-0 mr-2  border-lightBlue-500 hover:bg-lightBlue-500 hover:text-red-500 active:bg-lightBlue-600 active:text-white text-sm px-6 py-2 shadow hover:shadow-lg rounded-md"
                >
                  Click here to Download
                </a>
              ) : (
                <Button
                  classes="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  text={"Generate Report"}
                  onClick={() => { }}
                  loading={loading}
                  type={"submit"}
                ></Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const schema = Joi.object({
  domain: Joi.string().required(),
  reportName: Joi.string().required(),
  reportType: Joi.string().required(),
  reportFormat: Joi.string().required(),
  delivery: Joi.string().required(),
});

const Reports = () => {
  const [
    isSignUpCompleted,
    isPlanSubscribed,
    plan,
    ifSubscriptionCancelled,
    cancelPlan,
  ] = useAccountMiddleware();
  const abortController = useRef(null);
  const authContext = useAuth();
  const appContext = useApp();
  const history = useHistory();
  const [openTab, setOpenTab] = useState(1);
  const [project, setProject] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [day, setDay] = useState(1);
  const [isMounted, setIsMounted] = useState(false);
  const [showMiniModal, setShowMiniModal] = useState({
    show: false,
    when: day,
  });
  const [projectCount, setProjectCount] = useState(null);
  const [reportLink, setReportLink] = useState(null);
  const [state, setState] = useState({
    reportType: "",
    reportFormat: "pdf",
    delivery: "",
  });
  const [reports, setReports] = useState([]);
  const [showModal, setShowModal] = useState(
    appContext?.state?.showModal || false
  );

  const [showSentToModal, setShowSentToModal] = useState(false);
  const [reportProject, setReportProject] = useState(null);
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(null);
  const btnDropdownRefs = useRef([]);
  const popoverDropdownRefs = useRef([]);

  // // Function to toggle the dropdown popover for a specific row
  const toggleDropdownPopover = (index) => {
    setDropdownPopoverShow((prevState) => (prevState === index ? null : index));
  };

  // // Function to close the dropdown popover when a click occurs outside of it
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(null);
  };

  // const sendTo = () => {};

  useEffect(() => {
    btnDropdownRefs.current = btnDropdownRefs.current.slice(0, reports.length);
    popoverDropdownRefs.current = popoverDropdownRefs.current.slice(
      0,
      reports.length
    );
  }, [reports]);

  useEffect(() => {
    btnDropdownRefs.current.forEach((btnRef, index) => {
      const popoverDropdownRef = popoverDropdownRefs.current[index];
      const popoverInstance = createPopper(btnRef, popoverDropdownRef, {
        placement: "bottom-start",
        strategy: "fixed",
      });
    });

    //   // Event listener to close the dropdown popover when a click occurs outside of it
    const handleClickOutside = (event) => {
      // Check if the click event target is outside of any popover element
      const isOutsidePopover = popoverDropdownRefs?.current?.every(
        (popoverRef, index) =>
          !popoverRef?.contains(event.target) &&
          !btnDropdownRefs?.current[index]?.contains(event.target)
      );

      // Close the dropdown popover if the click is outside
      if (isOutsidePopover) {
        closeDropdownPopover();
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [reports]);

  const hideModal = () => {
    setShowModal(!showModal);
    appContext.dispatch({
      type: appContext.actions.UPDATE,
      data: {
        showModal: !showModal,
      },
    });
  };

  // useEffect(() => {
  //   setIsMounted(true);
  //   if (isMounted) {
  //     const closeDropDown = (e) => {
  //       if (
  //         btnDropdownRef.current &&
  //         !btnDropdownRef.current.contains(e.target)
  //       ) {
  //         closeDropdownPopover();
  //       }
  //     };

  //     document.addEventListener("click", closeDropDown, true);

  //     // Cleanup function
  //     return () => {
  //       document.removeEventListener("click", closeDropDown, true);
  //       setIsMounted(false);
  //     };
  //   }
  // }, [isMounted]);

  // Function to toggle the dropdown popover for a specific row

  // useEffect(() => {
  //   if (reportLink !== null) {
  //     document.getElementById("reportForm").reset();
  //     setProject("");
  //     setProjectType("");
  //     setState({
  //       ...state,
  //       reportType: "",
  //       reportFormat: "pdf",
  //     });
  //   }
  // }, [reportLink]);
  useEffect(() => {
    setShowModal(appContext.state.showModal);
  }, [appContext.state.showModal]);

  // useEffect(() => {
  //   if(project && project.domain) setReportLink(null);
  // }, [project])

  const handleChange = (event) => {
    setReportLink(null);
    let value = "";
    const name = event.target.name;
    if (event.target.type === "radio") {
      value = event.target.getAttribute("data-value");
      if (event.target.getAttribute("data-value") === "email")
        setShowMiniModal({
          show: true,
          when: day,
        });

      setDay(showMiniModal.when);
    } else value = event.target.value;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleGenerateReport = async (event) => {
    event.preventDefault();
    if (isSignUpCompleted() === false) {
      history.push("/auth/register");
      return;
    }
    if ((!plan || (plan && plan?.title === "Free")) && cancelPlan === null) {
      toast.error("You do not subscribed any plan!");
      setTimeout(() => {
        history.push("/app/subscription");
      }, 1000);
      return;
    }
    setLoading(true);
    let domain = project?.domain;
    let reportName = state?.reportName;
    let reportType = projectType?.title;
    let reportFormat = state?.reportFormat;
    let delivery = state?.delivery;
    let validator = schema.validate({
      domain,
      reportName,
      reportType,
      reportFormat,
      delivery,
    });
    if (validator?.error) {
      setError(validator.error.details?.[0]);
      setLoading(false);
      return;
    } else {
      setError(null);
      setLoading(true);
      abortController.current = new AbortController();
      await generateReport(
        authContext.state.authToken,
        project?.domain,
        state?.reportName,
        projectType?.title,
        state?.reportFormat.toLowerCase(),
        state?.delivery.toLowerCase(),
        showMiniModal,
        abortController
      )
        .then(async (response) => {
          if (response.status === false) {
            if (response.name === "AbortError") {
              console.log("Request aborted");
            } else {
              toast.error(response?.message || response?.error?.message);
            }
            setLoading(false);
          } else {
            setReportLink(response.url);

            setTimeout(() => {
              if (response.name === "AbortError") {
                console.log("Request aborted");
              } else {
                toast.success(response?.message);
              }
              setLoading(false);
            }, 2000);
            handleReportSchedule();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.name === "AbortError") {
            console.log("Request aborted");
          } else {
            toast.error(error);
          }
        });
    }
  };

  const cancelReport = () => {
    abortController.current && abortController.current.abort();
    setLoading(false);
  };

  const handleReportSchedule = async () => {
    await getReportSchedule(authContext.state.authToken)
      .then(async (response) => {
        if (response.status === false) {
          toast.error(response.message);
          setLoading(false);
        } else {
          setTimeout(() => {
            setLoading(false);
            setReports(response);
          }, 2000);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  useEffect(() => {
    setIsMounted(true);
    composeTitle(pages?.reportCenter);
    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.reportCenter,
      },
    });

    if (authContext?.state?.guestUser) {
      authContext.dispatch({
        type: authContext.actions.SET,
        data: {
          guestUser: false,
        },
      });
      localStorage.setItem("previousPage", "/app/page-speed");
      localStorage.removeItem("persist");
      history.push("/auth/register");
    }

    if (isMounted) {
      if (reports.length === 0) {
        handleReportSchedule();
      }
      isPlanSubscribed();
      ifSubscriptionCancelled();
    }

    return () => {
      setIsMounted(false);
    };
    localStorage.removeItem("openTab");
  }, [isMounted]);

  return (
    <>
      <MiniModal
        showMiniModal={showMiniModal}
        setShowMiniModal={setShowMiniModal}
        when={day}
      ></MiniModal>
      <Modal showModal={showModal} hideModal={hideModal} />
      <SentToModal
        showSentToModal={showSentToModal}
        setShowSentToModal={setShowSentToModal}
        project={reportProject}
      />
      <div className="w-full h-screen overflow-x-hidden">
        <div className="bg-white mb-0 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
          <div className="w-full px-4 mx-auto px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="px-4 relative w-full md:w-12/12">
                <div className="relative py-12">
                  <section className="text-center block  mb-4"></section>
                  <section className="block">
                    <ul
                      className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                      role="tablist"
                    >
                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                            (openTab === 1
                              ? "text-white bg-lightBlue-600"
                              : "text-lightBlue-600 bg-white")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(1);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          <i className="fas fa-folder-open text-base mr-1"></i>{" "}
                          Create
                        </a>
                      </li>
                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                            (openTab === 2
                              ? "text-white bg-lightBlue-600"
                              : "text-lightBlue-600 bg-white")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          <i className="far fa-clone text-base mr-1"></i> Report
                          Schedule
                        </a>
                      </li>
                    </ul>
                  </section>
                  <div className="mt-4"></div>
                </div>
              </div>
              <div className="ml-auto px-4 relative w-full md:w-12/12 justify-center">
                {openTab === 1 && (
                  <div className="relative">
                    <div className="duration-500 transition-all ease-in-out block">
                      <div className="mb-6">
                        <header className="block relative">
                          <h2 className="text-2xl font-semibold mt-0 mb-2">
                            Create Report
                          </h2>
                        </header>
                        <hr className="w-full mt-2 mb-8 h-0 border-blueGray-400" />
                        {returnForm(
                          project,
                          setProject,
                          projectType,
                          setProjectType,
                          handleChange,
                          handleGenerateReport,
                          error,
                          loading,
                          reportLink,
                          setProjectCount,
                          cancelReport
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {openTab === 2 && (
                  <div className="relative overflow-x-scroll">
                    <div className="my-8 duration-500 transition-all ease-in-out block">
                      <div className="mb-6">
                        <header className="block relative">
                          <h2 className="text-2xl font-semibold mt-0 mb-2">
                            Report List Schedule
                          </h2>
                        </header>
                        <hr className="w-full mt-2 mb-8 h-0 border-blueGray-400" />
                        <table className="w-full mb-4 text-blueGray-800 border-collapse items-center">
                          {reports.length > 0 && (
                            <thead className="items-center">
                              <tr className="text-left">
                                <th className="align-middle font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Project</small>
                                </th>
                                <th className="align-middle d  font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Type</small>
                                </th>
                                <th className="align-middle d  font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Format</small>
                                </th>
                                <th className="align-middle  font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Date</small>
                                </th>
                                <th className="align-middle  font-bold text-sm p-3 border-t text-blueGray-500">
                                  <small>Action</small>
                                </th>
                              </tr>
                            </thead>
                          )}
                          <tbody className="">
                            {reports &&
                              reports.map((report, index) => (
                                <tr key={index}>
                                  <td className="align-middle font-normal p-3 border-t">
                                    {report?.title}
                                  </td>
                                  <td className="align-middle  font-normal p-3 border-t">
                                    <label className="inline-flex items-center">
                                      {report?.type}
                                    </label>
                                  </td>
                                  <td className="align-middle  font-normal p-3 border-t">
                                    <label className="inline-flex items-center">
                                      {report?.format}
                                    </label>
                                  </td>
                                  <td className="align-middle  font-normal p-3 border-t">
                                    <label className="inline-flex items-center">
                                      {report?.created_at}
                                    </label>
                                  </td>
                                  <td className="align-middle  font-normal p-3 border-t">
                                    <div
                                      className="relative w-auto pl-4 flex-initial cursor-pointer"
                                      ref={(el) =>
                                        (btnDropdownRefs.current[index] = el)
                                      }
                                      onClick={() =>
                                        toggleDropdownPopover(index)
                                      }
                                    >
                                      <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow rounded-full hover:shadow-lg cursor-pointer">
                                        <img src={menu} width="50" />
                                      </div>
                                    </div>

                                    <div
                                      ref={(el) =>
                                      (popoverDropdownRefs.current[index] =
                                        el)
                                      }
                                      className={
                                        dropdownPopoverShow === index
                                          ? "block absolute z-40 bg-blueGray-50 rounded shadow-lg p-4"
                                          : "hidden"
                                      }
                                    >
                                      <ul className="cursor-pointer">
                                        <li className="pb-2">
                                          <button
                                            onClick={() => {
                                              setShowSentToModal(true);
                                              setReportProject(report);
                                            }}
                                          >
                                            Send To
                                          </button>
                                        </li>
                                        <div className="border border-solid border-blueGray-200"></div>
                                        <div className="border border-solid border-blueGray-200"></div>
                                        <li className="pt-2">
                                          <a
                                            href={
                                              "https://webwatch.marketmediaconnect.com/" +
                                              "/uploads/pdf/" +
                                              report.reportName
                                            }
                                            target="_blank"
                                          >
                                            <button>Download</button>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                  {/* <td className="align-middle  font-normal p-3 border-t">
                                    <a
                                      href={
                                        "http://35.85.40.81:8080" +
                                        "/uploads/pdf/" +
                                        report.reportName
                                      }
                                      target="_blank"
                                    >
                                      <button className="text-xs font-bold py-2 shadow-lg rounded block leading-normal text-white bg-lightBlue-600 px-2">
                                        Download
                                      </button>
                                    </a>
                                  </td> */}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
