import React from "react";

const PercentageProgressBar = ({ domain, percentage }) => {
  return (
    <>
      <div>
        Checking {domain}, please wait...{" "}
        <span className="text-lightBlue-500">{percentage}%</span>
      </div>
      <div className="w-full bg-blueGray-600 rounded-full mt-3">
        <div
          className="bg-lightBlue-500 p-0.5  py-1 rounded-full h-1.5"
          style={{ width: percentage + "%" }}
        ></div>
      </div>
    </>
  );
};

export default PercentageProgressBar;
