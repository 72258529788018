import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { Toaster } from "react-hot-toast";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/custom.css";
// Data Providers
import { AuthProvider } from "./contexts/auth-context";
import { AppProvider } from "contexts/app-context";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

import { ProtectedRoute } from "routes/ProtectedRoute";

// views without layouts

import Index from "views/Index.js";
import WebAnalyzer from "views/app/WebReport/WebAnalyzer"; // Import the WebAnalyzer component
import pageSpeed from "views/app/WebReport/PageSpeed"; // Import the pageSpeed component


ReactDOM.render(
  <BrowserRouter>
    <Toaster
      position="bottom-left"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        className: "",
        duration: 5000,
        style: {
          background: "#363636",
          color: "#fff",
        },

        // Default options for specific types
        success: {
          duration: 3000,
          theme: {
            primary: "green",
            secondary: "black",
          },
        },
      }}
    />
    <Switch>
      {/* add routes with layouts */}
      <AuthProvider>
        <AppProvider>
          <ProtectedRoute path="/app" component={Admin} />
          <Route path="/auth" component={Auth} />
          {/* add routes without layouts */}
          <Route path="/" exact component={Index} />
        </AppProvider>
        <Route path="/WebAnalyzer" component={WebAnalyzer} />
        <Route path="/pageSpeed" component={pageSpeed} />
      </AuthProvider>
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
