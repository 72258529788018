import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import "assets/styles/tailwind.css";

import CardLead from "components/Cards/CardLead";
import CircleImages from "components/CircleImages.js";
import FooterNew from "components/Footers/FooterNew";
import roundIcon from 'assets/images/round-icons/save.png';
import contentImage from 'assets/images/round-icons/content.png';
import actImage from 'assets/images/round-icons/act.png';
import stepImage from 'assets/images/step-4.png';
import heroIllustration from 'assets/images/hero-illustration.png';

import IndexNavbar from "components/Navbars/IndexNavbar.js";

const SeoCheckerSteps = () => {
  const [seoSteps, setSeoSteps] = useState(0);

  const images = [
    { src: 'steps.png', alt: 'Image 1' },
    { src: 'step-1.png', alt: 'Image 1', title: 'STEP 1', text: 'Add the page or website as a project for which you want to check the Speed.' },
    { src: 'step-2.png', alt: 'Image 2', title: 'STEP 2', text: 'On the second screen from projects, choose your project.' },
    { src: 'step-3.png', alt: 'Image 3', title: 'STEP 3', text: 'After clicking on Analyze, you will get the complete Page Speed report of your website, both for mobile and desktop.' },
    { src: 'step-4.png', alt: 'Image 4', title: 'STEP 4', text: 'Go to generate a report and get a Free Website Speed Test report in your email or directly download the report'},
    { src: 'step-5.png', alt: 'Image 5', title: 'STEP 5', text: 'Under the report schedule, you can see all the previous reports for your website for better comparison.' },
  ];

  return (
    <>
      <div className="md:grid md:grid-cols-6 md:gap-10">
        <div className="flex items-center col-span-4">
          <img src={stepImage} alt="" className={`mx-auto h-96`} />
        </div>
        <div className="border-l-2 border-theme-primary col-span-2 flex items-center">
          <div>
            {
              images.map((image, index) => (
                index > 0 && (
                  <div key={index} className="mx-12 flex p-4 rounded-md hover:bg-theme-primary hover:font-medium hover:text-white" onMouseEnter={() => setSeoSteps(index)} onMouseLeave={() => setSeoSteps(0)}>
                    <div>
                      <h3 className="w-20 text-xl font-medium">{image.title}</h3>
                    </div>
                    <div className="">
                      <p>{image.text}</p>
                    </div>
                  </div>
                )
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
};

const PageSpeed = () => {
  const [activeImage, setActiveImage] = useState(0);

  const images = [
    { src: roundIcon, alt: 'Image 1', title: <>BACKLINK <span className="block">IDEAS</span></>, description: 'Description 1', titleStyle: { left: '-170', top: '-160' }, },
    { src: contentImage, alt: 'Image 2', title: <>Technical <span className="block"> SEO IDEAS</span></>, description: 'Description 2', titleStyle: { left: '-100', top: '-190' }, },
    { src: actImage, alt: 'Image 3', title: <>SEMANTIC <span className="block">IDEAS</span></>, description: 'Description 3', titleStyle: { left: '-210', top: '-160' }, },
    // Add the rest of your images here with their corresponding imports
  ];

  const content = [
    {
      src: roundIcon, alt: 'backlink ideas', title: 'Backlink Ideas', content: <>Lorem ipsum...</>
    },
    {
      src: contentImage, alt: 'technical ideas', title: 'Technical SEO Ideas', content: <>Lorem ipsum...</>
    },
    {
      src: actImage, alt: 'semantic ideas', title: 'Semantic Ideas', content: <>Lorem ipsum...</>
    },
    // Add the rest of your content here with their corresponding imports
  ];

  const calculateImageAngle = (index) => {
    return (360 / images.length) * (index + 0.5); // Add 0.5 to center the title below the image
  };

  return (
    <>
      <IndexNavbar />

      <div className="bg-hero bg-cover bg-no-repeat place-items-center relative">
        <div className="hidden lg:block">
          <img src={heroIllustration} alt="" className='ml-auto h-full w-3/4 2xl:w-3/5' />
        </div>
        <div className="lg:absolute lg:top-0 w-full h-full lg:flex place-items-center">
          <div className="container mx-auto p-8">
            <div className="lg:grid lg:grid-cols-2">
              <div className='py-8 text-white'>
                <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold md:leading-[55px] lg:leading-[69px] mb-5"><span>Page Speed </span><span className="block">Checker</span><span className="block"></span></h1>
                <p className="text-white mt-5 w-full md:w-2/3">
                  Track Your Website's Performance with Our Page Speed Checker
                </p>
                <div className="mt-5 text-center">
                  <CardLead />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-5 lg:mt-12">
        <img src={stepImage} alt="" className='mx-auto h-full w-11/12' />
      </div>
      <div className="bg-rank bg-cover  bg-no-repeat text-white w-full md:-mt-[15vh] lg:-mt-[25vh] xl:-mt-[50vh] z-50 relative">
        <div className="container mx-auto p-8 lg:pb-16 pt-36 md:mt-5 lg:mt-8">
          <h2 className="text-3xl md:text-4xl lg:text-5xl md:leading-[45px] lg:leading-[60px] font-medium mb-3 uppercase"><span>Why is Website Speed  </span><span className="md:block">Optimization Important?</span></h2>
          <div className="md:grid  gap-10 w-full lg:gap-8 mt-10">
            <div className="w-full">
              <p>Website Speed Optimization is an essential factor for user experience and Search Engine Rankings. A website that loads quickly retains visitors, reduces bounce rate, and enhances user engagement. Our Website Speed Checker is designed to provide you with comprehensive insights into your website's performance. Here's why it's important.</p>
              <div>
                <p className="mt-3 flex place-items-center"><FaCheckCircle className="mr-3 text-xl" /> <span className="font-bold mr-2">Improved User Experience:</span> Fast-loading pages keep users engaged, reducing bounce rates and encouraging more extended site visits.
                </p>
                <p className="mt-3 flex place-items-center"><FaCheckCircle className="mr-3 text-xl" /> <span className="font-bold mr-2">Higher Search Engine Rankings: </span>Search engines like Google, favor fast websites, boosting their visibility in search results.
                </p>
                <p className="mt-3 flex place-items-center"><FaCheckCircle className="mr-3 text-xl" /> <span className="font-bold mr-2">Increased Conversion Rates:</span>Users are more likely to complete purchases or sign-ups on a website that loads quickly.
                </p>
                <p className="mt-3 flex place-items-center"><FaCheckCircle className="mr-3 text-xl" /> <span className="font-bold mr-2">Enhanced Mobile Performance:</span> With the rise of mobile browsing, fast load times are essential for a seamless mobile experience.
                </p>
                <p className="mt-3 flex place-items-center"><FaCheckCircle className="mr-3 text-xl" /> <span className="font-bold mr-2">Better Brand Perception:</span> Quick load times contribute to a positive impression of your brand, showcasing professionalism and reliability
                </p>

              </div>

            </div>
            <div className="">
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-8 lg:mt-8">
        <CircleImages />
      </div>
      <div className="container mx-auto p-8">
        <h2 className="text-3xl md:text-4xl lg:text-5xl md:leading-[45px] lg:leading-[60px] font-medium mb-3 uppercase text-theme-secondary"><span>how to use </span><span className="md:block">a Website Speed Checker?</span></h2>
        <SeoCheckerSteps />
      </div>
      <div className="container mx-auto rounded-2xl lg:px-20">
        <div className="md:grid md:grid-cols-3 my-8">
          <div className="grid grid-cols-5 p-4">
            <div className="flex items-center">
              <img src={actImage} alt="" className='mx-auto' />
            </div>
            <div className="flex items-center col-span-4">
              <h3 className="text-theme-secondary font-medium text-xl lg:text-2xl uppercase">Optimization Suggestions:</h3>
            </div>
            <div className="col-span-5">
              <p className="text-theme-basic">Our Website Speed Optimization tools will provide customized suggestions to improve your website Speed.</p>
            </div>
          </div>
          <div className="grid grid-cols-5 p-4">
            <div className="flex items-center">
              <img src={contentImage} className='mx-auto' />
            </div>
            <div className="flex items-center col-span-4">
              <h3 className="text-theme-secondary font-medium text-xl lg:text-2xl uppercase">Comprehensive Analysis:</h3>
            </div>
            <div className="col-span-5">
              <p className="text-theme-basic">Our Website Speed Checker gives you a comprehensive report of your website's Speed on mobile and desktop.</p>
            </div>
          </div>
          <div className="grid grid-cols-5 p-4">
            <div className="flex items-center">
              <img src={roundIcon} alt="" className='mx-auto' />
            </div>
            <div className="flex items-center col-span-4">
              <h3 className="text-theme-secondary font-medium text-xl lg:text-2xl uppercase">Real-Time Results: </h3>
            </div>
            <div className="col-span-5">
              <p className="text-theme-basic">Enjoy the efficiency of real-time testing for immediate insights into your website's performance with our free Website Speed Checker.</p>
            </div>
          </div>
        </div>
        <div className="bg-get-started bg-cover bg-no-repeat text-white text-center p-8 lg:p-16">
          <h2 className="text-3xl lg:text-4xl lg:leading-[45px] font-medium"><span>Don't Let a Slow Website Hold You Back! Accelerate Your Website Now with our </span> <span className="block">Website Speed Checker</span></h2>
          <button className="block mx-auto mt-5 px-6 py-2 font-medium text-lg bg-theme-primary hover:bg-theme-secondary rounded shadow-lg">Free Website Speed Test Now</button>
        </div>
      </div>

      <FooterNew />
    </>
  )
};

export default PageSpeed;
