import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "contexts/auth-context";
import { session } from "constant/constant";
export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const authContext = useAuth();
  const history = useHistory().location.pathname;
  if (
    history.includes("/app/project-detail/") &&
    localStorage.getItem("persist") === null
  ) {
    localStorage.setItem("currentPage", history);
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authContext.state.status === session.authenticated) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
