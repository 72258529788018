import React from "react";

const ProjectDetailTable = ({ items }) => {
  return items && items?.length > 0 && items[0]?.url ? (
    <div className="block w-full overflow-x-auto pt-20">
      <table className="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md font-arimo uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              URL
            </th>
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md font-arimo uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              Resource Size
            </th>
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-md font-arimo uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              Potential Savings
            </th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item, index) => (
              <tr key={index}>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm font-arimo whitespace-nowrap p-4">
                  <a
                    href={item?.url}
                    target="_blank"
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 font-arimo"
                  >
                    {item?.url?.length > 20
                      ? item?.url.substring(0, 50) + "....."
                      : item?.url}
                  </a>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {item?.totalBytes >= 1024
                    ? Math.round(item.totalBytes / 1024)
                    : "---"}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {item?.totalBytes ? item.totalBytes : "---"}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  ) : (
    ""
  );
};

export default ProjectDetailTable;
