import React, { useEffect, useRef, useState } from "react";

/** service import */
import { fetchProjectList } from "services/dashboard";

import { useHistory } from "react-router-dom";
import { session } from "constant/constant";

import toast from "react-hot-toast";
/** context import */
import { useAuth } from "contexts/auth-context";
import { useApp } from "contexts/app-context";

/** constant import */
import { pages } from "constant/constant";

// components
//import CardLineChart from "components/Cards/CardLineChart.js";
//import CardBarChart from "components/Cards/CardBarChart.js";
import Modal from "components/Modals/Modal";
//import CardPageVisits from "components/Cards/CardPageVisits.js";
//import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";
import CardProjects from "components/Cards/CardProject";
/** utils import */
import { composeTitle } from "utils/helper";

export default function Dashboard() {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [projectsFilter, setProjectsFilter] = useState(null);
  const authContext = useAuth();
  const appContext = useApp();
  const [showModal, setShowModal] = useState(
    appContext?.state?.showModal || false
  );

  useEffect(() => {
    if (projectsFilter) {
      const newFilteredProjects = projects.filter(
        (project) => project.uuid !== projectsFilter
      );
      setProjects(newFilteredProjects);
      setProjectsFilter(null);
    }
  }, [projectsFilter, projects]);
  const hideModal = () => {
    setShowModal(!showModal);
    appContext.dispatch({
      type: appContext.actions.UPDATE,
      data: {
        showModal: !showModal,
      },
    });
    if (showModal) {
      getProjectList();
    }
  };

  const getProjectList = async () => {
    try {
      await fetchProjectList(authContext.state.authToken).then((response) => {
        if (response.status === false) {
          toast.error(response.message);
        } else {
          setProjects(response?.data);
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    composeTitle(pages?.dashboard);
    appContext.dispatch({
      type: appContext.actions.SET,
      data: {
        pageTitle: pages?.dashboard,
      },
    });

    if (authContext?.state?.guestUser) {
      authContext.dispatch({
        type: authContext.actions.SET,
        data: {
          guestUser: false,
        },
      });
      localStorage.setItem("previousPage", "/app/page-speed");
      localStorage.removeItem("persist");
      history.push("/auth/register");
    }
    localStorage.removeItem("openTab");
  }, []);

  useEffect(() => {
    getProjectList();
  }, []);

  useEffect(() => {
    setShowModal(appContext.state.showModal);
  }, [appContext.state.showModal]);
  return (
    <>
      <Modal showModal={showModal} hideModal={hideModal} />
      {/* <div className="flex flex-wrap">
      
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <CardLineChart />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardBarChart />
        </div>
      </div> */}
      <div className="h-screen">
        <div className="flex flex-wrap mt-4">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
            {projects && projects.length > 0 && (
              <CardProjects
                projects={projects}
                setProjectsFilter={setProjectsFilter}
              />
            )}
          </div>
          {/* <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <CardPageVisits />
          </div> */}
          {/* <div className="w-full xl:w-4/12 px-4">
            <CardSocialTraffic />
          </div> */}
        </div>
      </div>
    </>
  );
}
