import React, { useState, useEffect } from "react";
import Joi from "joi";
import toast from "react-hot-toast";
import { Link, useHistory } from "react-router-dom";

/** common import */
import { composeTitle } from "utils/helper";
import Button from "components/Shared/Button";

/*Service import*/
import { login } from "services/auth";

/*AuthContext import*/
import { useAuth } from "contexts/auth-context";

/**backend service import */
import { signup } from "services/auth";

/** constant import */
import { session, store } from "constant/constant";

import signup_image from "assets/img/signup_image.png";
import logo_dark from "assets/logo/logo-dark.png";

/** fiedls validator */
const schema = Joi.object({
  firstName: Joi.string()
    .pattern(/^[A-Za-z\s]+$/)
    .min(3)
    .max(30)
    .required()
    .messages({
      "string.pattern.base": `"First Name" must contain only alphabetic characters`,
      "string.empty": '"First Name" is required'
    }),
  lastName: Joi.string()
    .pattern(/^[A-Za-z\s]+$/)
    .min(3)
    .max(30)
    .required()
    .messages({
      "string.pattern.base": `Last Name must contain only alphabetic characters`,
      "string.empty": '"Last Name" is required'
    }),
  email: Joi.string()
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: ["com", "net"] },
    }).messages({
      "string.empty": '"Email" is required'
    }),
  phone: Joi.number().integer().required().messages({
    "string.empty": '"Phone Number" is required'
  }),
  // password: Joi.string()
  //   .pattern(new RegExp("^[a-zA-Z0-9]{3,30}$"))
  //   .min(8)
  //   .messages({
  //     "string.pattern.base": `Password must contain alphanumeric characters`,
  //   }),
  password: Joi.string().min(8).required().messages({
    "string.empty": '"Password" is required'
  }),
  checkbox: Joi.boolean().valid(true).messages({
    "any.only": `You must be agreed to Privacy Policy`,
  }),
});

export default function Register() {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    checkbox: false,
  });
  const [isGuestUser, setIsGuestUser] = useState(false);
  const authContext = useAuth();
  const [clientError, setClientError] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const userId = localStorage.getItem("userId") || "";
  const handleClick = async (e) => {
    let validator = schema.validate({ ...user });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setClientError(null);

      try {
        setLoading(true);
        const response = await signup({ ...user, userId });
        if (response?.status === false) {
          setLoading(false);
          toast.error(response.message);
        } else {
          if (localStorage.getItem("userId") === null) {
            setLoading(false);
            toast.success(response.message);
            history.push("/auth/login");
          } else if (localStorage.getItem("previousPage")) {
            try {
              localStorage.removeItem(store.session);
              localStorage.removeItem("userId");
              setLoading(true);
              const response = await login({ ...user });
              if (response?.status === false) {
                setLoading(false);
                toast.error(response.message);
              } else {
                setLoading(false);
                let user = response;
                user.session = session.authenticated;
                localStorage.setItem(store.session, JSON.stringify(user));
                await authContext.dispatch({
                  type: authContext.actions.SET,
                  data: {
                    role: user.role,
                    authToken: user.token,
                    userId: user.user_id,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    email: user.email,
                    status: session.authenticated,
                    signupCompleted: user.sign_up,
                    emailVerified: user.email_verified,
                    signupDate: user.signed_up_date,
                    url: "",
                    guestUser: false,
                  },
                });
                if (
                  localStorage.getItem("previousPage") === "/app/page-speed"
                ) {
                  history.push("/app/subscription");
                } else {
                  history.push("/app/dashboard");
                }
              }
            } catch (err) {
              setLoading(false);
              let message =
                new Error(err)?.message ||
                "Something went wrong. Please Check you connection & try again";
              toast.error(message);
            }
          } else {
            history.push("/auth/login");
          }
        }
      } catch (err) {
        setLoading(false);
        toast.error(err);
      }
    }
  };

  useEffect(() => {
    composeTitle("Sign up");

    if (localStorage.getItem("persist")) {
      history.push("/app/dashboard");
    }

    if (localStorage.getItem("userId")) {
      setIsGuestUser(true);
    }
  }, []);
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full px-4 flex justify-center">
            <div className="lg:w-8/12 relative flex  min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <div className="lg:w-6/12 hidden md:block">
                <Link to="/">
                  <img
                    src={signup_image}
                    alt="Signup image"
                    className="h-full w-full"
                  />
                </Link>
                <div className="lg:w-6/12 w-full md:w-6/12 absolute top-0 mt-10 left-0 z-10 flex h-auto flex-col justify-center items-center">
                  <img
                    src={logo_dark}
                    alt="Signup image"
                    height="100"
                    width="200"
                  />
                  <p className="text-2xl text-white mt-20 md:mt-10 text-center">
                    Signup to continue access
                  </p>
                </div>
              </div>
              <div className="lg:w-6/12 w-full md:w-8/12">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Sign up
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <div className="text-blueGray-400 text-center mb-3 font-bold">
                    <small>
                      Signup for free! <b>It's quick & easy.</b>
                    </small>
                  </div>
                  <form>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="First Name"
                        autoComplete="off"
                        onChange={(e) =>
                          setUser({ ...user, firstName: e.target.value })
                        }
                        value={user.firstName}
                        required
                      />
                      {clientError &&
                        clientError?.context?.key === "firstName" && (
                          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                            {clientError?.message}
                          </span>
                        )}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Last Name"
                        autoComplete="off"
                        onChange={(e) =>
                          setUser({ ...user, lastName: e.target.value })
                        }
                        value={user.lastName}
                        required
                      />
                      {clientError &&
                        clientError?.context?.key === "lastName" && (
                          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                            {clientError?.message}
                          </span>
                        )}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        autoComplete="off"
                        onChange={(e) =>
                          setUser({ ...user, email: e.target.value })
                        }
                        value={user.email}
                        required
                      />
                      {clientError && clientError?.context?.key === "email" && (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {clientError?.message}
                        </span>
                      )}
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Phone number"
                        autoComplete="off"
                        onChange={(e) =>
                          setUser({ ...user, phone: e.target.value })
                        }
                        value={user.phone}
                        required
                      />
                      {clientError && clientError?.context?.key === "phone" && (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {clientError?.message}
                        </span>
                      )}
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                        autoComplete="off"
                        onChange={(e) =>
                          setUser({ ...user, password: e.target.value })
                        }
                        value={user.password}
                        required
                      />
                      {clientError &&
                        clientError?.context?.key === "password" && (
                          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                            {clientError?.message}
                          </span>
                        )}
                    </div>

                    <div className="relative w-full mb-3">
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          id="customCheckLogin"
                          type="checkbox"
                          className="form-checkbox border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                          onChange={(e) =>
                            setUser({ ...user, checkbox: e.target.checked })
                          }
                          value={user.checkbox}
                          required
                        />
                        <span className="ml-2 text-sm font-semibold text-blueGray-600">
                          I agree with the{" "}
                          <a
                            href="https://marketmediaconnect.com/privacy-policy/"
                            className="text-lightBlue-500"
                          >
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                      {clientError &&
                        clientError?.context?.key === "checkbox" && (
                          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                            {clientError?.message}
                          </span>
                        )}
                    </div>

                    <div className="text-center mt-6">
                      <Button
                        classes="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        text={"Create Account"}
                        onClick={handleClick}
                        loading={loading}
                      ></Button>
                    </div>
                  </form>
                  {!isGuestUser && (
                    <p className="mt-5">
                      <span className="">
                        Already have an account{" "}
                        <Link className="underline font-bold" to="/auth/login">
                          Login Now
                        </Link>
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
