import React, { useEffect, useState } from "react";
import { useAuth } from "contexts/auth-context";
import Joi from "joi";
import { sentReport } from "services/report";
import toast from "react-hot-toast";
import Button from "components/Shared/Button";

export default function SendToModal({
  showSentToModal,
  setShowSentToModal,
  project,
}) {
  const auth = useAuth().state;
  const [email, setEmail] = useState("");
  const [clientError, setClientError] = useState(null);
  const [loading, setLoading] = useState(false);
  const authToken = useAuth()?.state?.authToken;

  const schema = Joi.object({
    email: Joi.string()
      .required()
      .email({
        minDomainSegments: 2,
        tlds: { allow: ["com", "net"] },
      }),
  });
  const onSubmit = async (event) => {
    event.preventDefault();
    let validator = schema.validate({ email });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setClientError(null);
      setLoading(true);
      await sentReport(authToken, email, project)
        .then((response) => {
          toast.success(response.message);
          setLoading(false);
          setShowSentToModal(false);
        })
        .catch((error) => {
          toast.error(error?.message || error);
          setLoading(false);
        });
    }
  };

  return (
    <>
      {showSentToModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Sent Report To</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowSentToModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div
                  className="relative p-6 flex-auto"
                  onClick={(e) => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                  }}
                >
                  <p className="mb-5 text-blueGray-500 text-sm leading-relaxed">
                    Send to Contact
                  </p>
                  <div className="relative w-full mb-3">
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="someone@example.com"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {clientError && clientError?.context?.key === "email" && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {clientError?.message}
                      </span>
                    )}
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowSentToModal(false)}
                  >
                    Close
                  </button>
                  <Button
                    classes="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onSubmit}
                    loading={loading}
                    text="Send"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
