import react from "react";

const CommingSoon = () => {
  return (
    <div className="h-screen overflow-hidden">
      <div
        className="absolute bg-cover bg-50 h-full w-full"
        style={{
          backgroundImage:
            "url(/notus-pro-react/static/media/404.c4fcb63b.svg)",
        }}
      ></div>
      <div className="container mx-auto px-4 h-full">
        <div className="flex flex-wrap -mx-4 h-full items-center">
          <div className="text-center px-4 relative w-full">
            <h1 className="font-bold text-10 sm:text-12 tracking-875 text-blueGray-800">
              Comming Soon
            </h1>
            <h4 className="text-3xl font-bold mb-2 mt-0">Stay with us:(</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommingSoon;
