import React from "react";

const HeadingList = ({ tag = "", headings = [] }) => {
  return (
    <>
      <ul className="list-none">
        {headings && headings.length > 0 ? (
          headings.map((heading, index) => (
            <li key={index + 1} className="pl-4 py-2 border-b">
              <div className="flex items-center">
                <div>
                  <span className="text-green-600 bg-lightBlue-200 text-xs font-semibold inline-block w-8 h-8 flex items-center justify-center uppercase rounded-full mr-3">
                    {`<${tag}>`}
                  </span>
                </div>
                <div>
                  <h4 className="text-blueGray-500">{heading}</h4>
                </div>
              </div>
            </li>
          ))
        ) : (
          <li className="py-2">
            <div className="flex items-center">
              <div>
                <span className="text-orange-500 bg-lightBlue-200 text-xs font-semibold inline-block w-8 h-8 flex items-center justify-center uppercase rounded-full mr-3">
                  <i className="fas fa-exclamation-circle"></i>
                </span>
              </div>
              <div>
                <h4 className="text-blueGray-500">{tag} heading not Found</h4>
              </div>
            </div>
          </li>
        )}
      </ul>
    </>
  );
};

export default HeadingList;
