export async function viewDetail(projectId, token) {
  const response = await fetch("/report/viewDetail?reportId=" + projectId, {
    method: "GET",
    headers: { "Content-Type": "application/json", authorization: token },
  });
  return await response.json();
}

export async function analyzeViewDetail(projectId, token) {
  const response = await fetch("/report/analyzeViewDetail?reportId=" + projectId, {
    method: "GET",
    headers: { "Content-Type": "application/json", authorization: token },
  });
  return await response.json();
}
