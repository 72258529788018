export async function getDesktopPageSpeed(domain, token) {
  try {
    const response = await fetch("/report/pageSpeedDesktopReport", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        project: domain,
      }),
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function getMobilePageSpeed(domain, token) {
  try {
    const response = await fetch("/report/pageSpeedMobileReport", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        project: domain,
      }),
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}

export const fetchWebPageDetails = async (token, domain) => {
  try {
    return await fetch("/WebPageAnalyzeReport/webPageScrap", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ domain: domain }),
    }).then((response) => response.json());
  } catch (error) {
    return error;
  }
};
