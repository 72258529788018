import React, { useEffect } from "react";

// components

export default function Table({
  title,
  items,
  headings,
  isStaticHeader = true,
}) {
  useEffect(() => {
    if (headings && headings.length > 2) {
      headings?.reverse();
    }
  }, []);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                {title}
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {!isStaticHeader &&
                  headings &&
                  headings?.length > 1 &&
                  headings?.length < 3 &&
                  headings.reverse() &&
                  headings.map((heading, index) => (
                    <th
                      key={index + 1}
                      className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    >
                      {heading.key}
                    </th>
                  ))}
              </tr>
              {isStaticHeader && (
                <tr>
                  <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Url
                  </th>
                  <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Total Bytes
                  </th>
                </tr>
              )}
            </thead>
            <tbody>
              {isStaticHeader &&
                items &&
                items.map((item, index) => (
                  <tr key={index + 1}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {item?.url?.length > 100
                        ? `${item.url.substring(0, 100)}...`
                        : item?.url}
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.totalBytes}
                      <span className="ml-3 text-xs">Bytes</span>
                    </td>
                  </tr>
                ))}
              {!isStaticHeader &&
                items &&
                items.map((item, index) => (
                  <tr key={index + 1}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {item?.url?.length > 100
                        ? `${item.url.substring(0, 100)}...`
                        : item?.url}
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item?.totalBytes || item?.wastedBytes || item?.duration}
                      <span className="ml-3 text-xs">Bytes</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
