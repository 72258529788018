import React, { useEffect } from "react";
import Joi from "joi";
import toast from "react-hot-toast";

import { useHistory } from "react-router-dom";

import { useAuth } from "contexts/auth-context";

import Button from "components/Shared/Button";

import { createProject } from "services/dashboard";
const schema = Joi.object({
  domain: Joi.string()
    .regex(
      /^(https?:\/\/)?((?!-)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z]{2,})+)(\/.*)?$/
    )
    .label("Domain")
    .required()
    .messages({ "string.pattern.base": "* Invalid Domain" }),
  title: Joi.string()
    .required()
    .messages({ "string.empty": "title is required" }),
});

export default function Modal({ showModal, hideModal }) {
  const [domain, setDomain] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [clientError, setClientError] = React.useState("");
  const authToken = useAuth()?.state?.authToken;
  const history = useHistory();

  const handleFocusOut = () => {
    let validator = schema.extract("domain").validate(domain);
    if (validator?.error) {
      setClientError(validator?.error?.details?.[0]);
    } else {
      if (domain.includes("https://") || domain.includes("http://")) {
        try {
          let url = new URL(domain);
          setTitle(
            url.host.includes("www")
              ? url.host.split(".")[1]
              : url.host.split(".")[0]
          );
        } catch (error) {
          console.log(error);
        }
      } else if (domain.includes("www") || domain.includes("www")) {
        try {
          let url = new URL("http://" + domain);
          setTitle(
            url.host.includes("www")
              ? url.host.split(".")[1]
              : url.host.split(".")[0]
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        setTitle(domain.split(".")[0]);
      }
    }
  };

  const handleSubmit = async () => {
    let validator = schema.validate({ title, domain });
    if (validator?.error) {
      setClientError(validator.error.details?.[0]);
    } else {
      setLoading(true);
      await createProject(authToken, domain, title)
        .then(async (response) => {
          if (response.status === false) {
            toast.error(response.message);
            setLoading(false);
            setTimeout(() => {
              hideModal(false);
              history.push("/app/subscription");
            }, 2000);
          } else {
            setTimeout(() => {
              toast.success(response.message);
              setLoading(false);
              hideModal(false);
            }, 2000);
            window.location.reload();
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  useEffect(() => {
    setDomain("");
    setTitle("");
    setClientError("");
  }, [showModal, hideModal]);

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Add Project</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={hideModal}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex-auto">
                    <div className="flex flex-wrap -mx-4">
                      <div className="px-4 relative w-full  md:w-6/12  ">
                        <div className="mb-3 pt-0 relative">
                          <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                            Domain
                          </label>
                          <div className="relative flex w-full flex-wrap items-stretch mb-3">
                            <input
                              placeholder="Enter your website"
                              type="url"
                              name="domain"
                              onChange={(e) => setDomain(e.target.value)}
                              onBlur={handleFocusOut}
                              onFocus={(e) => setTitle("")}
                              className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200"
                            />
                            {clientError &&
                              (clientError?.context?.key === "domain" ||
                                clientError?.context?.label === "Domain") && (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                  {clientError?.message}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="px-4 relative w-full  md:w-6/12  ">
                        <div className="mb-3 pt-0 relative">
                          <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                            Project Title
                          </label>
                          <div className="relative flex w-full flex-wrap items-stretch mb-3">
                            <input
                              placeholder="Project title"
                              type="text"
                              disabled
                              name="title"
                              value={title}
                              className="border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200"
                            />
                            {clientError &&
                              clientError?.context?.key === "title" && (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                  {clientError?.message}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={hideModal}
                  >
                    Close
                  </button>
                  <Button
                    onClick={handleSubmit}
                    loading={loading}
                    text={"Start Now"}
                    classes={
                      "bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    }
                    Submit
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
